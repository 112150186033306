import {
  GETCATEGORY,
  GETCATEGORIES,
  GETCHILDCATEGORIES,
  GETPARENTCATEGORIES,
} from "../actionTypes"

const initialState = {
  category: {},
  categories: {},
  childCategories: {},
  parentCategories: {},
}

const categoryReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GETCATEGORY:
      return {
        ...state,
        category: payload
      }

    case GETCATEGORIES:
      return {
        ...state,
        categories: payload
      }

    case GETPARENTCATEGORIES:
      return {
        ...state,
        parentCategories: payload
      }

    case GETCHILDCATEGORIES:
      return {
        ...state,
        childCategories: payload
      }

    default: return state
  }
}

export default categoryReducer