import toast from 'react-hot-toast'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import AuthService from '../../api-services/authService'

const VerifyEmail = () => {
  const { id } = useParams()
  const history = useHistory()

  const [responseReceived, setResponseReceived] = useState(false)
  const [error, setError] = useState('')

  useEffect(async () => {
    const response = await AuthService.verifyEmail(id)

    if (!response.error) {
      setResponseReceived(true)
      toast.success(response.msg)
      setTimeout(() => {
        history.replace('/login')
      }, 3000)
    } else {
      setResponseReceived(true)
      setError(response.msg)
      toast.error(response.msg)
    }
  }, [])

  return (
    <>
      <h4 className="text-center">
        {responseReceived
          ? error !== ''
            ? error
            : "Verification Successfull....You will be redirected shortly..."
          : "Please wait while we complete the verification process."
        }
      </h4>
    </>
  )
}

export default VerifyEmail
