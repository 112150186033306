import ProductService from "../../api-services/productService"
import {
  SETERROR,
  GETPRODUCT,
  GETPRODUCTS,
  GETPRODUCTSBYUSER_ID,
} from "../actionTypes"

export const getProduct = (params, history) => async dispatch => {

  try {
    const data = await ProductService.getProduct(params)
    dispatch({ type: GETPRODUCT, payload: data })
    dispatch({ type: SETERROR, payload: '' })
  } catch (err) {
    if (err.response)
      dispatch({ type: SETERROR, payload: err.response.data.msg })
  }
}

export const getProducts = (pageNumber = 1) => async dispatch => {
  try {
    const data = await ProductService.getProducts(pageNumber)
    dispatch({ type: GETPRODUCTS, payload: data })
    dispatch({ type: SETERROR, payload: '' })
  } catch (err) {
    if (err.response)
      dispatch({ type: SETERROR, payload: err.response.data.msg })
  }
}

export const getProductsByUserId = (pageNumber = 1) => async dispatch => {
  try {
    const data = await ProductService.getProductByUserId(localStorage.getItem('user_id'), pageNumber)
    dispatch({ type: GETPRODUCTS, payload: data })
    dispatch({ type: SETERROR, payload: '' })
  } catch (err) {
    if (err.response)
      dispatch({ type: SETERROR, payload: err.response.data.msg })
  }
}