import * as Yup from "yup"
import toast from "react-hot-toast"
import { BsEye } from "react-icons/bs"
import { Link } from "react-router-dom"
import { BsEyeSlash } from "react-icons/bs"
import { Field, Form, Formik } from "formik"
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Redirect, useHistory } from 'react-router'
import { login, register } from '../../redux/actions/auth.action'

const loginSchema = Yup.object().shape({
  fullname: Yup.string().min(2, 'Too Short').required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().min(6, 'Too Short').required('Must have at least 2 characters'),
  confirmPassword: Yup.string().min(6, 'Too Short').required('Must have at least 2 characters')
})

const Signup = ({
  latestError,
  claimFlag,
  closeModal
}) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const handleSignup = (values, actions) => {
    setLoading(true)

    let signupFormData = new FormData()

    signupFormData.append('email', values.email)
    signupFormData.append('fullname', values.fullname)
    signupFormData.append('password', values.password)

    dispatch(register(signupFormData, history, claimFlag, closeModal))
  }

  useEffect(() => {
    setLoading(false)

    if (latestError !== '')
      toast.error(latestError)
  }, [latestError])

  return (
    <>
      {localStorage.getItem('access_token')
        ? <Redirect to="/dashboard" />
        : <div className="col">
          <div
            className={claimFlag ? '' : "shadow rounded"}
            style={{
              height: claimFlag ? '76vh' : "87vh",
              marginLeft: claimFlag ? '0' : '10%',
              marginRight: claimFlag ? '0' : '10%'
            }}
          >
            <div className="row h-100">
              <div className="col-lg-6 rounded bg-primary d-flex justify-content-center align-items-center">
                <h2 className="text-white">Directory</h2>
              </div>
              <div className="col-lg-6 d-flex justify-content-center align-items-center">
                <div>
                  <div className="row py-3">
                    <h2>Signup</h2>
                  </div>
                  <div className="row pb-2">
                    <h5>Create a new account</h5>
                  </div>
                  <div className='row'>
                    <Formik
                      validationSchema={loginSchema}
                      initialValues={{ name: '', email: '', password: '', confirmPassword: '' }}
                      onSubmit={(values, actions) => handleSignup(values, actions)}
                    >
                      {({ errors, touched, isSubmitting, isValid, dirty, values }) => (
                        <Form>
                          <div className="col-lg-12">
                            <h6 className="text-muted">
                              <small>NAME</small>
                            </h6>
                            <Field
                              id="fullname"
                              type="text"
                              name="fullname"
                              className={errors.fullname
                                ? "border border-danger form-control col-lg-12 px-2"
                                : "form-control col-lg-12 px-2"
                              }
                            />
                          </div>
                          {errors.fullname && touched.fullname
                            ? <small className="float-end text-danger">{errors.fullname}</small>
                            : null
                          }
                          <div className="col-lg-12 mt-3">
                            <h6 className="text-muted">
                              <small>EMAIL</small>
                            </h6>
                            <Field
                              id="email"
                              type="email"
                              name="email"
                              className={errors.email
                                ? "border border-danger form-control col-lg-12 px-2"
                                : "form-control col-lg-12 px-2"
                              }
                            />
                          </div>
                          {errors.email && touched.email
                            ? <small className="float-end text-danger">{errors.email}</small>
                            : null
                          }
                          <div className="col-lg-12 mt-3">
                            <h6 className="text-muted">
                              <small>PASSWORD</small>
                            </h6>
                            <div className="input-group">
                              <Field
                                id="password"
                                name="password"
                                type={showPassword ? "text" : "password"}
                                className={errors.password
                                  ? "border border-danger form-control col-lg-10 px-2"
                                  : "form-control col-lg-10 px-2"
                                }
                              />
                              <span className="mt-1 ps-3 col-lg-2">
                                {showPassword
                                  ? <BsEye
                                    size="20"
                                    onClick={() => setShowPassword(!showPassword)}
                                  />
                                  : <BsEyeSlash
                                    size="20"
                                    onClick={() => setShowPassword(!showPassword)}
                                  />
                                }
                              </span>
                            </div>
                          </div>
                          {errors.password && touched.password
                            ? <small className="float-end text-danger">{errors.password}</small>
                            : null
                          }
                          <div className="col-lg-12 mt-3">
                            <h6 className="text-muted">
                              <small>CONFIRM PASSWORD</small>
                            </h6>
                            <div className="input-group">
                              <Field
                                id="confirmPassword"
                                name="confirmPassword"
                                type={showConfirmPassword ? "text" : "password"}
                                className={errors.confirmPassword
                                  ? "border border-danger form-control col-lg-10 px-2"
                                  : "form-control col-lg-10 px-2"
                                }
                              />
                              <span className="mt-1 ps-3 col-lg-2">
                                {showConfirmPassword
                                  ? <BsEye
                                    size="20"
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                  />
                                  : <BsEyeSlash
                                    size="20"
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                  />
                                }
                              </span>
                            </div>
                          </div>
                          {errors.confirmPassword && touched.confirmPassword
                            ? <small className="float-end text-danger">{errors.confirmPassword}</small>
                            : null
                          }
                          <div className="row mt-4 float-start">
                            <div className="col-lg-12">
                              <button
                                type='submit'
                                className="btn btn-primary rounded-3"
                                disabled={!isValid || !dirty || loading || values.password !== values.confirmPassword}
                              >
                                {loading
                                  ? <span
                                    role="status"
                                    aria-hidden="true"
                                    className="spinner-border spinner-border-sm"
                                  ></span>
                                  : <></>
                                }{" "}
                                {loading
                                  ? "Signing Up"
                                  : "Signup"
                                }
                              </button>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                  {claimFlag
                    ? <></>
                    : <div className="row mt-2">
                      <p>Have an account? <Link to={{ pathname: '/login' }}>Come Back to Directory</Link></p>
                    </div>

                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

const mapStateToProps = state => {
  return {
    errors: state.errorReducer.errors,
    latestError: state.errorReducer.latestError
  }
}

export default connect(mapStateToProps, null)(Signup)
