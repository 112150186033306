import toast from 'react-hot-toast'
import Pagination from 'react-js-pagination'
import { withRouter } from 'react-router-dom'
import { AiOutlineEye } from 'react-icons/ai'
import { connect, useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { GETSERVICE } from '../../redux/actionTypes'
import ServiceCreationForm from './ServiceCreationForm'
import { BsTrash, BsPencilSquare } from 'react-icons/bs'
import DataTable from '../../components/dataTable/DataTable'
import CustomModal from '../../components/modal/CustomModal'
import ServicesService from '../../api-services/servicesService'
import { getServices, getServicesByUserId } from '../../redux/actions/service.action'

const ServiceMainPage = ({
  latestError,
  reduxServices,
  reduxServicesPagination,
}) => {
  const dispatch = useDispatch()
  const [services, setServices] = useState([])
  const [loading, setLoading] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [operationFlag, setOperationFlag] = useState('')
  const [currentService, setCurrentService] = useState({})
  const [service, setService] = useState({ title: '', description: '' })
  const [activePage, setActivePage] = useState(1)

  const handlePageChange = (pageNumber) => {
    if (localStorage.getItem('role') === 'vendor') dispatch(getServicesByUserId(pageNumber))
    else dispatch(getServices(pageNumber))

    setActivePage(pageNumber)
  }

  const columns = [{
    dataField: 'title',
    text: 'Title',
    sort: true
  }, {
    dataField: 'actions',
    text: 'Actions'
  }]

  const handleCreateService = async (values, resetForm) => {

    let serviceFormData = new FormData()

    serviceFormData.append('title', values.title)
    serviceFormData.append('list_location_id', [''])
    serviceFormData.append('description', values.description)
    serviceFormData.append('user_id', localStorage.getItem('user_id'))

    try {
      const response = await ServicesService.createService(serviceFormData)

      if (response.error === false) {
        resetForm()

        toast.success('Service Added Successfully')
        setService({ title: '', description: '' })

        if (localStorage.getItem('role') === 'vendor') dispatch(getServicesByUserId())
        else dispatch(getServices())
      }
    } catch (error) {
      if (error.response)
        toast.error(error.response.data.msg)
    }
  }

  const handleDeletion = async (serviceId, userId) => {
    setDeleting(true)

    try {
      const response = await ServicesService.deleteService(serviceId, userId)

      if (response.error === false) {
        toast.success('Service Removed Successfully')

        if (localStorage.getItem('role') === 'vendor') dispatch(getServicesByUserId())
        else dispatch(getServices())

        setDeleting(false)
      }
    } catch (error) {
      setDeleting(false)

      if (error.response)
        toast.error(error.response.data.msg)
    }
  }

  const handleEdit = (e, row) => {
    dispatch({ type: GETSERVICE, payload: row })
    setCurrentService(row)
  }

  const rowEvents = {
    onClick: (e, row, rowIndex) => handleEdit(e, row)
  }

  useEffect(() => {
    setLoading(true)

    if (localStorage.getItem('role') === 'vendor') dispatch(getServicesByUserId())
    else dispatch(getServices())
  }, [])

  useEffect(() => {
    if (reduxServices.Data !== undefined) {
      if (reduxServices.Data.length !== 0) {
        reduxServices.Data.forEach(service => {
          service.actions = [
            <div className="text-center">
              <AiOutlineEye
                size='1.5em'
                data-bs-toggle="modal"
                className='text-info mt-1'
                data-bs-target="#customModal"
                style={{ cursor: 'pointer' }}
                onClick={() => setOperationFlag('view')}
              />{" "}
              <BsTrash
                size='1.5em'
                className='text-danger'
                style={{ cursor: deleting ? 'default' : 'pointer' }}
                onClick={!deleting ? () => handleDeletion(service.id, service.user_data._id.$oid) : ''}
              />{" "}
              <BsPencilSquare
                size='1.5em'
                data-bs-toggle="modal"
                className="text-primary"
                data-bs-target="#customModal"
                style={{ cursor: 'pointer' }}
                onClick={() => setOperationFlag('edit')}
              />
            </div>
          ]
        })
        setLoading(false)
        setServices(reduxServices.Data)
      } else {
        toast.success('No Services Found')
        setLoading(false)
      }
    }
  }, [reduxServices])

  useEffect(() => {
    if (services.length !== 0)
      toast.success('Services listed successfully')
  }, [services])

  // useEffect(() => {
  //   setLoading(false)
  // }, [service])

  useEffect(() => {

    if (latestError !== '') {
      setLoading(false)
      toast.error(latestError)
    }
  }, [latestError])

  return (
    <>
      <div className="row">
        <div className="col">
          <div
            className={loading
              ? "card p-3 d-flex justify-content-center align-items-center"
              : "card p-3"
            }
            style={{ maxHeight: '85vh', overflowY: 'auto' }}
          >
            {loading
              ? <div
                className="spinner-border text-primary"
                style={{
                  width: '3rem',
                  height: '3rem'
                }}
                role="status"
              ></div>
              : services.length === 0
                ? <h5 className="card-title text-center">No Services Found</h5>
                : <DataTable
                  columns={columns}
                  data={services}
                  rowEvents={rowEvents}
                  tableHeader={'Services'}
                />
            }
          </div>
          {reduxServices.pagination_data === undefined
            ? <></>
            : <div className="float-end mt-2">
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                pageRangeDisplayed={6}
                activePage={activePage}
                onChange={handlePageChange}
                totalItemsCount={reduxServices.pagination_data.total_items}
                itemsCountPerPage={reduxServices.pagination_data.items_per_page}
              />
            </div>
          }
        </div>
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Add New Service</h5>
              <ServiceCreationForm
                editFlag={false}
                serviceData={service}
                handleCreateService={handleCreateService}
              />
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        dataFlag={'service'}
        modalData={currentService}
        operationFlag={operationFlag}
        modalTitle={currentService.title}
      />
    </>
  )
}

const mapStateToProps = state => {
  return {
    latestError: state.errorReducer.latestError,
    reduxServices: state.serviceReducer.services,
    reduxServicesPagination: state.serviceReducer.servicePagination,
  }
}

export default connect(mapStateToProps, null)(withRouter(ServiceMainPage))
