export const LOGIN = 'auth/login'
export const LOGOUT = 'auth/logout'
export const REGISTER = 'auth/register'

export const GETUSER = 'users/get'
export const GETUSERS = 'users/list'

export const GETVENDOR = 'vendors/get'
export const GETVENDORS = 'vendors/list'

export const GETROLE = 'roles/get'
export const GETROLES = 'roles/list'
export const GETALLROLES = 'allRoles/list'

export const GETPRODUCT = 'products/get'
export const GETPRODUCTS = 'products/list'
export const GETPRODUCTSBYUSER_ID = 'products/user_id'

export const GETSERVICE = 'services/get'
export const GETSERVICES = 'services/list'
export const GETSERVICESBYUSER_ID = 'services/user_id'

export const GETLOCATION = 'locations/get'
export const GETLOCATIONS = 'locations/list'
export const GETALLLOCATIONS = 'allLocations/list'
export const GETCHILDLOCATIONS = 'childLocations/list'
export const GETPARENTLOCATIONS = 'parentLocations/list'

export const GETPAGINATION = 'pagination/get'

export const GETCITIES = 'cities/get'

export const GETCATEGORY = 'categories/get'
export const GETCATEGORIES = 'categories/list'
export const GETCHILDCATEGORIES = 'childCategories/list'
export const GETPARENTCATEGORIES = 'parentCategories/list'

export const GETLISTING = 'listings/get'
export const GETLISTINGS = 'listings/list'

export const SETSEARCHLISTINGS = 'listings/search'

export const GETCLAIMS = 'claims/list'

export const SETFORMPROGRESS = 'form/set'

export const SETERROR = 'error/set'
export const SETERRORS = 'errors/set'