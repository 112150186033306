import API from './api'

const categoryService = {
  createCategory: (data) => {
    return API.post('/category/add', data)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  updateCategory: (data) => {
    return API.patch('/category/update', data)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  deleteCategory: (data) => {
    return API.delete(`/category/delete?id=${data}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  getCategories: (data) => {
    return API.get(`/category/?page_no=${data}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  getParentCategories: () => {
    return API.get('/category/parent_list')
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  getParentCategorieNoAuth: () => {
    return API.get('/category/parent_list_auth')
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  getCategory: (data) => {
    return API.get(`/category/read_id?parent_id=${data}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  
  getCategoryNoAuth: (data) => {
    return API.get(`/category/read_id_auth?parent_id=${data}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
}

export default categoryService