import { combineReducers } from "redux"
import authReducer from "./auth.reducer"
import userReducer from "./user.reducer"
import roleReducer from "./role.reducer"
import formReducer from "./form.reducer"
import errorReducer from "./error.reducer"
import claimReducer from "./claim.reducer"
import searchReducer from "./search.reducer"
import listingReducer from "./listing.reducer"
import productReducer from "./product.reducer"
import serviceReducer from "./service.reducer"
import locationReducer from "./location.reducer"
import categoryReducer from "./category.reducer"

const appReducer = combineReducers({
  authReducer,
  userReducer,
  roleReducer,
  formReducer,
  errorReducer,
  claimReducer,
  listingReducer,
  productReducer,
  serviceReducer,
  categoryReducer,
  searchReducer,
  locationReducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout') {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export default rootReducer