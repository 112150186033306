import toast from 'react-hot-toast'
import Pagination from 'react-js-pagination'
import { withRouter } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { SETERROR } from '../../redux/actionTypes'
import claimService from '../../api-services/claimService'
import DataTable from '../../components/dataTable/DataTable'
import { getClaims } from '../../redux/actions/claim.action'

const ClaimMainPage = ({
  latestError,
  reduxClaims,
  reduxClaimsPagination,
}) => {
  const dispatch = useDispatch()

  const [claims, setClaims] = useState([])
  const [loading, setLoading] = useState(true)
  const [activePage, setActivePage] = useState(1)

  const columns = [{
    sort: true,
    text: 'Claimant',
    dataField: 'user_name',
  }, {
    sort: true,
    text: 'Listing',
    dataField: 'list_title',
  }, {
    text: 'Actions',
    dataField: 'actions',
  }]

  const handlePageChange = (pageNumber) => {
    dispatch(getClaims(pageNumber))

    setActivePage(pageNumber)
  }

  const handleApproval = async (claimData) => {
    let claimFormData = new FormData()

    claimFormData.append('list_id', claimData.list_id)
    claimFormData.append('user_id', claimData.user_id)

    const response = await claimService.approveClaim(claimFormData)

    try {
      if (!response.error) {
        dispatch(getClaims())
      }
    } catch (error) {
      if (error.response) {
        dispatch({ type: SETERROR, payload: error.response.data.msg })
      }
    }
  }

  // const handleEditClaim = (e, row) => {
  //   dispatch({ type: GETLISTING, payload: row })
  //   setCurrentListing(row)
  // }

  // const rowEvents = {
  //   onClick: (e, row, rowIndex) => handleEditClaim(e, row)
  // }

  useEffect(() => {
    setLoading(true)

    dispatch(getClaims())
  }, [])

  useEffect(() => {
    if (reduxClaims.Data !== undefined) {
      if (reduxClaims.Data.length !== 0) {
        reduxClaims.Data.forEach(claim => {
          claim.actions = [
            <div className="text-center">
              <button
                className='btn btn-success'
                onClick={() => handleApproval(claim)}
              >
                Approve
              </button>
            </div>
          ]
        })
        setLoading(false)
        toast.success('Claims listed successfully')
        setClaims(reduxClaims.Data)
      } else {
        setLoading(false)
        toast.success("No Claims Found")
        setClaims([])
      }
    }
  }, [reduxClaims])

  // useEffect(() => {
  //   if (claims !== undefined && claims.length !== 0) {
  //     toast.success('Claims listed successfully')
  //   }
  // }, [claims])

  useEffect(() => {
    if (latestError !== '') {
      toast.error(latestError)
      setLoading(false)
    }
  }, [latestError])

  return (
    <>
      <div className="row">
        <div className="col">
          <div
            className={loading
              ? "card p-3 d-flex justify-content-center align-items-center"
              : "card p-3"
            }
            style={{ maxHeight: '85vh', overflowY: 'auto' }}
          >
            {loading
              ? <div
                className="spinner-border text-primary"
                style={{
                  width: '3rem',
                  height: '3rem'
                }}
                role="status"
              ></div>
              : claims.length === 0
                ? <h5 className="card-title text-center">No Claims Found</h5>
                : <DataTable
                  data={claims}
                  columns={columns}
                  // rowEvents={rowEvents}
                  tableHeader={'Claims'}
                />
            }
          </div>
          {reduxClaims.pagination_data === undefined
            ? <></>
            : <div className="float-end mt-2">
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                pageRangeDisplayed={6}
                activePage={activePage}
                onChange={handlePageChange}
                totalItemsCount={reduxClaims.pagination_data.total_items}
                itemsCountPerPage={reduxClaims.pagination_data.items_per_page}
              />
            </div>
          }
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    reduxClaims: state.claimReducer.claims,
    latestError: state.errorReducer.latestError,
    reduxClaimsPagination: state.claimReducer.claimPagination,
  }
}

export default connect(mapStateToProps, null)(withRouter(ClaimMainPage))
