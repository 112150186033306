import {
  LOGIN,
  LOGOUT,
  REGISTER,
} from "../actionTypes"

const initialState = {
  access_token: localStorage.getItem('access_token') || '',
  isLoggedIn: localStorage.getItem('access_token') ? true : false
}

const authReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case LOGIN:
      return {
        ...state,
        access_token: payload,
        isLoggedIn: true
      }

    case REGISTER:
      return {
        ...state,
        //new state here
      }

    case LOGOUT:
      return {
        ...state,
        access_token: '',
        isLoggedIn: false
      }

    default: return state
  }
}

export default authReducer