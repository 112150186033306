import React from 'react'
import * as Yup from "yup"
import { connect } from 'react-redux'
import { BsEye } from "react-icons/bs"
import { BsEyeSlash } from "react-icons/bs"
import { Field, Form, Formik } from "formik"

const createUserSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().min(6, 'Too Short').required('Must have at least 6 characters'),
  fullname: Yup.string().min(2, 'Too Short').required('Must have at least 2 characters'),
  role: Yup.string().min(1, 'Role is required').required('Role not selected')
})

const UserCreationForm = ({
  user,
  editFlag,
  viewFlag,
  reduxRoles,
  showPassword,
  selectOptions,
  handleEditUser,
  handleOnChange,
  setShowPassword,
  handleCreateUser,
}) => {

  return (
    <>
      {!editFlag && !viewFlag ?
        <Formik
          initialValues={{ fullname: '', email: '', password: '', role: '' }}
          validationSchema={createUserSchema}
          onSubmit={(values, { resetForm }) => handleCreateUser(values, resetForm)}
        >
          {({ errors, touched, isSubmitting, isValid, dirty }) => (
            <Form>
              <div className="col-lg-12">
                <h6 className="text-muted"><small>FULL NAME</small></h6>
                <Field
                  id="fullname"
                  type="text"
                  name="fullname"
                  className={errors.fullname ? "border border-danger form-control col-lg-12 px-2" : "form-control col-lg-12 px-2"}
                />
              </div>
              {errors.fullname && touched.fullname ? (
                <small className="float-end text-danger">{errors.fullname}</small>
              ) : null}

              <div className="col-lg-12 mt-3">
                <h6 className="text-muted"><small>EMAIL</small></h6>
                <Field
                  id="email"
                  type="email"
                  name="email"
                  className={errors.email ? "border border-danger form-control col-lg-12 px-2" : "form-control col-lg-12 px-2"}
                />
              </div>
              {errors.email && touched.email ? (
                <small className="float-end text-danger">{errors.email}</small>
              ) : null}
              <div className="col-lg-12 mt-3">
                <h6 className="text-muted"><small>PASSWORD</small></h6>
                <div className="input-group">
                  <Field
                    id="password"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    className={errors.password ? "border border-danger form-control col-lg-11 px-2" : "form-control col-lg-11 px-2"}
                  />
                  <span className="mt-1 ps-3 col-lg-1">
                    {showPassword ?
                      <BsEye size="20" onClick={() => setShowPassword(!showPassword)} /> :
                      <BsEyeSlash size="20" onClick={() => setShowPassword(!showPassword)} />
                    }
                  </span>
                </div>
              </div>
              {errors.password && touched.password ? (
                <small className="float-end text-danger">{errors.password}</small>
              ) : null}

              <div className="col-lg-12 mt-3">
                <h6 className="text-muted"><small>ROLE</small></h6>
                <div className="input-group">
                  <Field
                    name="role"
                    as="select"
                    className={errors.email ? "border border-danger form-select" : "form-select"}
                  >
                    <option defaultValue value=''>Select Role</option>
                    {reduxRoles.Data !== undefined ? reduxRoles.Data.map((role, i) =>
                      <option key={i} value={role.id}>{role.role_name}</option>
                    ) : <></>}
                  </Field>
                </div>
              </div>
              {errors.role && touched.role ? (
                <small className="float-end text-danger">{errors.role}</small>
              ) : null}
              <div className="row mt-4 float-start">
                <div className="col-lg-12">
                  <button
                    type='submit'
                    className="btn btn-primary rounded-3"
                    disabled={isSubmitting || !isValid || !dirty}
                  >
                    {isSubmitting
                      ? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      : <></>}{" "}
                    {isSubmitting
                      ? "Submitting"
                      : "Create User"
                    }
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik> :
        editFlag && !viewFlag ?
          <Formik
            enableReinitialize={true}
            initialValues={{
              role: user.role,
              email: user.email,
              fullname: user.fullname,
              password: user.password,
            }}
            validationSchema={createUserSchema}
            onSubmit={(values, { resetForm }) => handleEditUser(values, resetForm)}
          >
            {({ errors, touched, isSubmitting, isValid, dirty }) => (
              <Form>
                <div className="col-lg-12">
                  <h6 className="text-muted"><small>FULL NAME</small></h6>
                  <Field
                    id="fullname"
                    type="text"
                    name="fullname"
                    className={errors.fullname ? "border border-danger form-control col-lg-12 px-2" : "form-control col-lg-12 px-2"}
                  />
                </div>
                {errors.fullname || touched.fullname ? (
                  <small className="float-end text-danger">{errors.fullname}</small>
                ) : null}

                {/* <div className="col-lg-12 mt-3">
                  <h6 className="text-muted"><small>EMAIL</small></h6>
                  <Field
                    id="email"
                    type="email"
                    name="email"
                    className={errors.email ? "border border-danger form-control col-lg-12 px-2" : "form-control col-lg-12 px-2"}
                  />
                </div>
                {errors.email || touched.email ? (
                  <small className="float-end text-danger">{errors.email}</small>
                ) : null} */}
                {/* <div className="col-lg-12 mt-3">
                <h6 className="text-muted"><small>PASSWORD</small></h6>
                <div className="input-group">
                  <Field
                    id="password"
                    // type={showPassword ? "text" : "password"}
                    type={"password"}
                    name="password"
                    className={errors.password ? "border border-danger form-control col-lg-11 px-2" : "form-control col-lg-11 px-2"}
                  />
                  <span className="mt-1 ps-3 col-lg-1">
                    {showPassword ?
                      <BsEye size="20" onClick={() => setShowPassword(!showPassword)} /> :
                      <BsEyeSlash size="20" onClick={() => setShowPassword(!showPassword)} />
                    }
                  </span>
                </div>
              </div> */}
                {/* {errors.password && touched.password ? (
                  <small className="float-end text-danger">{errors.password}</small>
                ) : null} */}

                <div className="col-lg-12 mt-3">
                  <h6 className="text-muted"><small>ROLE</small></h6>
                  <div className="input-group">
                    <Field
                      name="role"
                      as="select"
                      className={errors.role ? "border border-danger form-control" : "form-control"}
                    >
                      <option defaultValue value=''>Select Role</option>
                      {reduxRoles !== undefined ? reduxRoles.map((role, i) =>
                        <option
                          key={i}
                          value={role.id}
                          selected={role.role_name === user.role_data}
                        >
                          {role.role_name}
                        </option>
                      )
                        : <></>
                      }
                    </Field>
                  </div>
                </div>
                {errors.role || touched.role ? (
                  <small className="float-end text-danger">{errors.role}</small>
                ) : null}
                <div className="row mt-4 float-start">
                  <div className="col-lg-12">
                    <button
                      type='submit'
                      className="btn btn-primary rounded-3"
                      disabled={isSubmitting || !isValid || !dirty}
                    >
                      {isSubmitting
                        ? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        : <></>}{" "}
                      {isSubmitting
                        ? "Submitting"
                        : "Edit User"
                      }
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik> :
          !editFlag && viewFlag ?
            <form>
              <div className='row'>
                <div className="mb-3">
                  <label htmlFor="nameField" className="form-label">Name</label>
                  <input type="text" className="form-control" id="nameField" name="fullname" disabled value={user.fullname} onChange={handleOnChange} />
                </div>
              </div>
              <div className='row'>
                <div className="mb-3">
                  <label htmlFor="emailField" className="form-label">Email Address</label>
                  <input type="email" className="form-control" id="emailField" name="email" disabled value={user.email} onChange={handleOnChange} />
                </div>
              </div>
              {/* <div className='row'>
                <div className="mb-3">
                  <label htmlFor="passwordField" className="form-label">Password</label>
                  <input type="password" className="form-control" id="passwordField" name="password" disabled value={user.password} onChange={handleOnChange} />
                </div>
              </div> */}
              <div className='row'>
                <div className="mb-3">
                  <label htmlFor="roleField" className="form-label">Role</label>
                  <select type="text" className="form-control" id="nameField" name="role" disabled onChange={handleOnChange}>
                    <option defaultValue>Select Role</option>
                    {reduxRoles !== undefined ? reduxRoles.map((role, i) =>
                      <option key={i} selected={role.role_name === user.role_data} value={role.id}>{role.role_name}</option>
                    ) : <></>}
                  </select>
                </div>
              </div>
            </form> :
            <></>
      }
    </>
  )
}

const mapStateToProps = state => {
  return {
    reduxRoles: state.roleReducer.allRoles
  }
}

export default connect(mapStateToProps, null)(UserCreationForm)
