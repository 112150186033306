import SearchService from "../../api-services/searchService"
import { SETSEARCHLISTINGS, SETERROR } from "../actionTypes"

export const searchListings = (title, pageNumber = 1) => async dispatch => {
  try {
    const data = await SearchService.searchByTitle({ pageNumber: pageNumber, title: title })
    dispatch({ type: SETSEARCHLISTINGS, payload: data })
    dispatch({ type: SETERROR, payload: '' })
  } catch (err) {
    if (err.response)
      dispatch({ type: SETERROR, payload: err.response.data.msg })
  }
}