import toast from 'react-hot-toast'
import Pagination from 'react-js-pagination'
import { AiOutlineEye } from 'react-icons/ai'
import { withRouter } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import DataTable from '../../components/dataTable/DataTable'
import CustomModal from '../../components/modal/CustomModal'
import listingService from '../../api-services/listingService'
import { GETLISTING, SETERROR } from '../../redux/actionTypes'
import { getListings, getListingsByUserId } from '../../redux/actions/listing.action'
import { getProductsByUserId } from '../../redux/actions/product.action'
import { BsArchive, BsCheck2Circle, BsPencilSquare } from 'react-icons/bs'
import { getCategories, getChildrenCategories, getParentCategories } from '../../redux/actions/category.action'
import { getAllLocations, getChildLocations, getLocations, getParentLocations } from '../../redux/actions/location.action'
import { getServicesByUserId } from '../../redux/actions/service.action'

const ListingMainPage = ({
  latestError,
  reduxListings,
  reduxListingsPagination,
}) => {
  const dispatch = useDispatch()

  const [listing, setListing] = useState()
  const [listings, setListings] = useState([])
  const [loading, setLoading] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [activePage, setActivePage] = useState(1)
  const [operationFlag, setOperationFlag] = useState('')
  const [currentListing, setCurrentListing] = useState({})

  const approvalFormatter = (cell, row) => {
    if (row.is_approved) {
      return (
        <span>
          <strong className='text-success'><h4>&#10003;</h4></strong>
        </span>
      )
    }

    return (
      <span>
        <strong className='text-danger'><h4>&#10007;</h4></strong>
      </span>
    );
  }

  const columns = [{
    sort: true,
    text: 'Title',
    dataField: 'title',
  }, {
    sort: true,
    text: 'Address',
    dataField: 'address',
  }, {
    sort: true,
    text: 'Description',
    dataField: 'description',
  }, {
    sort: true,
    align: 'center',
    text: 'Approval Status',
    dataField: 'is_approved',
    formatter: approvalFormatter,
  }, {
    dataField: 'actions',
    text: 'Actions'
  }]

  const handlePageChange = (pageNumber) => {
    if (localStorage.getItem('role') === 'vendor') dispatch(getListingsByUserId(pageNumber))
    else dispatch(getListings(pageNumber))

    setActivePage(pageNumber)
  }

  const handleCreateListing = async values => {
    let videos = values.videos.map(video => video.name)
    let website = values.website.map(website => website.name)
    let contact_info = values.contact_info.map(contact_info => contact_info.name)
    let social_profile = values.social_profile.map(social_profile => social_profile.name)

    let listingFormData = new FormData()

    listingFormData.append('title', values.title)
    listingFormData.append('address', values.address)
    listingFormData.append('category_id', values.category)
    listingFormData.append('product_id', values.product_id)
    listingFormData.append('description', values.description)
    listingFormData.append('user_id', localStorage.getItem('user_id'))
    listingFormData.append('service_id', values.service_id == '' ? '' : values.service_id)
    listingFormData.append('location_id', values.city_id !== '' ? values.city_id : values.state_id !== '' ? values.state_id : values.location)

    for (var i = 0; i < videos.length; i++)
      listingFormData.append('videos', videos[i])

    for (var i = 0; i < website.length; i++)
      listingFormData.append('website', website[i])

    for (var i = 0; i < contact_info.length; i++)
      listingFormData.append('contact_info', contact_info[i])

    for (var i = 0; i < social_profile.length; i++)
      listingFormData.append('social_profile', social_profile[i])

    const response = await listingService.createListing(listingFormData)

    if (response.error === false) {
      dispatch(getListings())
      dispatch(getLocations())
      dispatch(getCategories())
      dispatch(getProductsByUserId())
    }
  }

  const handleDeletion = async (listingId) => {
    setDeleting(true)

    const response = await listingService.deleteListing(listingId)

    if (response.error === false) {
      if (localStorage.getItem('role') === 'vendor') dispatch(getListingsByUserId())
      else dispatch(getListings())
      dispatch(getLocations())
      dispatch(getCategories())
      dispatch(getProductsByUserId())
      setDeleting(false)
    } else
      setDeleting(false)
  }

  // const handleApproval = async (listingData) => {
  //   console.log(listingData)
  //   let listingFormData = new FormData()

  //   listingFormData.append('keyword', 'basic_info')
  //   listingFormData.append('title', '')
  //   listingFormData.append('address', '')
  //   listingFormData.append('videos', '')
  //   listingFormData.append('website', '')
  //   listingFormData.append('description', '')
  //   listingFormData.append('contact_info', '')
  //   listingFormData.append('social_profile', '')
  //   listingFormData.append('views', '')
  //   listingFormData.append('archive', '')
  //   listingFormData.append('contacted', '')
  //   listingFormData.append('is_claimed', '')
  //   listingFormData.append('is_approved', true)
  //   listingFormData.append('user_id', '')
  //   listingFormData.append('list_id', listingData.id)
  //   listingFormData.append('location_service', [])

  //   const response = await listingService.updateListing(listingFormData)

  //   try {
  //     if (response.error === false) {
  //       toast.success(`Listing Approved`)
  //       dispatch(getListings())
  //     }
  //   } catch (error) {
  //     if (error.response)
  //       dispatch({ type: SETERROR, payload: error.response.data.msg })
  //   }
  // }

  const handleEditListing = (e, row) => {
    dispatch({ type: GETLISTING, payload: row })
    setCurrentListing(row)
  }

  const rowEvents = {
    onClick: (e, row, rowIndex) => handleEditListing(e, row)
  }

  useEffect(() => {
    setLoading(true)

    dispatch(getCategories())
    dispatch(getParentCategories())
    dispatch(getParentLocations())
    dispatch(getChildLocations())
    dispatch(getAllLocations())
    dispatch(getProductsByUserId())
    dispatch(getServicesByUserId())
    if (localStorage.getItem('role') === 'vendor') dispatch(getListingsByUserId())
    else dispatch(getListings())
  }, [])

  useEffect(() => {
    if (reduxListings.Data !== undefined) {
      if (reduxListings.Data.length !== 0) {
        reduxListings.Data.forEach(listing => {
          listing.actions = [
            <div className="text-center">
              <AiOutlineEye
                size='1.5em'
                data-bs-toggle="modal"
                data-bs-target="#customModal"
                // data-bs-toggle="tooltip"
                // data-bs-placement="top"
                title="View"
                style={{ cursor: 'pointer' }}
                className='text-info mt-1'
                onClick={() => setOperationFlag('view')}
              />{" "}
              <BsArchive
                size='1.5em'
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Archive"
                className={listing.archive ? 'text-success' : 'text-danger'}
                onClick={!deleting && !listing.archive ? () => handleDeletion(listing.id) : ''}
                style={{ cursor: deleting ? 'default' : listing.archive ? 'default' : 'pointer' }}
              />{" "}
              <BsPencilSquare
                size='1.5em'
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Edit"
                data-bs-toggle="modal"
                className="text-primary"
                data-bs-target="#customModal"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (listing.cat_data !== undefined && listing.cat_data.parent !== null)
                    if (listing.cat_data.parent !== undefined)
                      dispatch(getChildrenCategories(listing.cat_data.parent))
                    else
                      dispatch(getChildrenCategories(listing.cat_data.id))
                  setOperationFlag('edit')
                }}
              />{' '}
              {listing.is_approved || localStorage.getItem('role') === 'vendor'
                ? <></>
                : <BsCheck2Circle
                  size='1.5em'
                  title="Approve"
                  data-bs-placement="top"
                  data-bs-toggle="tooltip"
                  className='text-success'
                  style={{ cursor: 'pointer' }}
                  onClick={async () => {
                    // console.log(listing)
                    let listingFormData = new FormData()

                    listingFormData.append('keyword', 'is_approved')
                    listingFormData.append('title', '')
                    listingFormData.append('address', '')
                    listingFormData.append('videos', '')
                    listingFormData.append('website', '')
                    listingFormData.append('description', '')
                    listingFormData.append('contact_info', '')
                    listingFormData.append('social_profile', '')
                    listingFormData.append('views', '')
                    listingFormData.append('archive', '')
                    listingFormData.append('contacted', '')
                    listingFormData.append('is_claimed', '')
                    listingFormData.append('is_approved', true)
                    listingFormData.append('user_id', '')
                    listingFormData.append('list_id', listing.id)
                    listingFormData.append('location_service', [])

                    const response = await listingService.updateListing(listingFormData)

                    try {
                      if (response.error === false) {
                        toast.success(`Listing Approved`)
                        dispatch(getListings())
                      }
                    } catch (error) {
                      if (error.response)
                        dispatch({ type: SETERROR, payload: error.response.data.msg })
                    }
                  }}
                />
              }
            </div>
          ]
        })
        setLoading(false)
        setListings(reduxListings.Data)
      } else {
        toast.success('No Listings Found.')
        setLoading(false)
      }
    }
  }, [reduxListings])

  useEffect(() => {
    // setLoading(false)

    if (listings !== undefined && listings.length !== 0)
      toast.success('Listings listed successfully')

  }, [listings])

  useEffect(() => {
    if (latestError !== '') {
      toast.error(latestError)
      setLoading(false)
    }
  }, [latestError])

  return (
    <>
      <div className="row">
        <div className='col mb-2'>
          <button
            data-bs-toggle="modal"
            onClick={() => {
              setOperationFlag('')
              setCurrentListing({})
            }}
            data-bs-target="#customModal"
            className="btn btn-primary float-end"
          >
            Add New Listing
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div
            className={loading
              ? "card p-3 d-flex justify-content-center align-items-center"
              : "card p-3"
            }
            style={{ maxHeight: '85vh', overflowY: 'auto' }}
          >
            {loading
              ? <div
                className="spinner-border text-primary"
                style={{
                  width: '3rem',
                  height: '3rem'
                }}
                role="status"
              ></div>
              : listings.length === 0
                ? <h5 className="card-title text-center">No Listings Available</h5>
                : <DataTable
                  data={listings}
                  columns={columns}
                  rowEvents={rowEvents}
                  tableHeader={'Listings'}
                />
            }
          </div>
          {reduxListings.pagination_data === undefined
            ? <></>
            : <div className="float-end mt-2">
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                pageRangeDisplayed={6}
                activePage={activePage}
                onChange={handlePageChange}
                totalItemsCount={reduxListings.pagination_data.total_items}
                itemsCountPerPage={reduxListings.pagination_data.items_per_page}
              />
            </div>
          }
        </div>
      </div>
      <CustomModal
        dataFlag={'listing'}
        modalData={currentListing}
        operationFlag={operationFlag}
        modalTitle={currentListing.title === undefined ? "Add New Listing" : currentListing.title}
      />
    </>
  )
}

const mapStateToProps = state => {
  return {
    latestError: state.errorReducer.latestError,
    reduxListings: state.listingReducer.listings,
    reduxListingsPagination: state.listingReducer.listingPagination,
  }
}

export default connect(mapStateToProps, null)(withRouter(ListingMainPage))
