import {
  SETERROR,
  SETERRORS,
} from "../actionTypes"

const initialState = {
  errors: [],
  latestError: '',
}

const errorReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SETERROR:
      return {
        ...state,
        latestError: payload
      }

    case SETERRORS:
      return {
        ...state,
        errors: state.errors.push(payload)
      }

    default: return state
  }
}

export default errorReducer