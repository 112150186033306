import {
  GETCITIES,
  GETLOCATION,
  GETLOCATIONS,
  GETALLLOCATIONS,
  GETCHILDLOCATIONS,
  GETPARENTLOCATIONS,
} from "../actionTypes"

const initialState = {
  cities: {},
  location: [],
  locations: {},
  allLocations: {},
  childLocations: {},
  parentLocations: {},
}

const locationReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {

    case GETCITIES:
      return {
        ...state,
        cities: payload
      }

    case GETLOCATION:
      return {
        ...state,
        location: payload.data
      }

    case GETLOCATIONS:
      return {
        ...state,
        locations: payload
      }

    case GETCHILDLOCATIONS:
      return {
        ...state,
        childLocations: payload
      }

    case GETALLLOCATIONS:
      return {
        ...state,
        allLocations: payload
      }

    case GETPARENTLOCATIONS:
      return {
        ...state,
        parentLocations: payload
      }

    default: return state
  }
}

export default locationReducer