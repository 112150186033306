import locationService from "../../api-services/locationService"
import {
  SETERROR,
  GETCITIES,
  GETLOCATION,
  GETLOCATIONS,
  GETALLLOCATIONS,
  GETCHILDLOCATIONS,
  GETPARENTLOCATIONS,
} from "../actionTypes"

export const getLocation = (params, locationIdentifier) => async dispatch => {
  try {
    const data = await locationService.getLocation(params)
    if (locationIdentifier === 'states') {
      dispatch({ type: GETLOCATION, payload: data })
      dispatch({ type: SETERROR, payload: '' })
    } else if (locationIdentifier === 'cities') {
      dispatch({ type: GETCITIES, payload: data })
      dispatch({ type: SETERROR, payload: '' })
    }
  } catch (err) {
    if (err.response)
      dispatch({ type: SETERROR, payload: err.response.data.msg })
  }
}

export const getLocations = (pageNumber = 1) => async dispatch => {
  try {
    const data = await locationService.getLocations(pageNumber)
    dispatch({ type: GETLOCATIONS, payload: data })
    dispatch({ type: SETERROR, payload: '' })
  } catch (err) {
    if (err.response)
      dispatch({ type: SETERROR, payload: err.response.data.msg })
  }
}

export const getAllLocations = () => async dispatch => {
  try {
    const data = await locationService.getAllLocations()
    dispatch({ type: GETALLLOCATIONS, payload: data })
    dispatch({ type: SETERROR, payload: '' })
  } catch (err) {
    if (err.response)
      dispatch({ type: SETERROR, payload: err.response.data.msg })
  }
}

export const getChildLocations = () => async dispatch => {
  try {
    const data = await locationService.getChildLocations()
    dispatch({ type: GETCHILDLOCATIONS, payload: data })
    dispatch({ type: SETERROR, payload: '' })
  } catch (err) {
    if (err.response)
      dispatch({ type: SETERROR, payload: err.response.data.msg })
  }
}

export const getParentLocations = () => async dispatch => {
  try {
    const data = await locationService.getParentLocations()
    dispatch({ type: GETPARENTLOCATIONS, payload: data })
    dispatch({ type: SETERROR, payload: '' })
  } catch (err) {
    if (err.response)
      dispatch({ type: SETERROR, payload: err.response.data.msg })
  }
}