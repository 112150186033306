import { SETFORMPROGRESS } from "../actionTypes"

const initialState = {
  locationAndServices: []
}

const formReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SETFORMPROGRESS:
      return {
        ...state,
        locationAndServices: [...state.locationAndServices, payload]
      }

    default: return state
  }
}

export default formReducer