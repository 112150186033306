import toast from 'react-hot-toast'
import { connect } from 'react-redux'
import { useDispatch } from 'react-redux'
import Signup from '../../pages/auth/Signup'
import { useRouteMatch } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import RoleService from '../../api-services/roleService'
import UserService from '../../api-services/userService'
import { getUsers } from '../../redux/actions/user.action'
import { getRoles } from '../../redux/actions/roles.action'
import listingService from '../../api-services/listingService'
import ProductService from '../../api-services/productService'
import RoleCreationForm from '../../pages/role/RoleCreationForm'
import UserCreationForm from '../../pages/user/UserCreationForm'
import locationService from '../../api-services/locationService'
import categoryService from '../../api-services/categoryService'
import { getListings, getListingsByUserId } from '../../redux/actions/listing.action'
import { getProducts } from '../../redux/actions/product.action'
import ServicesService from '../../api-services/servicesService'
import { getServices } from '../../redux/actions/service.action'
import ListingCreationForm from '../../pages/listing/ListingCreationForm'
import ProductCreationForm from '../../pages/product/ProductCreationForm'
import ServiceCreationForm from '../../pages/service/ServiceCreationForm'
import CategoryCreationForm from '../../pages/category/CategoryCreationForm'
import LocationCreationForm from '../../pages/location/LocationCreationForm'
import { getLocations, getParentLocations } from '../../redux/actions/location.action'
import { getCategories, getChildrenCategories, getParentCategories } from '../../redux/actions/category.action'

const CustomModal = ({
  listing,
  dataFlag,
  modalData,
  modalTitle,
  latestError,
  operationFlag,
  localLocationAndServices,
}) => {
  const dispatch = useDispatch()
  const { url } = useRouteMatch()

  const [product, setProduct] = useState('')
  const [category, setCategory] = useState('')
  const [updating, setUpdating] = useState(false)
  const [currentListing, setCurrentListing] = useState({})
  const [currentTab, setCurrentTab] = useState('basic-info')
  const [currentListingId, setCurrentListingId] = useState('')
  const [creatingListing, setCreatingListing] = useState(false)
  const [locationAndService, setLocationAndService] = useState([])
  const [user, setUser] = useState({ fullname: '', email: '', password: '', role: '' })

  const closeModal = () => {
    const modal = document.getElementById("customModal")
    modal.style.display = "none"
    modal.classList.remove("show")
    document.body.removeAttribute("style")
    modal.setAttribute("aria-hidden", "true")
    modal.setAttribute('aria-modal', 'false')
    document.body.classList.remove("modal-open")
    modal.setAttribute('data-bs-dismiss', 'modal')

    const modalBackdrops = document.getElementsByClassName("modal-backdrop")
    modalBackdrops[0].classList.remove("show")

    document.body.removeChild(modalBackdrops[0])
  }

  const handleOnChange = e => {
    const { name, value } = e.target

    setUser(prevUser => ({ ...prevUser, [name]: value }))
  }

  const handleEditUser = async (values, resetForm) => {
    let userFormData = new FormData()

    userFormData.append('email', values.email)
    userFormData.append('role_id', values.role)
    userFormData.append('user_id', modalData.id)
    userFormData.append('fullname', values.fullname)
    userFormData.append('password', values.password)

    const response = await UserService.updateUser(userFormData)

    if (response.error === false) {
      resetForm()
      toast.success(`${values.fullname}'s Data Updated`)

      dispatch(getRoles())
      dispatch(getUsers())
    }
  }

  const handleEditRole = async (values, resetForm) => {
    let roleFormData = new FormData()

    roleFormData.append('role_id', modalData.id)
    roleFormData.append('role_name', values.role_name)

    const response = await RoleService.updateRole(roleFormData)

    if (response.error === false) {
      resetForm()

      toast.success(`${values.role_name}'s Data Updated`)

      dispatch(getRoles())
    }

    closeModal()
  }

  const handleEditLocation = async (values, resetForm) => {
    let locationFormData = new FormData()

    locationFormData.append('id', modalData.id)
    locationFormData.append('parent_id', values.parent_id)
    locationFormData.append('location_name', values.location_name)

    const response = await locationService.updateLocation(locationFormData)

    if (response.error === false) {
      resetForm()

      toast.success(`${values.location_name}'s Data Updated`)
      dispatch(getLocations())
      dispatch(getParentLocations())

      closeModal()
    }
  }

  const handleEditCategory = async (values, resetForm) => {
    let categoryFormData = new FormData()

    categoryFormData.append('id', modalData.id)
    categoryFormData.append('name', values.category_name)
    categoryFormData.append('parent_id', values.parent_id)

    const response = await categoryService.updateCategory(categoryFormData)

    if (response.error === false) {
      resetForm()

      toast.success(`${values.category_name}'s Data Updated`)

      dispatch(getCategories())
      dispatch(getParentCategories())

      closeModal()
    }
  }

  const handleEditListing = async values => {
    let listingFormData = new FormData()

    listingFormData.append('title', values.title)
    listingFormData.append('address', values.address)
    listingFormData.append('location_id', values.location)
    listingFormData.append('category_id', values.category)
    listingFormData.append('description', values.description)

    const response = await listingService.updateListing(listingFormData)

    if (response.error === false) {
      dispatch(getListings())
      dispatch(getLocations())
      dispatch(getCategories())

      closeModal()
    }
  }

  const handleEditProduct = async (values, resetForm) => {
    let productFormData = new FormData()

    productFormData.append('id', modalData.id)
    productFormData.append('title', values.title)
    productFormData.append('price', values.price)
    productFormData.append('description', values.description)
    productFormData.append('user_id', localStorage.getItem('user_id'))

    const response = await ProductService.updateProduct(productFormData)

    if (response.error === false) {
      resetForm()

      toast.success(`${values.title}'s Data Updated`)

      dispatch(getProducts())
      closeModal()
    }
  }

  const handleEditService = async (values, resetForm) => {
    let serviceFormData = new FormData()

    serviceFormData.append('id', modalData.id)
    serviceFormData.append('title', values.title)
    serviceFormData.append('description', values.description)
    serviceFormData.append('user_id', localStorage.getItem('user_id'))

    const response = await ServicesService.updateService(serviceFormData)

    if (response.error === false) {
      resetForm()

      toast.success(`${values.title}'s Data Updated`)

      dispatch(getServices())
      closeModal()
    }
  }

  const handleCreateListing = async values => {
    setCreatingListing(true)

    let videos = values.videos.map(video => video.name)
    let website = values.website.map(website => website.name)
    let contact_info = values.contact_info.map(contact_info => contact_info.name)
    let social_profile = values.social_profile.map(social_profile => social_profile.name)

    let listingFormData = new FormData()

    listingFormData.append('title', values.title)
    listingFormData.append('address', values.address)
    listingFormData.append('description', values.description)
    listingFormData.append('user_id', localStorage.getItem('user_id'))

    for (var i = 0; i < videos.length; i++)
      listingFormData.append('videos', videos[i])

    for (var i = 0; i < website.length; i++)
      listingFormData.append('website', website[i])

    for (var i = 0; i < contact_info.length; i++)
      listingFormData.append('contact_info', contact_info[i])

    for (var i = 0; i < social_profile.length; i++)
      listingFormData.append('social_profile', social_profile[i])

    setCurrentListing(listingService)

    const response = await listingService.createListing(listingFormData)

    if (response.error === false) {
      setCreatingListing(false)
      toast.success('Listing Created Successfully')

      if (localStorage.getItem('role') === 'vendor') dispatch(getListingsByUserId())
      else dispatch(getListings())

      setCurrentTab('products')
      setCurrentListingId(response.list_id)
    } else
      setCreatingListing(false)
  }

  const handleUpdateListing = async params => {
    setUpdating(true)
    if (currentTab === 'basic-info') {
      let listingFormData = new FormData()

      listingFormData.append('title', params.title)
      listingFormData.append('list_id', listing.id)
      listingFormData.append('keyword', 'basic_info')
      listingFormData.append('address', params.address)

      for (var i = 0; i < params.videos.length; i++)
        listingFormData.append('videos', params.videos[i])

      for (var i = 0; i < params.website.length; i++)
        listingFormData.append('website', params.website[i])

      listingFormData.append('user_id', localStorage.getItem('user_id'))
      listingFormData.append('description', params.description)

      for (var i = 0; i < params.contact_info.length; i++)
        listingFormData.append('contact_info', params.contact_info[i])

      for (var i = 0; i < params.social_profile.length; i++)
        listingFormData.append('social_profile', params.social_profile[i])

      listingFormData.append('views', listing.views)
      listingFormData.append('archive', listing.archive)
      listingFormData.append('contacted', listing.contacted)
      listingFormData.append('is_claimed', listing.is_claimed)
      listingFormData.append('is_approved', listing.is_approved)

      const response = await listingService.updateListing(listingFormData)

      if (response.error === false) {
        toast.success(`${params.title}'s Data Updated`)
        if (localStorage.getItem('role') === 'vendor') dispatch(getListingsByUserId())
        else dispatch(getListings())
        setUpdating(false)
        setCurrentTab('products')
      } else
        setUpdating(false)
    } else if (currentTab === 'products') {
      let listingFormData = new FormData()

      listingFormData.append('keyword', 'product')
      listingFormData.append('user_id', localStorage.getItem('user_id'))
      listingFormData.append('list_id', currentListingId === '' ? listing.id : currentListingId)

      for (var i = 0; i < product.length; i++)
        listingFormData.append('product_id', product[i].value === undefined ? product[i] : product[i].value)

      const response = await listingService.updateListing(listingFormData)

      if (response.error === false) {
        toast.success(`Listing updated with Products`)
        if (localStorage.getItem('role') === 'vendor') dispatch(getListingsByUserId())
        else dispatch(getListings())

        setUpdating(false)
        setCurrentTab('category')
      } else
        setUpdating(false)
    } else if (currentTab === 'category') {
      let listingFormData = new FormData()

      listingFormData.append('keyword', 'category')
      listingFormData.append('user_id', localStorage.getItem('user_id'))
      listingFormData.append('category_id', category !== undefined ? category : '')
      listingFormData.append('list_id', currentListingId === '' ? listing.id : currentListingId)

      const response = await listingService.updateListing(listingFormData)

      if (response.error === false) {
        toast.success(`Listing updated with Category`)
        if (localStorage.getItem('role') === 'vendor') dispatch(getListingsByUserId())
        else dispatch(getListings())

        setUpdating(false)
        setCurrentTab('location-services')
      } else
        setUpdating(false)
    } else if (currentTab === 'location-services') {
      console.log(params)
      // console.log(params)
      // let listingFormData = new FormData()

      // // listingFormData.append('title', values.title)
      // // listingFormData.append('address', values.address)
      // // listingFormData.append('location_id', values.city_id !== '' ? values.city_id : values.state_id !== '' ? values.state_id : values.location)
      // // listingFormData.append('category_id', params !== undefined ? params.category : '')
      // listingFormData.append('keyword', 'ls')
      // listingFormData.append('list_id', currentListingId)

      // for (var i = 0; i < locationAndService.length; i++)
      //   listingFormData.append('location_service', JSON.stringify(locationAndService[i]))
      // // listingFormData.append('category_id', category !== undefined ? category : '')
      // // listingFormData.append('service_id', values.service_id == '' ? '' : values.service_id)
      // listingFormData.append('user_id', localStorage.getItem('user_id'))
      // // listingFormData.append('description', values.description)

      // console.log(locationAndService)
      // console.log(...listingFormData)
      // // const response = await listingService.updateListing(listingFormData)

      // // if (response.error === false) {
      // //   setUpdating(false)
      // //   closeModal()
      // //   setCurrentTab('basic-info')
      // // } else {
      // //   setUpdating(false)
      // // }
    }
  }

  useEffect(async () => {
    console.log(locationAndService)

    if (locationAndService.length !== 0
      // && listing.id !== undefined
      // && updating === true
      && url === '/listings') {
      console.log(locationAndService)
      // if (locationAndService[0].length === 0) {
      //   locationAndService.splice(0)
      //   // console.log(locationAndService)
      // } else {
      setUpdating(true)

      let listingFormData = new FormData()

      listingFormData.append('keyword', 'ls')
      listingFormData.append('user_id', localStorage.getItem('user_id'))
      listingFormData.append('list_id', currentListingId === '' ? listing.id : currentListingId)

      // if (localLocationAndServices[0] !== undefined) {
      //   if (localLocationAndServices[0].location !== '')
      //     for (var i = 0; i < localLocationAndServices.length; i++) {
      //       if (typeof localLocationAndServices[i] === 'string') {
      //         listingFormData.append('location_service', localLocationAndServices[i])
      //       } else {
      //         listingFormData.append('location_service', JSON.stringify(localLocationAndServices[i]))
      //       }
      //     }
      // }

      if (locationAndService.length !== 0) {
        // console.log(locationAndService)
        for (var i = 0; i < locationAndService.length; i++) {
          if (typeof locationAndService[i] === 'string') {
            listingFormData.append('location_service', locationAndService[i])
          } else {
            listingFormData.append('location_service', JSON.stringify(locationAndService[i]))
          }
        }
      }

      const response = await listingService.updateListing(listingFormData)

      if (response.error === false) {
        toast.success(`Listing updated with Location & Services`)
        if (localStorage.getItem('role') === 'vendor') dispatch(getListingsByUserId())
        else dispatch(getListings())

        setUpdating(false)
        closeModal()
        setCurrentTab('basic-info')
      } else
        setUpdating(false)
    }
  }, [locationAndService])

  useEffect(() => {
    if (latestError !== '')
      toast.error(latestError)
  }, [latestError])

  useEffect(() => {
    if (category !== '') dispatch(getChildrenCategories(category))
  }, [category])

  return (
    <div
      id="customModal"
      style={{ marginTop: '2.5%' }}
      className="modal fade" tabIndex="-1"
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{modalTitle}</h5>
            <button
              type="button"
              aria-label="Close"
              className="btn-close"
              data-bs-dismiss="modal"
            ></button>
          </div>
          <div className="modal-body overflow-auto" style={{ height: '80vh' }}>
            {
              Object.keys(modalData).length !== 0
                ? dataFlag === 'user' && operationFlag === 'edit'
                  ? <UserCreationForm
                    editFlag={true}
                    user={modalData}
                    handleOnChange={handleOnChange}
                    handleEditUser={handleEditUser}
                  />
                  : dataFlag === 'role' && operationFlag === 'edit'
                    ? <RoleCreationForm
                      editFlag={true}
                      role={modalData}
                      handleOnChange={handleOnChange}
                      handleEditRole={handleEditRole}
                    />
                    : dataFlag === 'user' && operationFlag === 'view'
                      ? <UserCreationForm
                        viewFlag={true}
                        editFlag={false}
                        user={modalData}
                      />
                      : dataFlag === 'location' && operationFlag === 'view'
                        ? <LocationCreationForm
                          viewFlag={true}
                          editFlag={false}
                          locationData={modalData}
                        />
                        : dataFlag === 'location' && operationFlag === 'edit'
                          ? <LocationCreationForm
                            editFlag={true}
                            viewFlag={false}
                            locationData={modalData}
                            handleOnChange={handleOnChange}
                            handleEditLocation={handleEditLocation}
                          />
                          : dataFlag === 'category' && operationFlag === 'view'
                            ? <CategoryCreationForm
                              viewFlag={true}
                              editFlag={false}
                              categoryData={modalData}
                            />
                            : dataFlag === 'category' && operationFlag === 'edit'
                              ? <CategoryCreationForm
                                editFlag={true}
                                viewFlag={false}
                                categoryData={modalData}
                                handleOnChange={handleOnChange}
                                handleEditCategory={handleEditCategory}
                              />
                              : dataFlag === 'listing' && operationFlag === 'view'
                                ? <ListingCreationForm
                                  viewFlag={true}
                                  editFlag={false}
                                  listingData={modalData}
                                />
                                : dataFlag === 'listing' && operationFlag === 'edit'
                                  ? <ListingCreationForm
                                    editFlag={true}
                                    viewFlag={false}
                                    product={product}
                                    updating={updating}
                                    category={category}
                                    listingData={modalData}
                                    currentTab={currentTab}
                                    setProduct={setProduct}
                                    setUpdating={setUpdating}
                                    setCategory={setCategory}
                                    setCurrentTab={setCurrentTab}
                                    locationAndService={locationAndService}
                                    handleUpdateListing={handleUpdateListing}
                                    handleCreateListing={handleCreateListing}
                                    setLocationAndService={setLocationAndService}
                                  />
                                  : dataFlag === 'product' && operationFlag === 'view'
                                    ? <ProductCreationForm
                                      viewFlag={true}
                                      editFlag={false}
                                      productData={modalData}
                                    />
                                    : dataFlag === 'product' && operationFlag === 'edit'
                                      ? <ProductCreationForm
                                        editFlag={true}
                                        viewFlag={false}
                                        productData={modalData}
                                        handleEditProduct={handleEditProduct}
                                      />
                                      : dataFlag === 'service' && operationFlag === 'view'
                                        ? <ServiceCreationForm
                                          viewFlag={true}
                                          editFlag={false}
                                          serviceData={modalData}
                                        />
                                        : dataFlag === 'service' && operationFlag === 'edit'
                                          ? <ServiceCreationForm
                                            editFlag={true}
                                            viewFlag={false}
                                            serviceData={modalData}
                                            handleEditService={handleEditService}
                                          />
                                          : <></>
                : dataFlag === 'listing' && operationFlag === ''
                  ? <ListingCreationForm
                    editFlag={false}
                    viewFlag={false}
                    product={product}
                    updating={updating}
                    category={category}
                    listingData={modalData}
                    currentTab={currentTab}
                    setProduct={setProduct}
                    setCategory={setCategory}
                    setCurrentTab={setCurrentTab}
                    creatingListing={creatingListing}
                    locationAndService={locationAndService}
                    handleUpdateListing={handleUpdateListing}
                    handleCreateListing={handleCreateListing}
                    setLocationAndService={setLocationAndService}
                  />
                  : dataFlag === 'auth' && operationFlag === 'auth'
                    ? <Signup claimFlag={true} closeModal={closeModal} />
                    : <></>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    currentUser: state.userReducer.user,
    listing: state.listingReducer.listing,
    latestError: state.errorReducer.latestError,
    localLocationAndServices: state.formReducer.locationAndServices
  }
}

export default connect(mapStateToProps, null)(CustomModal)
