import API from './api'

const AuthService = {
  login: (data) => {
    return API.post('/user/login', data)
      .then(({ data }) => {
        setHeadersAndStorage(data)
        return data
      })
      .catch(err => { throw err })
  },
  register: (data) => {
    return API.post('/user/signup', data)
      .then(({ data }) => {
        setHeadersAndStorage(data)
        return data
      })
      .catch(err => { return err })
  },
  logout: () => {
    return API.delete('/user/logout')
      .then(({ data }) => {
        removeHeadersAndStorage()
        return data
      })
      .catch(err => { return err })
  },
  verifyEmail: (data) => {
    return API.post(`/user/verify?id=${data}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => { return err })
  }
}

const setHeadersAndStorage = ({ access_token, user_data }) => {
  API.defaults.headers['Authorization'] = `Bearer ${access_token}`

  localStorage.setItem('name', user_data.name)
  localStorage.setItem('user_id', user_data.id)
  localStorage.setItem('role', user_data.role_name)
  localStorage.setItem('access_token', access_token)
}

const removeHeadersAndStorage = () => {
  API.defaults.headers['Authorization'] = ``

  localStorage.clear()
}

export default AuthService