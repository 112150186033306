import {
  GETUSER,
  GETUSERS,
} from "../actionTypes"

const initialState = {
  user: {},
  users: {},
}

const userReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GETUSER:
      return {
        ...state,
        user: payload
      }

    case GETUSERS:
      return {
        ...state,
        users: payload
      }

    default: return state
  }
}

export default userReducer