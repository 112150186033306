import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Navbar from '../../navbar/Navbar'
import { withRouter, useRouteMatch } from 'react-router-dom'

const Layout = ({
  children,
  location,
  ...rest
}) => {
  const { url } = useRouteMatch()

  return (
    <>
      {url === '/login' || url === '/signup' || url.includes('/verifyEmail')
        ? <></>
        : <Navbar />
      }
      <div
        className=""
        style={{
          paddingTop: url === '/' ? '' : '4%',
          minHeight: "100vh"
        }}
      >
        {children}
      </div>
    </>
  )
}

Layout.propType = {
  children: PropTypes.any,
  location: PropTypes.object
}

export default connect(null, null)(withRouter(Layout))
