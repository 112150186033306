import API from './api'

const RoleService = {
  createRole: (data) => {
    return API.post('/roles/add', data)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  updateRole: (data) => {
    return API.patch('/roles/update', data)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  deleteRole: (data) => {
    return API.delete(`/roles/delete?role_id=${data}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  getRoles: (data) => {
    return API.get(`/roles/?page_no=${data}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  getRolesDropdown: (data) => {
    return API.get(`/roles/dropdown`)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  getRole: (data) => {
    return API.get(`/roles/detail?role_id=${data}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
}

export default RoleService