import React from 'react'
import PropTypes from "prop-types"
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'

const RouteMiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => {
  const isLoggedIn = useSelector(state => state.authReducer.isLoggedIn)

  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthProtected && !isLoggedIn)
          return (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location }
              }}
            />
          )

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }}
    />
  )
}

RouteMiddleware.propTypes = {
  component: PropTypes.any,
  location: PropTypes.object,
  isAuthProtected: PropTypes.bool
}

export default RouteMiddleware
