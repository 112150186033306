import {
  GETCLAIMS
} from "../actionTypes"

const initialState = {
  claims: {}
}

const claimReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GETCLAIMS:
      return {
        ...state,
        claims: payload
      }

    default: return state
  }
}

export default claimReducer