import API from './api'

const ProductService = {
  createProduct: (data) => {
    return API.post('/products/add', data)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  updateProduct: (data) => {
    return API.patch('/products/update', data)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  deleteProduct: (data) => {
    return API.delete(`/products/delete?id=${data}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  getProducts: (data) => {
    return API.get(`/products/?page_no=${data}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  getProduct: (data) => {
    return API.get(`/products/read?id=${data}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  getProductByUserId: (data) => {
    return API.get(`/products/product_read?user_id=${data}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
}

export default ProductService