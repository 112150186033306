import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory from 'react-bootstrap-table2-filter'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'

const DataTable = ({
  data,
  columns,
  rowEvents,
  tableHeader,
}) => {
  const { SearchBar } = Search

  const selectRow = {
    mode: 'checkbox',
    // clickToSelect: true
  }

  return (
    <ToolkitProvider
      search
      data={data}
      keyField='id'
      bordered={false}
      columns={columns}
    >
      {
        props => (
          <>
            <h5>{tableHeader}</h5>
            <SearchBar {...props.searchProps} />
            <hr />
            <div
              style={{
                maxHeight: '85vh',
                overflowY: 'auto'
              }}
            >
              <BootstrapTable
                rowEvents={rowEvents}
                selectRow={selectRow}
                filter={filterFactory()}
                {...props.baseProps}
              />
            </div>
          </>
        )
      }
    </ToolkitProvider>
  )
}

export default DataTable
