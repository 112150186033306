import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

const ViewListingCard = ({
  claiming,
  claimMade,
  reduxListing,
  handleClaimListing,
}) => {
  const history = useHistory()
  const { pathname } = useLocation()

  return (
    <div className="card p-4 shadow">
      <div className="row">
        <div className="col-lg-4">
          <div className="text-block row">
            <h4 className="mb-3">Title</h4>
          </div>
          <div className='text-block row'>
            <h4 className="mb-3 col text-muted">{reduxListing.Data[0].title}</h4>
          </div>
        </div>
        <div className='col-lg-4'>
          <div className="row">
            <h4 className="mb-3">Category</h4>
          </div>
          <div className='row mb-3 mb-lg-0'>
            <h4 className="col mb-3 text-muted">
              {reduxListing.Data[0].cat_data !== undefined
                ? reduxListing.Data[0].cat_data.name
                : ""
              }
            </h4>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="row text-block">
            <h4 className="mb-3">Description</h4>
          </div>
          <div className='row text-block'>
            <h4 className="mb-4 text-muted ps-3">{reduxListing.Data[0].description}</h4>
          </div>
        </div>
      </div>
      <hr className="text-muted" />

      <div className="text-block">
        <div className="row">
          <div className="col-sm">
            <h6>Address</h6>
            <p className="text-muted">{reduxListing.Data[0].address}</p>
          </div>
          {reduxListing.Data[0].contact_info === undefined
            ? <></>
            : reduxListing.Data[0].contact_info.length === 0
              ? <></>
              : <div className="col-sm">
                <h6>Phone</h6>
                <div className="row">
                  {
                    reduxListing.Data[0].contact_info.map((singlePhone, i) => (
                      <p
                        key={i}
                        className="text-muted col"
                        style={{
                          maxWidth: 'min-content',
                          minWidth: 'max-content'
                        }}
                      >
                        {singlePhone}
                      </p>
                    ))
                  }
                </div>
              </div>
          }
        </div>
      </div>
      <div className="text-block">
        <div className="d-flex align-items-center justify-content-between mb-3 row">
          {reduxListing.Data[0].videos === undefined
            ? <></>
            : reduxListing.Data[0].videos.length === 0
              ? <></>
              : <div className="col-sm">
                <h6>Videos</h6>
                <div className="row">
                  {
                    reduxListing.Data[0].videos.map((video, i) => (
                      <a
                        key={i}
                        className="text-muted col"
                        style={{
                          maxWidth: 'min-content',
                          minWidth: 'max-content'
                        }}
                        href={video}
                      >
                        {video}
                      </a>
                    ))
                  }
                </div>
              </div>
          }
          {reduxListing.Data[0].website === undefined
            ? <></>
            : reduxListing.Data[0].website.length === 0
              ? <></>
              : <div className="col">
                <h6>Websites</h6>
                <div className="row">
                  {
                    reduxListing.Data[0].website.map((website, i) => (
                      <a
                        key={i}
                        className="text-muted col"
                        style={{
                          maxWidth: 'min-content',
                          minWidth: 'max-content'
                        }}
                        href={website}
                      >
                        {website}
                      </a>
                    ))
                  }
                </div>
              </div>
          }
        </div>
        <div className="d-flex align-items-center justify-content-between mb-3 row">
          {reduxListing.Data[0].social_profile === undefined
            ? <></>
            : reduxListing.Data[0].social_profile.length === 0
              ? <></>
              : <div className="col">
                <h6>Social Links</h6>
                <div className="row">
                  {
                    reduxListing.Data[0].social_profile.map((social_profile, i) => (
                      <a
                        key={i}
                        className="text-muted col"
                        style={{
                          maxWidth: 'min-content',
                          minWidth: 'max-content'
                        }}
                        href={social_profile}
                      >
                        {social_profile}
                      </a>
                    ))
                  }
                </div>
              </div>
          }
        </div>
      </div>
      <div className="text-block">
        <div>
          {reduxListing.Data[0].loc_ser === undefined
            || reduxListing.Data[0].loc_ser.length === 0
            ? <></>
            : <>
              <hr className="text-muted" />
              <h4 className="hero-heading mb-3 text-muted">Location</h4>
            </>
          }
          {reduxListing.Data[0].loc_ser === undefined
            || reduxListing.Data[0].loc_ser.length === 0
            ? <></>
            : reduxListing.Data[0].loc_ser.map((singleLocationAndService, i) => (
              <>
                <div key={i} className="card px-0 mt-2">
                  <div className="card-header">
                    <h5 className="card-title">{singleLocationAndService.location.name}</h5>
                  </div>
                  <div className="card-body row">
                    <h4 className="text-muted">Services</h4>
                    {singleLocationAndService.service.map((singleService, i) => (
                      <div key={i} className="card col py-3 mx-2">
                        <h4 className="hero-heading mb-3">{singleService.title}</h4>
                        <h6 className="mb-4" style={{ fontWeight: '400' }}>{singleService.description}</h6>
                      </div>
                    ))
                    }
                  </div>
                </div>
              </>
            ))
          }
        </div>
        <div className="row mt-3">
          {reduxListing.Data[0].product_data === undefined
            || reduxListing.Data[0].product_data.length === 0
            ? <></>
            : <>
              <hr className="text-muted" />
              <h4 className="hero-heading mb-3 text-muted">Products</h4>
            </>
          }
          {reduxListing.Data[0].product_data === undefined
            || reduxListing.Data[0].product_data.length === 0
            ? <></>
            : reduxListing.Data[0].product_data.map((product, i) => (
              <>
                <div key={i} className="card col py-3 mx-2">
                  <div className="row">
                    <div className="col">
                      <h3 className="hero-heading mb-3">{product.title}</h3>
                      <h6 className="mb-4" style={{ fontWeight: '400' }}>{product.description}</h6>
                    </div>
                  </div>
                </div>
              </>
            ))
          }
        </div>
      </div>
      {reduxListing.Data[0].is_claimed
        ? <></>
        : <div className="row mt-3">
          <div className="col">
            {localStorage.getItem('role') === 'admin'
              || claimMade
              ? <></>
              : <button
                disabled={claiming}
                className="btn btn-primary float-end"
                // data-bs-toggle={localStorage.getItem('role') === 'vendor' ? "" : 'modal'}
                onClick={() => {
                  if (localStorage.getItem('role') === 'vendor')
                    handleClaimListing()
                  else
                    history.push('/login', { from: pathname })
                }}
              // data-bs-target={localStorage.getItem('role') === 'vendor' ? "" : "#customModal"}
              >
                {claiming
                  ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  : <></>
                }{" "}
                {claiming
                  ? 'Forwarding Claim'
                  : 'Claim Listing'
                }
              </button>
            }
          </div>
        </div>

      }
    </div>
  )
}

export default ViewListingCard
