import * as Yup from "yup"
import Select from 'react-select'
import { connect } from 'react-redux'
import { useDispatch } from 'react-redux'
import {
  getChildLocations,
  getLocation,
  getLocations,
  getParentLocations
} from '../../../redux/actions/location.action'
import React, { useEffect, useState } from 'react'
import { Field, FieldArray, Form, Formik } from 'formik'
import { SETFORMPROGRESS } from "../../../redux/actionTypes"
import ServicesService from '../../../api-services/servicesService'
import locationService from "../../../api-services/locationService"
import { getServicesByUserId } from '../../../redux/actions/service.action'

const createLocationSchema = Yup.object().shape({
  parent_id: Yup.string(),
  location_name: Yup.string().min(2, 'Too Short').required('Required'),
})

const createServiceSchema = Yup.object().shape({
  title: Yup.string().min(3, 'Too Short').required('Required'),
  description: Yup.string().min(3, 'Too Short').required('Required')
})

const LocationAndService = ({
  editFlag,
  updating,
  setUpdating,
  reduxCities,
  listingData,
  reduxServices,
  reduxAllLocations,
  locationAndService,
  handleUpdateListing,
  reduxChildLocations,
  reduxParentLocations,
  setLocationAndService,
  reduxLocationChildren,
  reduxServicesByUserId,
}) => {
  const dispatch = useDispatch()

  let localLocationAndServicesArray = []
  let localLocationAndServicesViewArray = []
  const [services, setServices] = useState([])
  const [location, setLocation] = useState('')
  const [latestLocation, setLatestLocation] = useState('')
  const [currentLocation, setCurrentLocation] = useState('')
  let localLocationAndServices = { location: '', service: [] }
  const [locationAndServiceState, setLocationAndServiceState] = useState([])
  const [locationAndServiceViewState, setLocationAndServiceViewState] = useState([])

  const handleCreateService = async values => {
    let serviceFormData = new FormData()

    serviceFormData.append('title', values.title)
    serviceFormData.append('description', values.description)
    serviceFormData.append('user_id', localStorage.getItem('user_id'))

    const response = await ServicesService.createService(serviceFormData)

    if (response.error === false) {
      dispatch(getServicesByUserId())
      dispatch(getParentLocations())
      dispatch(getChildLocations())
    }
  }

  const handleCreateLocation = async values => {
    let locationFormData = new FormData()

    locationFormData.append('location_name', values.location_name)
    locationFormData.append('parent_id', values.city_id !== '' ? values.city_id : values.state_id !== '' ? values.state_id : values.parent_id)

    const response = await locationService.createLocation(locationFormData)

    if (response.error === false) {
      dispatch(getLocations())
      dispatch(getParentLocations())
      dispatch(getChildLocations())
      setLocation({ location_name: '', parent_id: '' })
    }
  }

  const handleLocationChange = e => {
    const { name, value } = e.target

    setLatestLocation(value)

    localLocationAndServicesArray = JSON.parse(JSON.stringify(locationAndServiceState))

    if (editFlag) {
      if (localLocationAndServicesArray[name.charAt(name.length - 1)] !== undefined) {
        if (value !== '' && value !== 'Select State' && value !== 'Select City' && value !== 'Select Country') {
          localLocationAndServicesArray[name.charAt(name.length - 1)].location = value
          setLocationAndServiceState(localLocationAndServicesArray)
        } else {
          if (locationAndServiceViewState[name.charAt(name.length - 1)].location.parent_data !== undefined) {
            localLocationAndServicesArray[name.charAt(name.length - 1)].location = locationAndServiceViewState[name.charAt(name.length - 1)].location.parent_data.id
            setLocationAndServiceState(localLocationAndServicesArray)
          } else if (locationAndServiceViewState[name.charAt(name.length - 1)].location.parent_parent_data !== undefined) {
            localLocationAndServicesArray[name.charAt(name.length - 1)].location = locationAndServiceViewState[name.charAt(name.length - 1)].location.parent_parent_data.id
            setLocationAndServiceState(localLocationAndServicesArray)
          }
        }
        // localLocationAndServicesArray[name.charAt(name.length - 1)][0] = value
      } else {
        // localLocationAndServicesArray[name.charAt(name.length - 1)] = [value]
        localLocationAndServices.location = value
        localLocationAndServicesArray[name.charAt(name.length - 1)] = localLocationAndServices
        setLocationAndServiceState(localLocationAndServicesArray)
      }

    } else {
      if (localLocationAndServicesArray[name.charAt(name.length - 1)] !== undefined) {
        localLocationAndServicesArray[name.charAt(name.length - 1)].push(localLocationAndServices)
        // localLocationAndServicesArray[name.charAt(name.length - 1)][0] = value
      } else {
        // localLocationAndServicesArray[name.charAt(name.length - 1)] = [value]
        localLocationAndServices.location = value
        localLocationAndServicesArray[name.charAt(name.length - 1)] = localLocationAndServices
      }

      if (name.includes('location')) {
        // setCurrentLocation(value)
        // localLocationAndServices.location_id.push(value)

        // if (localLocationAndServicesArray[name.charAt(name.length - 1)] !== undefined) {
        //   localLocationAndServicesArray[name.charAt(name.length - 1)][0] = value
        // } else {
        //   localLocationAndServicesArray[name.charAt(name.length - 1)] = [value]
        // }
        // localLocationAndServices.location_id[name.charAt(name.length - 1)] = value

        dispatch(getLocation(value, 'states'))
      }

      if (name.includes('state_id')) {
        // console.log(value)
        // setCurrentLocation(value)
        // localLocationAndServices.location_id[name.charAt(name.length - 1)] = value

        // console.log(localLocationAndServicesArray[name.charAt(name.length - 1)])
        // if (localLocationAndServicesArray[name.charAt(name.length - 1)] !== undefined) {
        //   localLocationAndServicesArray[name.charAt(name.length - 1)][0] = value
        // } else {
        //   localLocationAndServicesArray[name.charAt(name.length - 1)] = [value]
        // }

        // localLocationAndServices.location_id.push(value)
        dispatch(getLocation(value, 'cities'))
      }

      if (name.includes('city_id')) {
        // setCurrentLocation(value)
        // localLocationAndServices.location_id[name.charAt(name.length - 1)] = value

        // if (localLocationAndServicesArray[name.charAt(name.length - 1)] !== undefined) {
        //   localLocationAndServicesArray[name.charAt(name.length - 1)][0] = value
        // } else {
        //   localLocationAndServicesArray[name.charAt(name.length - 1)] = [value]
        // }

        // localLocationAndServices.location_id.push(value)
      }

      if (name.includes('service')) {
        // console.log(localLocationAndServicesArray)
        // if (localLocationAndServicesArray[name.charAt(name.length - 1)] !== undefined)
        //   localLocationAndServicesArray[name.charAt(name.length - 1)].push(value)
        // else
        //   localLocationAndServicesArray[name.charAt(name.length - 1)] = [value]
        // localLocationAndServices.services.push(...services)
        // localLocationAndServices.location_id = currentLocation 

        // console.log(localLocationAndServices)
        // setLocationAndService([...locationAndService, localLocationAndServices])
      }
    }

    // console.log(updatingArray)

    // console.log(localLocationAndServicesArray)
  }

  // useEffect(() => {
  //   listingData !== undefined
  //     ? localLocationAndServicesArray.unshift(currentLocation/*listingData.location_data.id*/)
  //     : localLocationAndServicesArray.unshift(currentLocation)

  //   // localLocationAndServicesArray.forEach(localService => {
  //   //   for (let i = 0; i < services.length; i++) {
  //   //     if (localService !== services[i + 1]) {
  //   //       console.log(services[i + 1])
  //   //     }
  //   //     // localLocationAndServicesArray.push(services[i + 1])
  //   //   }
  //   // })

  //   services.forEach(service => {
  //     if (service.value !== undefined) {
  //       // localLocationAndServicesArray.forEach(localService => {
  //       // if (localService !== service.value || localLocationAndServicesArray.length < 2) {
  //       // localLocationAndServicesArray.splice(1)
  //       localLocationAndServicesArray.push(service.value)
  //       // }
  //       // })
  //       // localLocationAndServices.services.push(service.value)
  //     }
  //     else {
  //       // localLocationAndServices.services.push(...services)

  //       // localLocationAndServicesArray.splice(1)
  //       localLocationAndServicesArray.push(service)
  //     }
  //   })

  //   console.log(localLocationAndServicesArray)

  //   // localLocationAndServices.location_id === '' && listingData !== undefined
  //   //   ? localLocationAndServices.location_id = listingData.services.location_id
  //   //   : localLocationAndServices.location_id = currentLocation
  //   // console.log(localLocationAndServicesArray)
  // }, [services])

  useEffect(() => {
    if (currentLocation !== localLocationAndServicesArray[0])
      localLocationAndServicesArray.unshift(currentLocation)

    if (listingData !== undefined) {
      services.forEach(service => {
        if (service.value !== undefined) {
          // localLocationAndServicesArray.splice(1)
          // localLocationAndServicesArray.push(service.value)
          // localLocationAndServices.services.push(service.value)
        }
        else {
          localLocationAndServicesArray.splice(1)
          localLocationAndServicesArray.push(...services)
          localLocationAndServices.services.push(...services)
        }
      })
    }

    // localLocationAndServicesArray.unshift(currentLocation)
    // console.log(localLocationAndServicesArray)

    // localLocationAndServices.location_id = currentLocation
  }, [currentLocation])

  useEffect(() => {
    // if (listingData !== undefined && listingData.service_data !== undefined)
    //   listingData.service_data.forEach(service => {
    //     setServices(prevService =>
    //       [...prevService, { label: service.title, value: service.id }]
    //     )

    //     // localLocationAndServicesArray.splice(1)
    //     localLocationAndServicesArray.push(service.id)
    //   })

    let filteredServices = []

    if (listingData !== undefined && listingData.loc_ser !== undefined) {
      localLocationAndServicesArray = JSON.parse(JSON.stringify(listingData.loc_ser))
      localLocationAndServicesViewArray = JSON.parse(JSON.stringify(listingData.loc_ser))

      localLocationAndServicesArray.forEach(localLocationAndService => {
        localLocationAndService.location = localLocationAndService.location.id

        if (localLocationAndService.service !== undefined) {
          let serviceArr = []
          localLocationAndService.service.forEach(singleService => {
            serviceArr.push(singleService.id)
          })

          localLocationAndService.service = serviceArr
        }
      })
      setLocationAndServiceState(localLocationAndServicesArray)

      // setLocationAndServiceViewState(localLocationAndServicesArray)
      // setCurrentLocation(listingData.location_data.id)
    }

    if (reduxServicesByUserId.Data !== undefined) {
      reduxServicesByUserId.Data.map(service => {
        return { value: service.id, label: service.title }
      }).filter(service => {
        if (localLocationAndServicesViewArray !== undefined) {
          localLocationAndServicesViewArray.forEach(singleLocation => {
            if (singleLocation.service !== undefined) {
              singleLocation.service.forEach(function (singleService, index) {
                if (service.value === singleService.id)
                  this[index] = service
              }, singleLocation.service)
            }
          })
        }
      })

      setLocationAndServiceViewState(localLocationAndServicesViewArray)
    }

    // dispatch({ type: SETFORMPROGRESS, payload: localLocationAndServicesArray })

    // setLocationAndServiceState(localLocationAndServicesArray)
  }, [listingData])

  // useEffect(() => {
  //   console.log(locationAndServiceState)
  //   if (locationAndServiceState.length !== 0 && locationAndServiceState !== undefined) {

  //     for (let i = 0; i <= locationAndServiceState.length; i++) {
  //       if (locationAndServiceState[i] !== undefined) {
  //         localLocationAndServices.location = locationAndServiceState[i].location !== undefined ? locationAndServiceState[i].location.id : ""
  //         if (updatingArray[i] !== undefined) {
  //           updatingArray[i].push(localLocationAndServices)
  //           // localLocationAndServicesArray[name.charAt(name.length - 1)][0] = value
  //         } else {
  //           // localLocationAndServicesArray[name.charAt(name.length - 1)] = [value]
  //           localLocationAndServices.location = locationAndServiceState[i].location !== undefined ? locationAndServiceState[i].location.id : ""
  //           updatingArray[i] = localLocationAndServices
  //         }

  //         localLocationAndServices.services = locationAndServiceState[i].service
  //         if (updatingArray[i] !== undefined) {
  //           updatingArray[i].push(localLocationAndServices)
  //           // localLocationAndServicesArray[name.charAt(name.length - 1)][0] = value
  //         } else {
  //           // localLocationAndServicesArray[name.charAt(name.length - 1)] = [value]
  //           localLocationAndServices.services = locationAndServiceState[i].service
  //           updatingArray[i] = localLocationAndServices
  //         }
  //         // updatingArray[i].location = locationAndServiceState[i].location !== undefined
  //         //   ? locationAndServiceState[i].location.id : ""
  //       }
  //       // if (locationAndServiceState[i] !== undefined) {
  //       //   localLocationAndServices.services = locationAndServiceState[i].service
  //       //   if (updatingArray[i] !== undefined) {
  //       //     updatingArray[i].push(localLocationAndServices)
  //       //     // localLocationAndServicesArray[name.charAt(name.length - 1)][0] = value
  //       //   } else {
  //       //     // localLocationAndServicesArray[name.charAt(name.length - 1)] = [value]
  //       //     localLocationAndServices.services = locationAndServiceState[i].service
  //       //     updatingArray[i] = localLocationAndServices
  //       //   }
  //       // }
  //     }
  //     // updatingArray.forEach(singleIndex => {
  //     //   singleIndex.location = singleIndex.location.id
  //     //   singleIndex.service.forEach(function (singleService, index) {
  //     //     this[index] = singleService.id
  //     //   }, singleIndex.service)
  //     // })

  //     console.log(updatingArray)
  //   }
  // }, [locationAndServiceState])

  return (
    <div className='row'>
      <div className='col-lg-6'>
        {editFlag
          ? <Formik
            enableReinitialize={true}
            initialValues={{
              location_service: locationAndServiceViewState
            }}
            onSubmit={(values) => {
              // setLocationAndService([...locationAndService, localLocationAndServicesArray])
            }}
          >
            {({
              dirty,
              values,
              errors,
              touched,
              isValid,
              isSubmitting,
              setFieldTouched,
            }) => (
              <Form onChange={e => handleLocationChange(e)}>
                <FieldArray name="location_service">
                  {({ insert, remove, push }) => (
                    <>
                      {values.location_service !== undefined
                        ? values.location_service.map((singleLocServ, index) => (
                          <div className="card p-2 shadow mt-3">
                            <div className="row">
                              <div className="col-lg-12">
                                <h6 className="text-muted">
                                  <small>Country</small>
                                </h6>
                                {singleLocServ.location === undefined
                                  ? <></>
                                  : <div className="input-group">
                                    <Field
                                      name={`location.${index}`}
                                      as="select"
                                      className={
                                        errors.location
                                          ? "border border-danger form-select"
                                          : "form-select"
                                      }
                                    >
                                      <option defaultValue>Select Country</option>
                                      {reduxParentLocations.Data !== undefined
                                        ? reduxParentLocations.Data.map((location, i) =>
                                          <option
                                            key={i}
                                            value={location.id}
                                            selected={
                                              singleLocServ.location.parent_parent_data !== undefined
                                                ? location.id === singleLocServ.location.parent_parent_data.id
                                                : singleLocServ.location.parent_data !== undefined
                                                  ? location.id === singleLocServ.location.parent_data.id
                                                  : location.id === singleLocServ.location.id
                                            }
                                          >
                                            {location.name}
                                          </option>
                                        )
                                        : <></>
                                      }
                                    </Field>
                                  </div>
                                }
                                {errors.location && touched.location
                                  ? <small className="float-end text-danger">{errors.location}</small>
                                  : null
                                }
                              </div>

                              <div className="col-lg-6 mt-3">
                                <h6 className="text-muted">
                                  <small>State</small>
                                </h6>
                                {reduxLocationChildren.Data !== undefined
                                  && reduxLocationChildren.Data.length !== 0
                                  && singleLocServ.location !== undefined
                                  ? <Field
                                    as="select"
                                    name={`state_id.${index}`}
                                    className={errors.state_id
                                      ? "border border-danger form-select col-lg-12 px-2"
                                      : "form-select col-lg-12 px-2"
                                    }
                                  >
                                    <option defaultValue>Select State</option>
                                    {reduxLocationChildren.Data.length !== 0
                                      ? reduxLocationChildren.Data.map(childLocation =>
                                        <option
                                          value={childLocation.id}
                                          selected={
                                            singleLocServ.location.parent_parent_data !== undefined
                                              ? childLocation.id === singleLocServ.location.parent_data.id
                                              : childLocation.id === singleLocServ.location.id
                                          }
                                        >
                                          {childLocation.name}
                                        </option>
                                      )
                                      : <></>
                                    }
                                  </Field>
                                  : <p>No States found</p>
                                }
                                {errors.description && touched.description
                                  ? <small className="float-end text-danger">{errors.description}</small>
                                  : null
                                }
                              </div>

                              <div className="col-lg-6 mt-3">
                                <h6 className="text-muted">
                                  <small>City</small>
                                </h6>
                                {reduxLocationChildren.Data !== undefined
                                  && reduxLocationChildren.Data.length !== 0
                                  && singleLocServ.location !== undefined
                                  ? <Field
                                    as="select"
                                    name={`city_id.${index}`}
                                    className={errors.state_id
                                      ? "border border-danger form-select col-lg-12 px-2"
                                      : "form-select col-lg-12 px-2"
                                    }
                                  >
                                    <option value=''>
                                      {
                                        reduxLocationChildren.Data.length === 0
                                          ? 'No City Found'
                                          : 'Select City'
                                      }
                                    </option>
                                    {reduxLocationChildren.Data.length !== 0
                                      ? reduxLocationChildren.Data.map(childLocation =>
                                        <option
                                          value={childLocation.id}
                                          selected={
                                            singleLocServ.location.parent_parent_data !== undefined
                                              ? childLocation.id === singleLocServ.location.id
                                              : ""
                                          }
                                        >
                                          {childLocation.name}
                                        </option>
                                      )
                                      : <></>
                                    }
                                  </Field>
                                  : <p>No Cities found</p>
                                }
                                {errors.description && touched.description
                                  ? <small className="float-end text-danger">{errors.description}</small>
                                  : null
                                }
                              </div>

                              <div className="col-lg-12 mt-3">
                                <h6 className="text-muted"><small>Services</small></h6>
                                {reduxServicesByUserId.Data.length !== 0
                                  && locationAndServiceViewState[index] !== undefined
                                  ? <Select
                                    isMulti
                                    value={locationAndServiceViewState[index].service}
                                    name={`service_id.${index}`}
                                    onBlur={() => setFieldTouched(`service_id.${index}`, true)}
                                    onChange={
                                      value => {
                                        localLocationAndServices.services = value

                                        let locationAndServiceViewStateCopy = JSON.parse(JSON.stringify(locationAndServiceViewState))

                                        locationAndServiceViewStateCopy[index].service = localLocationAndServices.services

                                        setLocationAndServiceViewState(locationAndServiceViewStateCopy)

                                        let locationAndServiceStateCopy = JSON.parse(JSON.stringify(locationAndServiceState))

                                        locationAndServiceStateCopy[index].service = value.map(singleValue => { return `${singleValue.value}` })
                                        setLocationAndServiceState(locationAndServiceStateCopy)
                                        localLocationAndServices.location = latestLocation

                                        // updatingArray[index] = localLocationAndServices
                                      }
                                    }
                                    options={
                                      reduxServicesByUserId.Data.map(service => {
                                        return { value: service.id, label: service.title }
                                      })
                                    }
                                  />
                                  : <p>No Services found</p>
                                }
                                {errors.description && touched.description
                                  ? <small className="float-end text-danger">{errors.description}</small>
                                  : null
                                }
                              </div>
                            </div>
                            <button
                              type="button"
                              onClick={() => {
                                remove(index)
                                let filteredArray = locationAndServiceState.filter(singleLocation => singleLocation.location !== locationAndServiceState[index].location)
                                // console.log(locationAndServiceState)
                                setLocationAndServiceState(filteredArray)
                                // setLocationAndServiceViewState(filteredArray)
                              }}
                              className="btn btn-danger col-lg-12 mt-2 px-2 py-1"
                            >
                              Remove this Location
                            </button>
                          </div>
                        ))
                        : <></>
                      }
                      <button
                        type="button"
                        onClick={() => {
                          push('')
                          setLocationAndServiceViewState(locationAndServiceViewState => [...locationAndServiceViewState, { location: '', service: [] }])
                        }}
                        className="btn btn-primary col-lg-12 mt-3 px-2 py-1"
                      >
                        Add Another Location
                      </button>
                    </>
                  )}
                </FieldArray>
              </Form>
            )}
          </Formik>
          : <Formik
            initialValues={{
              location_service: ['']
            }}
            onSubmit={(values) => {
              // setLocationAndService([...locationAndService, localLocationAndServices])
              // setLocationAndService(localLocationAndServicesArray)
              // handleUpdateListing(values)
            }}
          >
            {({
              dirty,
              values,
              errors,
              touched,
              isValid,
              isSubmitting,
              setFieldTouched,
            }) => (
              <Form onChange={e => handleLocationChange(e)}>
                <FieldArray name="location_service">
                  {({ insert, remove, push }) => (
                    <>
                      {values.location_service.length > 0 &&
                        values.location_service.map((single_location_service, index) => (
                          <div className="card p-2 shadow mt-3">
                            <div className="row">
                              <div className="col-lg-12">
                                <h6 className="text-muted">
                                  <small>Country</small>
                                </h6>
                                <div className="input-group">
                                  <Field
                                    name={`location.${index}`}
                                    as="select"
                                    className={
                                      errors.location
                                        ? "border border-danger form-select"
                                        : "form-select"
                                    }
                                  >
                                    <option defaultValue>Select Location</option>
                                    {reduxParentLocations.Data !== undefined
                                      ? reduxParentLocations.Data.map((location, i) =>
                                        <option key={i} value={location.id}>{location.name}</option>
                                      )
                                      : <></>
                                    }
                                  </Field>
                                </div>
                                {errors.location && touched.location
                                  ? <small className="float-end text-danger">{errors.location}</small>
                                  : null
                                }
                              </div>

                              <div className="col-lg-6 mt-3">
                                <h6 className="text-muted">
                                  <small>State</small>
                                </h6>
                                {reduxChildLocations !== undefined && reduxChildLocations.length !== 0
                                  ? <Field
                                    as="select"
                                    name={`state_id.${index}`}
                                    className={errors.state_id
                                      ? "border border-danger form-select col-lg-12 px-2"
                                      : "form-select col-lg-12 px-2"
                                    }
                                  >
                                    <option value=''>
                                      {
                                        reduxChildLocations.length === 0
                                          ? 'No States Found'
                                          : 'Select State'
                                      }
                                    </option>
                                    {reduxChildLocations !== undefined
                                      ? reduxChildLocations.map((childLocation, i) =>
                                        <option key={i} value={childLocation.id}>{childLocation.name}</option>
                                      )
                                      : <></>
                                    }
                                  </Field>
                                  : <p>No States found</p>
                                }

                                {errors.description && touched.description
                                  ? <small className="float-end text-danger">{errors.description}</small>
                                  : null
                                }
                              </div>

                              <div className="col-lg-6 mt-3">
                                <h6 className="text-muted">
                                  <small>City</small>
                                </h6>
                                {reduxCities.data !== undefined && reduxCities.data.length !== 0
                                  ? <Field
                                    as="select"
                                    name={`city_id.${index}`}
                                    className={errors.state_id
                                      ? "border border-danger form-select col-lg-12 px-2"
                                      : "form-select col-lg-12 px-2"
                                    }
                                  >
                                    <option value=''>
                                      {
                                        reduxCities.data.length === 0
                                          ? 'No Cities Found'
                                          : 'Select City'
                                      }
                                    </option>
                                    {reduxCities.data.length !== 0
                                      ? reduxCities.data.map(childLocation =>
                                        <option value={childLocation.id}>{childLocation.name}</option>
                                      )
                                      : <></>
                                    }
                                  </Field>
                                  : <p>No Cities found</p>
                                }
                                {errors.description && touched.description
                                  ? <small className="float-end text-danger">{errors.description}</small>
                                  : null
                                }
                              </div>

                              <div className="col-lg-12 mt-3">
                                <h6 className="text-muted">
                                  <small>Services</small>
                                </h6>
                                {reduxServicesByUserId.Data !== undefined
                                  && reduxServicesByUserId.Data.length !== 0
                                  ? <Select
                                    isMulti
                                    name={`service_id.${index}`}
                                    onBlur={() => setFieldTouched(`service_id.${index}`, true)}
                                    onChange={
                                      value => {
                                        // console.log(localLocationAndServicesArray[index])
                                        localLocationAndServices.service = value.map(singleValue => {
                                          return `${singleValue.value}`
                                        })

                                        localLocationAndServices.location = latestLocation

                                        localLocationAndServicesArray = locationAndServiceState.splice(index, 1, localLocationAndServices)


                                        // setLocationAndServiceState(locationAndServiceState.splice(index, 1))

                                        locationAndServiceState.splice(index, 1, localLocationAndServices)

                                        // console.log(localLocationAndServicesArray)

                                        // setLocationAndService([...locationAndService, localLocationAndServices])

                                        // dispatch({ type: SETFORMPROGRESS, payload: localLocationAndServices })

                                        // if (localLocationAndServicesArray[index] !== undefined) {
                                        //   localLocationAndServicesArray[index].splice(1)
                                        //   localLocationAndServicesArray[index].push(...value.map(singleValue => {
                                        //     return `${singleValue.value}`
                                        //   }))
                                        // } else {
                                        //   // localLocationAndServicesArray[index].splice(1)
                                        //   localLocationAndServicesArray[index] = [...value.map(singleValue => {
                                        //     return `${singleValue.value}`
                                        //   })]
                                        // }

                                        // console.log(localLocationAndServicesArray)

                                        // setServices(value.map(singleValue => {
                                        //   return `${singleValue.value}`
                                        // }))
                                      }
                                    }
                                    options={
                                      reduxServicesByUserId.Data.map(service => {
                                        return { value: service.id, label: service.title }
                                      })
                                    }
                                  />
                                  : <p>No Services found</p>
                                }
                                {errors.description && touched.description
                                  ? <small className="float-end text-danger">{errors.description}</small>
                                  : null
                                }
                              </div>
                            </div>
                            <button
                              type="button"
                              onClick={() => {
                                remove(index)
                                localLocationAndServicesArray.splice(index, 1)
                              }}
                              className="btn btn-danger col-lg-12 mt-2 px-2 py-1"
                            >
                              Remove this Location
                            </button>
                          </div>
                        ))
                      }
                      <button
                        type="button"
                        onClick={() => {
                          // setLocationAndService([...locationAndService, localLocationAndServices])
                          dispatch({ type: SETFORMPROGRESS, payload: localLocationAndServices })

                          // console.log(localLocationAndServicesArray)
                          push('')
                        }}
                        className="btn btn-primary col-lg-12 mt-3 px-2 py-1"
                      >
                        Add Another Location
                      </button>
                    </>
                  )}
                </FieldArray>
              </Form>
            )}
          </Formik>
        }
      </div>
      <div className="col-lg-6 mt-3">
        <div className="card py-2 shadow row">
          <h6 className="text-muted">Add a Location if not already present</h6>
          <Formik
            validationSchema={createLocationSchema}
            onSubmit={(values, actions) => {
              handleCreateLocation(values)
              actions.resetForm()
            }}
            initialValues={{ location_name: '', parent_id: '', state_id: '', city_id: '' }}
          >
            {({ errors, touched, isSubmitting, isValid, dirty }) => (
              <Form onChange={e => handleLocationChange(e)}>
                <div className="col-lg-12">
                  <h6 className="text-muted">
                    <small>Location Name</small>
                  </h6>
                  <Field
                    id="location_name"
                    type="text"
                    name="location_name"
                    className={errors.location_name
                      ? "border border-danger form-control col-lg-12 px-2"
                      : "form-control col-lg-12 px-2"
                    }
                  />
                </div>
                {errors.location_name && touched.location_name
                  ? <small className="float-end text-danger">{errors.location_name}</small>
                  : null
                }
                <div className="row">
                  <>
                    <div className="col-lg-6 mt-3">
                      <h6 className="text-muted">
                        <small>Country</small>
                      </h6>
                      {reduxParentLocations.parent_data !== undefined
                        ? <Field
                          as="select"
                          name="parent_id"
                          className={errors.parent_id
                            ? "border border-danger form-select col-lg-12 px-2"
                            : "form-select col-lg-12 px-2"
                          }
                        >
                          <option value=''>
                            {
                              reduxParentLocations.parent_data.length === 0
                                ? 'No Country Found'
                                : 'Select Country'
                            }
                          </option>
                          {reduxParentLocations.parent_data.length !== 0
                            ? reduxParentLocations.parent_data.map((parentLocation, i) =>
                              <option key={i} value={parentLocation.id}>{parentLocation.name}</option>
                            )
                            : <></>
                          }
                        </Field>
                        : <p className="text-center mb-0">No Data Found</p>
                      }
                    </div>
                    {errors.description && touched.description
                      ? <small className="float-end text-danger">{errors.description}</small>
                      : null
                    }
                  </>
                  <>
                    <div className="col-lg-6 mt-3">
                      <h6 className="text-muted">
                        <small>State</small>
                      </h6>
                      {reduxChildLocations !== undefined
                        ?
                        <Field
                          as="select"
                          name="state_id"
                          className={errors.state_id
                            ? "border border-danger form-select col-lg-12 px-2"
                            : "form-select col-lg-12 px-2"
                          }
                        >
                          <option value=''>
                            {
                              reduxChildLocations.length === 0
                                ? 'No State Found'
                                : 'Select State'
                            }
                          </option>
                          {reduxChildLocations.length !== 0
                            ? reduxChildLocations.map((childLocation, i) =>
                              <option key={i} value={childLocation.id}>{childLocation.name}</option>
                            )
                            : <></>
                          }
                        </Field>
                        : <p>No States found</p>
                      }
                    </div>
                    {errors.description && touched.description
                      ? <small className="float-end text-danger">{errors.description}</small>
                      : null
                    }
                  </>
                </div>
                <div className="row mt-4 float-start">
                  <div className="col-lg-12">
                    <button
                      type='submit'
                      className="btn btn-primary rounded-3"
                      disabled={isSubmitting || !isValid || !dirty}
                    >
                      {isSubmitting
                        ? <span
                          role="status"
                          aria-hidden="true"
                          className="spinner-border spinner-border-sm"
                        ></span>
                        : <></>}{" "}
                      {isSubmitting
                        ? "Submitting"
                        : "Create Location"
                      }
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className='card py-2 shadow row mt-2'>
          <h6 className="text-muted">Add a Service if not already present</h6>
          <Formik
            initialValues={{ title: '', description: '' }}
            validationSchema={createServiceSchema}
            onSubmit={(values, actions) => {
              handleCreateService(values)
              actions.resetForm()
            }}
          >
            {({ errors, touched, isSubmitting, isValid, dirty }) => (
              <Form>
                <div className="col-lg-12">
                  <h6 className="text-muted">
                    <small>Title</small>
                  </h6>
                  <Field
                    id="title"
                    type="text"
                    name="title"
                    className={errors.title
                      ? "border border-danger form-control col-lg-12 px-2"
                      : "form-control col-lg-12 px-2"
                    }
                  />
                </div>
                {errors.title && touched.title
                  ? <small className="float-end text-danger">{errors.title}</small>
                  : null
                }
                <div className="col-lg-12 mt-3">
                  <h6 className="text-muted">
                    <small>Description</small>
                  </h6>
                  <Field
                    id="description"
                    type="text"
                    name="description"
                    className={errors.description
                      ? "border border-danger form-control col-lg-12 px-2"
                      : "form-control col-lg-12 px-2"
                    }
                  />
                </div>
                {errors.description && touched.description
                  ? <small className="float-end text-danger">{errors.description}</small>
                  : null
                }
                <div className="row mt-4 float-start">
                  <div className="col-lg-12">
                    <button
                      type='submit'
                      className="btn btn-primary rounded-3"
                      disabled={isSubmitting || !isValid || !dirty}
                    >
                      {isSubmitting
                        ? <span
                          role="status"
                          aria-hidden="true"
                          className="spinner-border spinner-border-sm"
                        ></span>
                        : <></>
                      }{" "}
                      {isSubmitting
                        ? "Submitting"
                        : "Create Service"
                      }
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <button
            onClick={() => {
              // setUpdating(true)
              // console.log(editFlag)
              if (editFlag) {
                // console.log(locationAndServiceState)
                setLocationAndService(locationAndServiceState)
              } else {
                // console.log(locationAndServiceState)
                setLocationAndService(locationAndServiceState)
              }
            }}
            className="btn btn-primary float-end rounded-3"
            disabled={
              editFlag
                ? locationAndServiceState.length === 0 || updating
                : locationAndService === '' || locationAndService === undefined || updating
            }
          >
            {updating
              ? <span
                role="status"
                aria-hidden="true"
                className="spinner-border spinner-border-sm"
              ></span>
              : <></>
            }{" "}
            {updating
              ? "Submitting"
              : "Add Location And Service"
            }
          </button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    reduxCities: state.locationReducer.cities,
    reduxServices: state.serviceReducer.services,
    reduxChildLocations: state.locationReducer.location,
    reduxAllLocations: state.locationReducer.allLocations,
    reduxLocationChildren: state.locationReducer.childLocations,
    reduxParentLocations: state.locationReducer.parentLocations,
    reduxServicesByUserId: state.serviceReducer.servicesByUser_Id,
  }
}

export default connect(mapStateToProps, null)(LocationAndService)
