import React from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'

const Sidebar = () => {
  const { url } = useRouteMatch()

  return (
    <div
      role="tablist"
      id="v-pills-tab"
      aria-orientation="vertical"
      style={{ minHeight: '93vh' }}
      className="nav flex-column nav-pills pe-1"
    >
      {localStorage.getItem('role') === 'vendor'
        ? <></>
        : <>
          <NavLink
            to={{
              pathname: '/claims'
            }}
            className={`nav-link ${url === '/claims' ? 'active' : ''}`}
          >
            Claims
          </NavLink>
          <NavLink
            to={{
              pathname: '/users'
            }}
            className={`nav-link ${url === '/users' ? 'active' : ''}`}
          >
            Users
          </NavLink>
          <NavLink
            to={{
              pathname: '/roles'
            }}
            className={`nav-link ${url === '/roles' ? 'active' : ''}`}
          >
            Roles
          </NavLink>
          <NavLink
            to={{
              pathname: '/locations'
            }}
            className={`nav-link ${url === '/locations' ? 'active' : ''}`}
          >
            Locations
          </NavLink>
          <NavLink
            to={{
              pathname: '/categories'
            }}
            className={`nav-link ${url === '/categories' ? 'active' : ''}`}
          >
            Categories
          </NavLink>
        </>
      }
      <NavLink
        to={{
          pathname: '/listings'
        }}
        className={`nav-link ${url === '/listings' ? 'active' : ''}`}
      >
        Listings
      </NavLink>
      <NavLink
        to={{
          pathname: '/products'
        }}
        className={`nav-link ${url === '/products' ? 'active' : ''}`}
      >
        Products
      </NavLink>
      <NavLink
        to={{
          pathname: '/services'
        }}
        className={`nav-link ${url === '/services' ? 'active' : ''}`}
      >
        Services
      </NavLink>
    </div>
  )
}

export default Sidebar
