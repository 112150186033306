import toast from 'react-hot-toast'
import Pagination from 'react-js-pagination'
import { AiOutlineEye } from 'react-icons/ai'
import { withRouter } from 'react-router-dom'
import { GETUSER } from '../../redux/actionTypes'
import UserCreationForm from './UserCreationForm'
import { connect, useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import UserService from '../../api-services/userService'
import { getUsers } from '../../redux/actions/user.action'
import DataTable from '../../components/dataTable/DataTable'
import CustomModal from '../../components/modal/CustomModal'
import { selectFilter } from 'react-bootstrap-table2-filter'
import { getAllRoles, getRoles } from '../../redux/actions/roles.action'
import { BsPencilSquare, BsPersonCheck, BsPersonX } from 'react-icons/bs'

const UserMainPage = ({
  reduxUsers,
  reduxRoles,
  latestError,
  reduxUserPagination
}) => {
  const dispatch = useDispatch()
  const [users, setUsers] = useState([])
  const [filter, setFilter] = useState('')
  const [loading, setLoading] = useState(false)
  const [activePage, setActivePage] = useState(1)
  const [currentUser, setCurrentUser] = useState({})
  const [suspending, setSuspending] = useState(false)
  const [operationFlag, setOperationFlag] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [user, setUser] = useState({ fullname: '', email: '', password: '', role: '' })

  const selectOptions = {
    true: 'Active',
    false: 'Suspended'
  }

  const statusFormatter = (cell, row) => {
    if (row.is_active)
      return (
        <span>
          <strong className='text-success'><h4>&#10003;</h4></strong>
        </span>
      )

    return (
      <span>
        <strong className='text-danger'><h4>&#10007;</h4></strong>
      </span>
    )
  }

  const columns = [{
    sort: true,
    text: 'Email',
    dataField: 'email',
  }, {
    sort: true,
    text: 'Name',
    dataField: 'fullname',
  }, {
    sort: true,
    text: 'Role',
    dataField: 'role_data',
  }, {
    sort: true,
    text: 'Status',
    align: 'center',
    dataField: 'is_active',
    filter: selectFilter({
      options: selectOptions
    }),
    formatter: statusFormatter
  }, {
    text: 'Actions',
    dataField: 'actions',
  }]

  const handlePageChange = (pageNumber) => {
    dispatch(getUsers(pageNumber))

    setActivePage(pageNumber)
  }

  const handleOnChange = e => {
    const { name, value } = e.target

    setUser(prevUser => ({ ...prevUser, [name]: value }))
  }

  const handleCreateUser = async (values, resetForm) => {

    let userFormData = new FormData()

    userFormData.append('email', values.email)
    userFormData.append('role_id', values.role)
    userFormData.append('fullname', values.fullname)
    userFormData.append('password', values.password)

    try {
      const response = await UserService.createUser(userFormData)

      if (response.error === false) {
        resetForm()
        toast.success('User Added Successfully')

        setUser({ fullname: '', email: '', password: '', role: '' })

        dispatch(getAllRoles())
        dispatch(getUsers())
      }
    } catch (error) {
      if (error.response)
        toast.error(error.response.data.msg)
      // dispatch({ type: SETERROR, payload: error.response.data.msg })
    }
  }

  const handleEdit = async (e, row) => {
    if (e.target.id === 'suspendUserIcon') {
      setSuspending(true)
      let userFormData = new FormData()

      userFormData.append('user_id', row.id)
      userFormData.append('is_active', !row.is_active)
      const response = await UserService.suspendUser(userFormData)

      if (response.error === false) {
        setSuspending(false)
        toast.success('User Edited Successfully')

        dispatch(getRoles())
        dispatch(getUsers())
      }

      setSuspending(false)
    } else {
      dispatch({ type: GETUSER, payload: row })
      setCurrentUser(row)
    }
  }

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      if (!suspending)
        handleEdit(e, row)
    }
  }

  useEffect(() => {
    setLoading(true)

    dispatch(getAllRoles())
    dispatch(getUsers())
  }, [])

  useEffect(() => {
    if (reduxUsers.Data !== undefined)
      if (reduxUsers.Data.length !== 0) {
        reduxUsers.Data.forEach(user => {
          user.role_data = user.role_data !== undefined ? user.role_data.role_name : ""
          user.actions = [
            <div className="text-center">
              <AiOutlineEye
                size='1.5em'
                data-bs-toggle="modal"
                data-bs-target="#customModal"
                style={{ cursor: 'pointer' }}
                className='text-info mt-1'
                onClick={() => setOperationFlag('view')}
              />{" "}
              {!user.is_active
                ? <BsPersonCheck
                  size='1.5em'
                  id='suspendUserIcon'
                  className='text-success'
                  style={{ cursor: 'pointer' }}
                />
                : <BsPersonX
                  size='1.5em'
                  id='suspendUserIcon'
                  className='text-danger'
                  style={{ cursor: 'pointer' }}
                />
              }{" "}
              <BsPencilSquare
                size='1.5em'
                data-bs-toggle="modal"
                className="text-primary"
                data-bs-target="#customModal"
                style={{ cursor: 'pointer' }}
                onClick={() => setOperationFlag('edit')}
              />
            </div>
          ]
        })
        setLoading(false)
        setUsers(reduxUsers.Data)
      }
  }, [reduxUsers])

  useEffect(() => {
    if (users.length !== 0)
      toast.success('Users listed successfully')
  }, [users])

  // useEffect(() => {
  //   setLoading(false)
  // }, [user])

  useEffect(() => {
    if (latestError !== '') {
      setLoading(false)
      toast.error(latestError)
    }
  }, [latestError])

  return (
    <>
      <div className="row">
        <div className="col">
          <div
            className={loading
              ? "card p-3 d-flex justify-content-center align-items-center"
              : "card p-3"
            }
            style={{ maxHeight: '85vh', overflowY: 'auto' }}
          >
            {loading
              ? <div
                role="status"
                style={{
                  width: '4rem',
                  height: '4rem'
                }}
                className="spinner-border text-primary"
              ></div>
              : users.length === 0
                ? <h5 className="card-title text-center">No Users Found</h5>
                : <DataTable
                  data={users}
                  columns={columns}
                  rowEvents={rowEvents}
                  tableHeader={'Users'}
                />
            }
          </div>
          {reduxUsers.pagination_data === undefined
            ? <></>
            : <div className="float-end mt-2">
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                pageRangeDisplayed={6}
                activePage={activePage}
                onChange={handlePageChange}
                totalItemsCount={reduxUsers.pagination_data.total_items}
                itemsCountPerPage={reduxUsers.pagination_data.items_per_page}
              />
            </div>
          }
        </div>
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Add New User</h5>
              <UserCreationForm
                user={user}
                editFlag={false}
                showPassword={showPassword}
                handleOnChange={handleOnChange}
                handleCreateUser={handleCreateUser}
                setShowPassword={setShowPassword}
              />
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        dataFlag={'user'}
        modalData={currentUser}
        operationFlag={operationFlag}
        modalTitle={currentUser.fullname}
      />
    </>
  )
}

const mapStateToProps = state => {
  return {
    reduxUsers: state.userReducer.users,
    reduxUserPagination: state.userReducer.userPagination,
    latestError: state.errorReducer.latestError
  }
}

export default connect(mapStateToProps, null)(withRouter(UserMainPage))