import React from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux'
import Navbar from '../../navbar/Navbar'
import Sidebar from '../../sidebar/Sidebar'
import { withRouter, useRouteMatch } from 'react-router-dom'

const Layout = ({ children, location, ...rest }) => {
  const { url } = useRouteMatch()

  return (
    <div className={url === '/' ? '' : `container-fluid`}>
      <div className="row">
        <Navbar />
      </div>
      <div
        style={{ paddingTop: '4%' }}
        className={`${url === '/dashboard' ? '' : 'container-fluid'}`}
      >
        <div className="row">
          {url === "/dashboard"
            ? <></>
            : <div
              className="col-lg-2 col-md-2 col-sm-2 pt-2 rounded-end shadow"
              style={{ position: 'fixed' }}
            >
              <Sidebar />
            </div>
          }
          <div className='col pt-2' style={{ marginLeft: url === "/dashboard" ? '' : '17%' }}>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

Layout.propType = {
  children: PropTypes.any,
  location: PropTypes.object
}

export default connect(null, null)(withRouter(Layout))
