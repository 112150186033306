import toast from 'react-hot-toast'
import Pagination from 'react-js-pagination'
import { withRouter } from 'react-router-dom'
import { GETROLE } from '../../redux/actionTypes'
import RoleCreationForm from './RoleCreationForm'
import { connect, useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { BsPencilSquare, BsTrash } from 'react-icons/bs'
import RoleService from '../../api-services/roleService'
import { getRoles } from '../../redux/actions/roles.action'
import DataTable from '../../components/dataTable/DataTable'
import CustomModal from '../../components/modal/CustomModal'

const RoleMainPage = ({
  reduxRoles,
  latestError,
  reduxRolesPagination
}) => {
  const dispatch = useDispatch()
  const [roles, setRoles] = useState([])
  const [loading, setLoading] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [activePage, setActivePage] = useState(1)
  const [currentRole, setCurrentRole] = useState({})
  const [role, setRole] = useState({ role_name: '' })

  const handlePageChange = (pageNumber) => {
    dispatch(getRoles(pageNumber))

    setActivePage(pageNumber)
  }

  const columns = [{
    dataField: 'role_name',
    text: 'Role',
    sort: true
  }, {
    dataField: 'actions',
    text: 'Actions'
  }]

  const handleOnChange = e => {
    const { name, value } = e.target

    setRole(prevRole => ({ ...prevRole, [name]: value }))
  }

  const handleCreateRole = async (values, resetForm) => {

    let roleFormData = new FormData()

    roleFormData.append('role_name', values.role_name)

    try {
      const response = await RoleService.createRole(roleFormData)

      if (response.error === false) {
        resetForm()

        toast.success('Role Added Successfully')
        setRole({ role_name: '' })
        dispatch(getRoles())
      }
    } catch (error) {
      setLoading(false)

      if (error.response)
        toast.error(error.response.data.msg)
    }
  }

  const handleDeletion = async (roleId) => {
    setDeleting(true)

    try {
      const response = await RoleService.deleteRole(roleId)

      if (response.error === false) {
        dispatch(getRoles())
        setDeleting(false)
      }
    } catch (error) {
      setDeleting(false)
      toast.error(error.response.data.msg)
    }

  }

  const handleEdit = (e, row) => {
    dispatch({ type: GETROLE, payload: row })
    setCurrentRole(row)
  }

  const rowEvents = {
    onClick: (e, row, rowIndex) => handleEdit(e, row)
  }

  useEffect(() => {
    setLoading(true)

    dispatch(getRoles())
  }, [])

  useEffect(() => {
    if (reduxRoles.Data !== undefined) {
      if (reduxRoles.Data.length !== 0) {
        reduxRoles.Data.forEach(role => {
          role.actions = [
            <div className="text-center">
              <BsTrash
                className='text-danger'
                size='1.5em'
                style={{ cursor: deleting ? 'default' : 'pointer' }}
                onClick={!deleting ? () => handleDeletion(role.id) : ''}
              />{" "}
              <BsPencilSquare
                className="text-primary"
                size='1.5em'
                data-bs-toggle="modal"
                data-bs-target="#customModal"
                style={{ cursor: 'pointer' }}
              />
            </div>
          ]
        })
        setLoading(false)
        setRoles(reduxRoles.Data)
      }
    }
  }, [reduxRoles])

  useEffect(() => {
    if (roles.length !== 0)
      toast.success('Roles listed successfully')
  }, [roles])

  // useEffect(() => {
  //   setLoading(false)
  // }, [role])

  useEffect(() => {
    if (latestError !== '') {
      setLoading(false)
      toast.error(latestError)
    }
  }, [latestError])

  return (
    <>
      <div className="row">
        <div className="col">
          <div
            className={loading
              ? "card p-3 d-flex justify-content-center align-items-center"
              : "card p-3"
            }
            style={{ maxHeight: '85vh', overflowY: 'auto' }}
          >
            {loading
              ? <div
                className="spinner-border text-primary"
                style={{
                  width: '3rem',
                  height: '3rem'
                }}
                role="status"
              ></div>
              : roles.length === 0
                ? <h5 className="card-title text-center">No Roles Found</h5>
                : <DataTable
                  data={roles}
                  columns={columns}
                  rowEvents={rowEvents}
                  tableHeader={'Roles'}
                />
            }
          </div>
          {reduxRoles.pagination_data === undefined
            ? <></>
            : <div className="float-end mt-2">
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                pageRangeDisplayed={6}
                activePage={activePage}
                onChange={handlePageChange}
                totalItemsCount={reduxRoles.pagination_data.total_items}
                itemsCountPerPage={reduxRoles.pagination_data.items_per_page}
              />
            </div>
          }
        </div>
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Add New Role</h5>
              <RoleCreationForm
                role={role}
                editFlag={false}
                handleOnChange={handleOnChange}
                handleCreateRole={handleCreateRole}
              />
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        dataFlag={'role'}
        operationFlag={'edit'}
        modalData={currentRole}
        modalTitle={currentRole.role_name}
      />
    </>
  )
}

const mapStateToProps = state => {
  return {
    reduxRoles: state.roleReducer.roles,
    latestError: state.errorReducer.latestError,
    reduxRolesPagination: state.roleReducer.rolePagination,
  }
}

export default connect(mapStateToProps, null)(withRouter(RoleMainPage))
