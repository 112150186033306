import React from 'react'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { BsBoxArrowLeft } from 'react-icons/bs'
import { Link, useHistory } from 'react-router-dom'
import AuthService from '../../api-services/authService'
import { LOGOUT, SETERROR } from '../../redux/actionTypes'

const Navbar = () => {
  let history = useHistory()
  const dispatch = useDispatch()

  return (
    <nav
      style={{
        top: 0,
        left: 0,
        right: 0,
        zIndex: 9999,
        position: 'fixed',
      }}
      className="navbar navbar-expand-lg navbar-light bg-light shadow-sm row"
    >
      <div className="col-lg-8 col-md-2 col-sm-2">
        <Link
          className="navbar-brand mb-1 ms-4"
          to={{ pathname: "/" }}
        >
          Directory
        </Link>
      </div>
      <div className="col-lg-4 col-md-10 col-sm-10 me-5 pe-5 sm-pe-5 sm-me-5">
        {localStorage.getItem('access_token')
          ? <div className="collapse navbar-collapse float-end">
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <a
                  href="#"
                  role="button"
                  id="navbarDropdown"
                  aria-expanded="false"
                  data-bs-toggle="dropdown"
                  className="nav-link dropdown-toggle"
                >
                  {localStorage.getItem('name')}
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdown"
                >
                  <li>
                    <Link
                      to={{ pathname: "/listings/all" }}
                      className="dropdown-item"
                    >
                      View Listings
                    </Link>
                  </li>
                  {localStorage.getItem('access_token')
                    ? <li>
                      <Link
                        to={{ pathname: "/dashboard" }}
                        className="dropdown-item"
                      >
                        Dashboard
                      </Link>
                    </li>
                    : <></>
                  }
                  <li>
                    {localStorage.getItem('access_token')
                      ? <button
                        className="dropdown-item"
                        onClick={async () => {
                          const response = await AuthService.logout()

                          try {
                            if (!response.error) {
                              dispatch({ type: LOGOUT })
                              toast.success(response.msg)
                              history.replace('/')
                            }
                          } catch (error) {
                            if (error.response)
                              dispatch({ type: SETERROR, payload: error.response.data.msg })
                          }
                        }}
                      >
                        <BsBoxArrowLeft /> Logout
                      </button>
                      : <Link
                        className="dropdown-item"
                        aria-current="page"
                        to={{ pathname: '/login' }}
                      >
                        Login
                      </Link>
                    }
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          : <div className='float-end'>
            <Link
              to={{ pathname: "/listings/all" }}
              className="btn btn-primary"
            >
              View Listings
            </Link>{" "}
            <Link
              aria-current="page"
              className="btn btn-primary"
              to={{ pathname: '/login' }}
            >
              Login
            </Link>
          </div>
        }
      </div>
    </nav>
  )
}

export default Navbar
