import API from "./api"

const claimService = {
  claimListing: (data) => {
    return API.post(`/claim/add`, data)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  getClaims: (data) => {
    return API.get(`/claim/?page_no=${data}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  approveClaim: (data) => {
    return API.patch(`/claim/claim`, data)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
}

export default claimService