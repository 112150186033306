import {
  GETPRODUCT,
  GETPRODUCTS,
  GETPRODUCTSBYUSER_ID
} from "../actionTypes"

const initialState = {
  product: {},
  products: {},
  productsByUser_Id: [],
}

const productReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GETPRODUCT:
      return {
        ...state,
        product: payload
      }

    case GETPRODUCTS:
      return {
        ...state,
        products: payload
      }

    case GETPRODUCTSBYUSER_ID:
      return {
        ...state,
        productsByUser_Id: payload
      }

    default: return state
  }
}

export default productReducer