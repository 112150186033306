import toast from "react-hot-toast"
import { css } from "@emotion/react"
import { connect } from 'react-redux'
import MetaTags from 'react-meta-tags'
import { useDispatch } from 'react-redux'
import BarLoader from "react-spinners/BarLoader"
import React, { useEffect, useState } from 'react'
import { GETLISTING } from "../../redux/actionTypes"
import claimService from "../../api-services/claimService"
import CustomModal from '../../components/modal/CustomModal'
import { Link, useParams, useRouteMatch } from 'react-router-dom'
import { getListingNoAuth } from '../../redux/actions/listing.action'
import ViewListingCard from "../../components/listing/ViewListingCard"

const override = css`
  display: block;
  margin: 0 auto;
  margin-top: 10%;
  border-color: red;
  border-radius: 10px;
`;

const ViewListing = ({
  reduxListing,
}) => {

  const { id } = useParams()
  const { url } = useRouteMatch()
  const dispatch = useDispatch()

  const [urlSplit, setURLSplit] = useState([])
  const [loading, setLoading] = useState(false)
  const [claiming, setClaiming] = useState(false)
  const [claimMade, setClaimMade] = useState(false)

  const handleClaimListing = async () => {
    setClaiming(true)

    let claimFormData = new FormData()

    claimFormData.append('user_id', localStorage.getItem('user_id'))
    claimFormData.append('list_id', id)

    try {
      const response = await claimService.claimListing(claimFormData)

      if (response.error === false) {
        setClaiming(false)
        setClaimMade(response.Flag)
        toast.success('Listing Claim Forwarded Successfully')
        // dispatch(getListingNoAuth(id))
      }
    } catch (error) {
      setClaiming(false)
      setClaimMade(false)
      if (error.response)
        toast.error(error.response.data.msg)
    }
  }

  useEffect(() => {
    setURLSplit(url.split('/'))
    setLoading(true)

    dispatch(getListingNoAuth(id))
  }, [])

  useEffect(() => {
    return () => {
      dispatch({ type: GETLISTING, payload: [] })
    }
  }, [])

  useEffect(() => {
    if (reduxListing.Data !== undefined)
      setLoading(false)
  }, [reduxListing])

  return (
    <>
      <MetaTags>
        <title>{`${reduxListing.Data !== undefined ? reduxListing.Data[0].title : ""}`}</title>
        <meta property="og:title" content={`${reduxListing.Data !== undefined ? reduxListing.Data[0].title : ""}`} />
        <meta name="description" content={`${reduxListing.Data !== undefined ? reduxListing.Data[0].description : ""}`} />
      </MetaTags>
      <div className="container">
        {loading
          ? <div className="col-lg-12 col-12">
            <BarLoader css={override} height={5} width={1000} color={"#00b4d8"} />
          </div>
          : reduxListing.Data !== undefined
            ? <>
              <ol className="breadcrumb ps-0  justify-content-start">
                <li className="breadcrumb-item"><Link to={{ pathname: '/' }}>Home</Link></li>
                <li className="breadcrumb-item"><Link to={{ pathname: `/categories/${urlSplit[2]}`, state: { id: reduxListing.Data[0].cat_data.parent.id } }}>{urlSplit[2]}</Link></li>
                <li className="breadcrumb-item"><Link to={{ pathname: `/listings/${urlSplit[2]}/${urlSplit[3]}`, state: { id: reduxListing.Data[0].cat_data.id, parentData: { id: reduxListing.Data[0].cat_data.parent.id, name: reduxListing.Data[0].cat_data.parent.name } } }}>{urlSplit[3]}</Link></li>
                <li className="breadcrumb-item active">{reduxListing.Data[0].title}</li>
              </ol>
              <ViewListingCard
                claiming={claiming}
                claimMade={claimMade}
                reduxListing={reduxListing}
                handleClaimListing={handleClaimListing}
              />
            </>
            : <></>
        }
        {localStorage.getItem('access_token')
          ? <></>
          : <CustomModal
            dataFlag={'auth'}
            operationFlag={'auth'}
            modalData={{}}
            modalTitle={''}
          />
        }
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    reduxListing: state.listingReducer.listing
  }
}

export default connect(mapStateToProps, null)(ViewListing)
