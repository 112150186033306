import * as Yup from "yup"
import React, { useState } from 'react'
import { withRouter } from "react-router"
import { Field, Form, Formik } from "formik"
import { connect, useDispatch } from 'react-redux'
import { getLocation } from "../../redux/actions/location.action"

const createLocationSchema = Yup.object().shape({
  parent_id: Yup.string(),
  location_name: Yup.string().min(3, 'Too Short').required('Required'),
})

const LocationCreationForm = ({
  editFlag,
  viewFlag,
  reduxCities,
  locationData,
  handleEditLocation,
  reduxChildLocations,
  handleCreateLocation,
  reduxParentLocations,
}) => {
  const dispatch = useDispatch()
  const [gettingStates, setGettingStates] = useState(false)
  const [gettingCities, setGettingCities] = useState(false)

  const handleLocationChange = e => {
    const { name, value } = e.target
    if (name === 'parent_id') {
      setGettingStates(true)
      dispatch(getLocation(value, 'states'))
      setGettingStates(false)
    }
    else if (name === 'state_id') {
      setGettingCities(true)
      dispatch(getLocation(value, 'cities'))
      setGettingCities(false)
    }
  }

  return (
    <>
      {!editFlag && !viewFlag ?
        <Formik
          validationSchema={createLocationSchema}
          onSubmit={(values, { resetForm }) => {
            handleCreateLocation(values, resetForm)
          }}
          initialValues={{ location_name: '', parent_id: '', state_id: '', city_id: '' }}
        >
          {({ errors, touched, isSubmitting, isValid, dirty }) => (
            <Form onChange={e => handleLocationChange(e)}>
              <div className="col-lg-12">
                <h6 className="text-muted"><small>LOCATION NAME</small></h6>
                <Field
                  id="location_name"
                  type="text"
                  name="location_name"
                  className={errors.location_name
                    ? "border border-danger form-control col-lg-12 px-2"
                    : "form-control col-lg-12 px-2"
                  }
                />
              </div>
              {errors.location_name && touched.location_name ? (
                <small className="float-end text-danger">{errors.location_name}</small>
              ) : null}
              <div className="row mt-3">
                <div className="col-lg-6">
                  <h6 className="text-muted"><small>COUNTRY</small></h6>
                  {reduxParentLocations.Data !== undefined
                    ? <Field
                      as="select"
                      name="parent_id"
                      className={errors.parent_id ? "border border-danger form-select col-lg-12 px-2" : "form-select col-lg-12 px-2"}
                    >
                      <option value=''>{reduxParentLocations.Data.length === 0 ? 'No Country Found' : 'Select Country'}</option>
                      {reduxParentLocations.Data.length !== 0 ? reduxParentLocations.Data.map(parentLocation => (
                        <option value={parentLocation.id}>{parentLocation.name}</option>
                      )) : <></>}
                    </Field>
                    : <p className="text-center mb-0">No Data Found</p>
                  }
                </div>
                {errors.description && touched.description ? (
                  <small className="float-end text-danger">{errors.description}</small>
                ) : null
                }
                <div className="col-lg-6">
                  <h6 className="text-muted"><small>STATE</small></h6>
                  {reduxChildLocations !== undefined && reduxChildLocations.length !== 0
                    ? <Field
                      as="select"
                      name="state_id"
                      className={errors.state_id ? "border border-danger form-select col-lg-12 px-2" : "form-select col-lg-12 px-2"}
                    >
                      <option value=''>
                        {reduxChildLocations.length === 0 && !gettingStates
                          ? 'No State Found'
                          : reduxChildLocations.length !== 0 && gettingStates
                            ? 'Loading States'
                            : 'Select State'
                        }
                      </option>
                      {reduxChildLocations.length !== 0 ? reduxChildLocations.map(childLocation => (
                        <option value={childLocation.id}>{childLocation.name}</option>
                      )) : <></>
                      }
                    </Field>
                    : <p>Loading States</p>
                  }
                  {errors.description && touched.description ? (
                    <small className="float-end text-danger">{errors.description}</small>
                  ) : null}
                </div>
              </div>
              <div className="row mt-4 float-start">
                <div className="col-lg-12">
                  <button
                    type='submit'
                    className="btn btn-primary rounded-3"
                    disabled={isSubmitting || !isValid || !dirty}
                  >
                    {isSubmitting
                      ? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      : <></>}{" "}
                    {isSubmitting
                      ? "Submitting"
                      : "Create Location"
                    }
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        : editFlag && !viewFlag ?
          <Formik
            enableReinitialize={true}
            initialValues={{ location_name: locationData.name, parent_id: locationData.parent_id }}
            validationSchema={createLocationSchema}
            onSubmit={(values, { resetForm }) => {
              handleEditLocation(values, resetForm)
            }}
          >
            {({ errors, touched, isSubmitting, isValid, dirty }) => (
              <Form>
                <div className="col-lg-12">
                  <h6 className="text-muted"><small>Location Name</small></h6>
                  <Field
                    id="location_name"
                    type="text"
                    name="location_name"
                    className={errors.location_name ? "border border-danger form-control col-lg-12 px-2" : "form-control col-lg-12 px-2"}
                  />
                </div>
                {errors.location_name && touched.location_name ? (
                  <small className="float-end text-danger">{errors.location_name}</small>
                ) : null}
                <div className="col-lg-12  mt-3">
                  <h6 className="text-muted"><small>Parent</small></h6>
                  <Field
                    as="select"
                    name="parent_id"
                    className={errors.parent_id ? "border border-danger form-control col-lg-12 px-2" : "form-control col-lg-12 px-2"}
                  >
                    <option value=''>Select Country</option>
                    {reduxParentLocations.map(parentLocation => (
                      <option selected={parentLocation.id === locationData.parent_id} value={parentLocation.id} >{parentLocation.name}</option>
                    ))}
                  </Field>
                </div>
                {errors.description && touched.description ? (
                  <small className="float-end text-danger">{errors.description}</small>
                ) : null}
                <div className="row mt-4 float-start">
                  <div className="col-lg-12">
                    <button
                      type='submit'
                      className="btn btn-primary rounded-3"
                      disabled={isSubmitting || !isValid || !dirty}
                    >
                      {isSubmitting
                        ? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        : <></>}{" "}
                      {isSubmitting
                        ? "Submitting"
                        : "Edit Location"
                      }
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          : !editFlag && viewFlag && locationData !== undefined ?
            <form>
              <div className='row'>
                <div className="mb-3">
                  <label htmlFor="locationField" className="form-label">Location Name</label>
                  <input type="text" className="form-control" id="locationField" name="name" disabled value={locationData.name} />
                </div>
              </div>
              {locationData.parent_id === null
                ? <></>
                : <div className='row'>
                  <div className="mb-3">
                    <label htmlFor="parentLocationField" className="form-label">Parent Location</label>
                    <select type="text" className="form-control" id="parentLocationField" name="parent_id" disabled>
                      <option defaultValue>No Parent</option>
                      {reduxParentLocations !== undefined ? reduxParentLocations.map((reduxParentLocation, i) =>
                        <option key={i} selected={reduxParentLocation.id === locationData.parent_id._id.$oid} value={reduxParentLocation.id}>{reduxParentLocation.name}</option>
                      ) : <></>}
                    </select>
                  </div>
                </div>
              }
            </form> :
            <></>
      }
    </>
  )
}

const mapStateToProps = state => {
  return {
    reduxRoles: state.roleReducer.roles,
    reduxCities: state.locationReducer.cities,
    reduxChildLocations: state.locationReducer.location,
    reduxParentLocations: state.locationReducer.parentLocations,
  }
}

export default connect(mapStateToProps, null)(withRouter(LocationCreationForm))

