import API from './api'

const ServicesService = {
  createService: (data) => {
    return API.post('/services/add', data)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  updateService: (data) => {
    return API.patch('/services/update', data)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  deleteService: (id, userId) => {
    return API.delete(`/services/delete?id=${id}&user_id=${userId}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  getServices: (data) => {
    return API.get(`/services/?page_no=${data}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  getServiceByUserId: (data) => {
    return API.get(`/services/service_read?user_id=${data}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  getService: (data) => {
    return API.get(`/services/read?id=${data}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
}

export default ServicesService