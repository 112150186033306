import listingService from "../../api-services/listingService"
import {
  SETERROR,
  GETLISTING,
  GETLISTINGS,
  GETPAGINATION,
} from "../actionTypes"

export const getListing = (params, history) => async dispatch => {

  try {
    const data = await listingService.getListing(params)
    dispatch({ type: GETLISTING, payload: data })
    dispatch({ type: SETERROR, payload: '' })
  } catch (err) {
    if (err.response)
      dispatch({ type: SETERROR, payload: err.response.data.msg })
  }
}

export const getListings = (pageNumber = 1) => async dispatch => {
  try {
    const data = await listingService.getListings(pageNumber)
    dispatch({ type: GETLISTINGS, payload: data })

    dispatch({ type: SETERROR, payload: '' })
  } catch (err) {
    if (err.response)
      dispatch({ type: SETERROR, payload: err.response.data.msg })
  }
}

export const getListingNoAuth = (params, history) => async dispatch => {
  try {
    const data = await listingService.getListingNoAuth(params)
    dispatch({ type: GETLISTING, payload: data })
    dispatch({ type: SETERROR, payload: '' })
  } catch (err) {
    if (err.response)
      dispatch({ type: SETERROR, payload: err.response.data.msg })
  }
}

export const getListingsNoAuth = (pageNumber = 1) => async dispatch => {
  try {
    const data = await listingService.getListingsNoAuth(pageNumber)
    dispatch({ type: GETPAGINATION, payload: data })
    dispatch({ type: GETLISTINGS, payload: data })

    dispatch({ type: SETERROR, payload: '' })
  } catch (err) {
    if (err.response)
      dispatch({ type: SETERROR, payload: err.response.data.msg })
  }
}

export const getListingsByUserId = (pageNumber = 1) => async dispatch => {
  try {
    const data = await listingService.getListingsByUserId(localStorage.getItem('user_id'), pageNumber)
    dispatch({ type: GETLISTINGS, payload: data })
    dispatch({ type: SETERROR, payload: '' })
  } catch (err) {
    if (err.response)
      dispatch({ type: SETERROR, payload: err.response.data.msg })
  }
}

export const getListingsWithCategory = (pageNumber = 1, categoryId) => async dispatch => {
  try {
    const data = await listingService.getListingWithCategory(pageNumber, categoryId)
    dispatch({ type: GETLISTINGS, payload: data })

    dispatch({ type: SETERROR, payload: '' })
  } catch (err) {
    if (err.response)
      dispatch({ type: SETERROR, payload: err.response.data.msg })
  }
}