import TreeMenu from 'react-simple-tree-menu'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { getChildrenCategories } from '../../../redux/actions/category.action'

const Category = ({
  updating,
  category,
  editFlag,
  listingData,
  setCategory,
  reduxCategories,
  handleUpdateListing,
  reduxChildCategories,
  reduxParentCategories,
}) => {
  const dispatch = useDispatch()

  const [cascadedCategory, setCascadedCategory] = useState([])

  // useEffect(() => {
  //   let cascadeCategory = []

  //   reduxParentCategories.map(parentCategory => {
  //     cascadeCategory.push({
  //       key: parentCategory.id,
  //       label: parentCategory.name,
  //       nodes: [
  //         reduxChildCategories.map(childCategory => {
  //           return {
  //             key: childCategory.id,
  //             label: childCategory.name
  //           }
  //         })
  //       ]
  //     })
  //   })

  //   setCascadedCategory(cascadeCategory)
  // }, [])

  return (
    <>
      <div className="row mt-3">
        <div className="col">
          <label htmlFor="categorySelect">
            <h6 className="text-muted">
              <small>Categories</small>
            </h6>
          </label>
          {editFlag
            ?
            <div className="row">
              <div className="col">
                {reduxParentCategories.parent_data === undefined || reduxParentCategories.parent_data.length === 0
                  ? <></>
                  : <select
                    id="categorySelect"
                    className="form-select mt-1"
                    onChange={e => setCategory(e.target.value)}
                  >
                    <option selected>Select Category</option>
                    {reduxParentCategories.parent_data.map((reduxCategory, i) => (
                      <option
                        key={i}
                        value={reduxCategory.id}
                        selected={listingData.cat_data == undefined
                          ? ""
                          : listingData.cat_data.parent === undefined
                            ? reduxCategory.id === listingData.cat_data.id
                            : reduxCategory.id === listingData.cat_data.parent
                        }
                      >
                        {reduxCategory.name}
                      </option>
                    ))
                    }
                  </select>
                }
              </div>
              <div className="col">
                {reduxChildCategories.data === undefined || reduxChildCategories.data.length === 0
                  ? <></>
                  : <select
                    id="categorySelect"
                    className="form-select mt-1"
                    onChange={e => setCategory(e.target.value)}
                  >
                    <option selected>Select Category</option>
                    {reduxChildCategories.data.map((reduxCategory, i) => (
                      <option
                        key={i}
                        value={reduxCategory.id}
                        selected={listingData.cat_data === undefined ? "" : reduxCategory.id === listingData.cat_data.id}
                      >
                        {reduxCategory.name}
                      </option>
                    ))
                    }
                  </select>
                }
              </div>
            </div>
            // <select
            //   id="categorySelect"
            //   className="form-select mt-1"
            //   onChange={e => setCategory(e.target.value)}
            // >
            //   <option>Select Category</option>
            //   {reduxCategories.map((reduxCategory, i) => (
            //     <option
            //       key={i}
            //       value={reduxCategory.id}
            //       selected={listingData.cat_data == undefined ? "" : reduxCategory.id === listingData.cat_data.id}
            //     >
            //       {reduxCategory.name}
            //     </option>
            //   ))
            //   }
            // </select>
            :
            // <TreeMenu
            //   onClickItem={({ key, label, ...props }) => {
            //     dispatch(getChildrenCategories(key))
            //   }}
            //   data={cascadedCategory}
            // >
            //   {({ items }) => (
            //     <ul className="list-group" style={{ cursor: 'pointer' }}>
            //       {items.map(({ key, label, ...props }) => (
            //         <li
            //           className="list-group-item"
            //           style={{ cursor: 'pointer' }}
            //           key={key}
            //           {...props}
            //           openedIcon={<i className="fas fa-plus-square"></i>}
            //           closedIcon={<i className="fas fa-minus-square"></i>}
            //         >
            //           <i className="fas fa-plus-square" style={{ cursor: 'pointer' }}></i>{" "}
            //           {label}
            //         </li>
            //       ))}
            //     </ul>
            //   )}
            // </TreeMenu>
            <div className="row">
              <div className="col">
                {reduxParentCategories.parent_data === undefined || reduxParentCategories.parent_data.length === 0
                  ? <></>
                  : <select
                    id="categorySelect"
                    className="form-select mt-1"
                    onChange={e => setCategory(e.target.value)}
                  >
                    <option selected>Select Category</option>
                    {reduxParentCategories.parent_data.map((reduxCategory, i) => (
                      <option key={i} value={reduxCategory.id}>{reduxCategory.name}</option>
                    ))
                    }
                  </select>
                }
              </div>
              <div className="col">
                {reduxChildCategories.data === undefined || reduxChildCategories.data.length === 0
                  ? <></>
                  : <select
                    id="categorySelect"
                    className="form-select mt-1"
                    onChange={e => setCategory(e.target.value)}
                  >
                    <option selected value="">Select Category</option>
                    {reduxChildCategories.data.map((reduxCategory, i) => (
                      <option key={i} value={reduxCategory.id}>{reduxCategory.name}</option>
                    ))
                    }
                  </select>
                }
              </div>
            </div>
          }
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <button
            onClick={handleUpdateListing}
            className="btn btn-primary float-end rounded-3"
            disabled={category === '' || category === undefined || updating}
          >
            {updating
              ? <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              : <></>
            }{" "}
            {updating
              ? "Submitting"
              : "Add Category"
            }
          </button>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    reduxCategories: state.categoryReducer.categories,
    reduxParentCategories: state.categoryReducer.parentCategories,
    reduxChildCategories: state.categoryReducer.childCategories
  }
}

export default connect(mapStateToProps, null)(Category)
