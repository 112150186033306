import React from 'react'
import { Link } from 'react-router-dom'

const AdminDashboard = () => {
  return (
    <>
      <div className="row text-center">
        <div className="col-lg-12">
          <h3>Welcome Back!</h3>
          <small
            className="text-muted fw-bold"
          >
            You can check Listings, Vendors and current Roles in system.
          </small>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <div className="card text-center">
            <div className="card-body">
              <img
                alt=''
                className="card-title"
                src="https://img.icons8.com/external-vitaliy-gorbachev-blue-vitaly-gorbachev/60/000000/external-list-support-vitaliy-gorbachev-blue-vitaly-gorbachev.png"
              />
              <p className="card-text mt-2">
                <small className="text-muted fw-bold">
                  View and edit current listings, add new listings.
                </small>
              </p>
              <Link
                className="btn btn-primary"
                to={{ pathname: "/listings" }}
              >
                Go to Listings
              </Link>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card text-center">
            <div className="card-body">
              <img
                alt=''
                className="card-title"
                src="https://img.icons8.com/external-vitaliy-gorbachev-blue-vitaly-gorbachev/60/000000/external-list-support-vitaliy-gorbachev-blue-vitaly-gorbachev.png"
              />
              <p className="card-text mt-2">
                <small className="text-muted fw-bold">
                  View and edit current Users, add new users.
                </small>
              </p>
              <Link
                to={{ pathname: "/users" }}
                className="btn btn-primary"
              >
                Manage your Users
              </Link>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card text-center">
            <div className="card-body">
              <img
                alt=''
                className="card-title"
                src="https://img.icons8.com/external-vitaliy-gorbachev-blue-vitaly-gorbachev/60/000000/external-list-support-vitaliy-gorbachev-blue-vitaly-gorbachev.png"
              />
              <p className="card-text mt-2">
                <small className="text-muted fw-bold">
                  View and edit current Roles, add new Roles.
                </small>
              </p>
              <Link
                to={{ pathname: "/roles" }}
                className="btn btn-primary"
              >
                Go to Roles
              </Link>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card text-center">
            <div className="card-body">
              <img
                alt=''
                className="card-title"
                src="https://img.icons8.com/external-vitaliy-gorbachev-blue-vitaly-gorbachev/60/000000/external-list-support-vitaliy-gorbachev-blue-vitaly-gorbachev.png"
              />
              <p className="card-text mt-2">
                <small className="text-muted fw-bold">
                  View and edit current Locations, add new Locations.
                </small>
              </p>
              <Link
                className="btn btn-primary"
                to={{ pathname: "/locations" }}
              >
                Go to Locations
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <div className="card text-center">
            <div className="card-body">
              <img
                alt=''
                className="card-title"
                src="https://img.icons8.com/external-vitaliy-gorbachev-blue-vitaly-gorbachev/60/000000/external-list-support-vitaliy-gorbachev-blue-vitaly-gorbachev.png"
              />
              <p className="card-text mt-2">
                <small className="text-muted fw-bold">
                  View, edit and add new Categories.
                </small>
              </p>
              <Link
                className="btn btn-primary"
                to={{ pathname: "/categories" }}
              >
                Go to Categories
              </Link>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card text-center">
            <div className="card-body">
              <img
                alt=''
                className="card-title"
                src="https://img.icons8.com/external-vitaliy-gorbachev-blue-vitaly-gorbachev/60/000000/external-list-support-vitaliy-gorbachev-blue-vitaly-gorbachev.png"
              />
              <p className="card-text mt-2">
                <small className="text-muted fw-bold">
                  View and edit current Products, add new Products.
                </small>
              </p>
              <Link
                className="btn btn-primary"
                to={{ pathname: "/products" }}
              >
                Go to Products
              </Link>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card text-center">
            <div className="card-body">
              <img
                alt=''
                className="card-title"
                src="https://img.icons8.com/external-vitaliy-gorbachev-blue-vitaly-gorbachev/60/000000/external-list-support-vitaliy-gorbachev-blue-vitaly-gorbachev.png"
              />
              <p className="card-text mt-2">
                <small className="text-muted fw-bold">
                  View and edit current Services, add new Services.
                </small>
              </p>
              <Link
                className="btn btn-primary"
                to={{ pathname: "/services" }}
              >
                Go to Services
              </Link>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card text-center">
            <div className="card-body">
              <img
                alt=''
                className="card-title"
                src="https://img.icons8.com/external-vitaliy-gorbachev-blue-vitaly-gorbachev/60/000000/external-list-support-vitaliy-gorbachev-blue-vitaly-gorbachev.png"
              />
              <p className="card-text mt-2">
                <small className="text-muted fw-bold">
                  View and approve pending Claims made by Vendors.
                </small>
              </p>
              <Link
                className="btn btn-primary"
                to={{ pathname: "/claims" }}
              >
                Go to Claims
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminDashboard
