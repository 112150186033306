import {
  GETSERVICE,
  GETSERVICES,
  GETSERVICESBYUSER_ID,
} from "../actionTypes"

const initialState = {
  service: {},
  services: {},
  servicesByUser_Id: {},
}

const serviceReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GETSERVICE:
      return {
        ...state,
        service: payload
      }

    case GETSERVICES:
      return {
        ...state,
        services: payload
      }

    case GETSERVICESBYUSER_ID:
      return {
        ...state,
        servicesByUser_Id: payload
      }

    default: return state
  }
}

export default serviceReducer