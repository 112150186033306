import * as Yup from "yup"
import Select from "react-select"
import { connect } from 'react-redux'
import { useDispatch } from "react-redux"
import { Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import ProductService from '../../../api-services/productService'
import { getProductsByUserId } from "../../../redux/actions/product.action"

const createProductSchema = Yup.object().shape({
  price: Yup.number().required('Required'),
  title: Yup.string().min(3, 'Too Short').required('Required'),
  description: Yup.string().min(3, 'Too Short').required('Required'),
})

const Product = ({
  product,
  editFlag,
  updating,
  setProduct,
  listingData,
  reduxProducts,
  handleUpdateListing
}) => {
  let filteredProducts = []

  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  const handleCreateProduct = async (values, resetForm) => {
    setLoading(true)

    let productFormData = new FormData()

    productFormData.append('list_id', [])
    productFormData.append('title', values.title)
    productFormData.append('price', values.price)
    productFormData.append('description', values.description)
    productFormData.append('user_id', localStorage.getItem('user_id'))

    const response = await ProductService.createProduct(productFormData)

    if (!response.error) {
      setLoading(false)
      resetForm()
      dispatch(getProductsByUserId())
    }
  }

  useEffect(() => {
    if (listingData !== undefined)
      if (reduxProducts.Data !== undefined) {

        if (reduxProducts.length === 0 || Object.keys(listingData).length === 0)
          setLoading(true)
        else {
          setLoading(false)

          reduxProducts.Data.map(product => {
            return { value: product.id, label: product.title }
          }).filter(product => {
            if (listingData !== undefined) {
              if (listingData.product_data !== undefined) {
                listingData.product_data.forEach(listingDataProduct => {
                  if (product.value === listingDataProduct.id)
                    filteredProducts.push(product)

                  setProduct(filteredProducts)
                })
              }
            }
          })
        }
        setLoading(false)
      }
  }, [listingData])

  return (
    <>
      <div className="row mt-3">
        {editFlag
          ? <div className="col">
            <label htmlFor="productSelect">
              <h6 className="text-muted">
                <small>Products</small>
              </h6>
            </label>
            <Select
              isMulti
              value={product}
              id='productSelect'
              isLoading={loading}
              isDisabled={loading}
              onChange={
                value => setProduct(value.map(singleValue => {
                  return singleValue
                }))
              }
              options={reduxProducts.Data.map(product => {
                return { value: product.id, label: product.title }
              })}
            />
          </div>
          : <div className="col">
            <label htmlFor="productSelect">
              <h6 className="text-muted">
                <small>Products</small>
              </h6>
            </label>
            {reduxProducts.Data !== undefined
              ? <Select
                isMulti
                isSearchable
                id='productSelect'
                isLoading={loading}
                isDisabled={loading}
                onChange={
                  value => setProduct(value.map(singleValue => {
                    return `${singleValue.value}`
                  }))
                }
                options={reduxProducts.Data.map(product => {
                  return { value: product.id, label: product.title }
                })}
              />
              : <></>
            }
          </div>
        }
        <div className="col">
          <h5 className="text-muted">Add a product if not already present</h5>
          <Formik
            initialValues={{ title: '', description: '', price: '' }}
            validationSchema={createProductSchema}
            onSubmit={(values, { resetForm }) => {
              handleCreateProduct(values, resetForm)
            }}
          >
            {({ errors, touched, isSubmitting, isValid, dirty }) => (
              <Form>
                <div className="col-lg-12">
                  <h6 className="text-muted">
                    <small>Product Title</small>
                  </h6>
                  <Field
                    id="title"
                    type="text"
                    name="title"
                    className={errors.title
                      ? "border border-danger form-control col-lg-12 px-2"
                      : "form-control col-lg-12 px-2"
                    }
                  />
                </div>
                {errors.title && touched.title
                  ? <small className="float-end text-danger">{errors.title}</small>
                  : null
                }
                <div className="col-lg-12  mt-3">
                  <h6 className="text-muted">
                    <small>Description</small>
                  </h6>
                  <Field
                    type="text"
                    id="description"
                    name="description"
                    className={errors.description
                      ? "border border-danger form-control col-lg-12 px-2"
                      : "form-control col-lg-12 px-2"
                    }
                  />
                </div>
                {errors.description && touched.description
                  ? <small className="float-end text-danger">{errors.description}</small>
                  : null
                }
                <div className="col-lg-12  mt-3">
                  <h6 className="text-muted">
                    <small>Price</small>
                  </h6>
                  <Field
                    id="price"
                    name="price"
                    type="text"
                    className={errors.price
                      ? "border border-danger form-control col-lg-12 px-2"
                      : "form-control col-lg-12 px-2"
                    }
                  />
                </div>
                {errors.price && touched.price
                  ? <small className="float-end text-danger">{errors.price}</small>
                  : null
                }
                <div className="row mt-4 float-start">
                  <div className="col-lg-12">
                    <button
                      type='submit'
                      className="btn btn-primary rounded-3"
                      disabled={isSubmitting || !isValid || !dirty}
                    >
                      {isSubmitting
                        ? <span
                          role="status"
                          aria-hidden="true"
                          className="spinner-border spinner-border-sm"
                        ></span>
                        : <></>
                      }{" "}
                      {isSubmitting
                        ? "Submitting"
                        : "Create Product"
                      }
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <button
            onClick={handleUpdateListing}
            className="btn btn-primary float-end rounded-3"
            disabled={product === '' || product === undefined || updating}
          >
            {updating
              ? <span
                role="status"
                aria-hidden="true"
                className="spinner-border spinner-border-sm"
              ></span>
              : <></>
            }{" "}
            {updating
              ? "Submitting"
              : "Add Product"
            }
          </button>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    reduxProducts: state.productReducer.products
  }
}

export default connect(mapStateToProps, null)(Product)
