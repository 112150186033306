import {
  GETUSER,
  GETUSERS,
  SETERROR,
} from "../actionTypes"
import UserService from "../../api-services/userService"
import { getAllRoles } from "./roles.action"

export const getUser = (params, history) => async dispatch => {

  try {
    const data = await UserService.getUser(params)
    dispatch({ type: GETUSER, payload: data })
    dispatch({ type: SETERROR, payload: '' })
  } catch (err) {
    if (err.response)
      dispatch({ type: SETERROR, payload: err.response.data.msg })
  }
}

export const getUsers = (pageNumber = 1, history) => async dispatch => {
  try {
    const data = await UserService.getUsers(pageNumber)
    dispatch({ type: GETUSERS, payload: data })
    dispatch({ type: SETERROR, payload: '' })
  } catch (err) {
    if (err.response)
      dispatch({ type: SETERROR, payload: err.response.data.msg })
  }
}

// export const addUser = (params, resetForm) => async dispatch => {
//   try {
//     const data = await UserService.createUser(params)
//     dispatch({ type: SETERROR, payload: '' })

//     // resetForm()
//     // toast.success('User Successfully Created.')

//     // dispatch(getAllRoles())
//     // dispatch(getUsers())
//     // return data
//   } catch (err) {
//     if (err.response)
//       dispatch({ type: SETERROR, payload: err.response.data.msg })
//   }
// }