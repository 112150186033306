import AuthService from "../../api-services/authService"
import { LOGIN, REGISTER, LOGOUT, SETERROR } from "../actionTypes"

export const login = (params, history) => async dispatch => {
  try {
    const data = await AuthService.login(params)
    dispatch({ type: LOGIN, payload: data })
    dispatch({ type: SETERROR, payload: '' })
    if (history.location.state !== undefined) history.push(history.location.state.from)
    else history.push('/dashboard')
  } catch (err) {
    if (err.response)
      dispatch({ type: SETERROR, payload: err.response.data.msg })
  }
}

export const register = (params, history, claimFlag, closeModal) => async dispatch => {
  try {
    const data = await AuthService.register(params)
    dispatch({ type: REGISTER, payload: data })
    dispatch({ type: SETERROR, payload: '' })
    if (claimFlag === undefined)
      history.push('/dashboard')
    else
      closeModal()
  } catch (err) {
    if (err.response)
      dispatch({ type: SETERROR, payload: err.response.data.msg })
  }
}

// export const logout = () => async dispatch => {
//   try {
//     const data = await AuthService.logout()
//     return data
//   } catch (err) {
//     if (err.response)
//       dispatch({ type: SETERROR, payload: err.response.data.msg })
//   }
// }