import API from "./api"

const SearchService = {
  searchByTitle: (params) => {
    return API.get(`/lists/search_title?page_no=${params.pageNumber}&title=${params.title}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  }
}

export default SearchService