import toast from 'react-hot-toast'
import { BsTrash, BsPencilSquare } from 'react-icons/bs'
import { withRouter } from 'react-router-dom'
import { AiOutlineEye } from 'react-icons/ai'
import { AiOutlineEdit } from 'react-icons/ai'
import { connect, useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { GETPRODUCT, SETERROR } from '../../redux/actionTypes'
import { AiOutlineUserDelete } from 'react-icons/ai'
import ProductCreationForm from './ProductCreationForm'
import DataTable from '../../components/dataTable/DataTable'
import CustomModal from '../../components/modal/CustomModal'
import ProductService from '../../api-services/productService'
import { getProducts, getProductsByUserId } from '../../redux/actions/product.action'
import Pagination from 'react-js-pagination'

const ProductMainPage = ({
  latestError,
  reduxProducts,
  reduxProductsPagination,
}) => {
  const dispatch = useDispatch()
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(true)
  const [deleting, setDeleting] = useState(false)
  const [activePage, setActivePage] = useState(1)
  const [operationFlag, setOperationFlag] = useState('')
  const [currentProduct, setCurrentProduct] = useState({})
  const [product, setProduct] = useState({ title: '', description: '', price: '' })

  const columns = [{
    dataField: 'title',
    text: 'Title',
    sort: true
  }, {
    dataField: 'price',
    text: 'Price',
    sort: true
  }, {
    dataField: 'actions',
    text: 'Actions'
  }]

  const handlePageChange = (pageNumber) => {
    if (localStorage.getItem('role') === "vendor") dispatch(getProductsByUserId(pageNumber))
    else dispatch(getProducts(pageNumber))

    setActivePage(pageNumber)
  }

  const handleCreateProduct = async (values, resetForm) => {

    let productFormData = new FormData()

    productFormData.append('list_id', [''])
    productFormData.append('title', values.title)
    productFormData.append('price', values.price)
    productFormData.append('description', values.description)
    productFormData.append('user_id', localStorage.getItem('user_id'))

    try {
      const response = await ProductService.createProduct(productFormData)

      if (response.error === false) {
        resetForm()

        toast.success('Product Added Successfully')
        setProduct({ title: '', description: '', price: '' })
        if (localStorage.getItem('role') === "vendor") dispatch(getProductsByUserId())
        else dispatch(getProducts())
      }
    } catch (error) {
      dispatch({ type: SETERROR, payload: error.response.data.msg })
      toast.error(error.response.data.msg)
    }
  }

  const handleDeletion = async (productId) => {
    setDeleting(true)

    try {
      const response = await ProductService.deleteProduct(productId)

      if (response.error === false) {
        if (localStorage.getItem('role') === "vendor") dispatch(getProductsByUserId())
        else dispatch(getProducts())

        setDeleting(false)
      }
    } catch (error) {
      setDeleting(false)

      if (error.response)
        toast.error(error.response.data.msg)
    }
  }

  const handleEdit = (e, row) => {
    dispatch({ type: GETPRODUCT, payload: row })
    setCurrentProduct(row)
  }

  const rowEvents = {
    onClick: (e, row, rowIndex) => handleEdit(e, row)
  }

  useEffect(() => {
    setLoading(true)

    if (localStorage.getItem('role') === "vendor") dispatch(getProductsByUserId())
    else dispatch(getProducts())
  }, [])

  useEffect(() => {
    if (reduxProducts.Data !== undefined) {
      setLoading(false)

      if (reduxProducts.Data.length !== 0) {
        reduxProducts.Data.forEach(product => {
          product.actions = [
            <div className="text-center">
              <AiOutlineEye
                size='1.5em'
                data-bs-toggle="modal"
                className='text-info mt-1'
                data-bs-target="#customModal"
                style={{ cursor: 'pointer' }}
                onClick={() => setOperationFlag('view')}
              />{" "}
              <BsTrash
                size='1.5em'
                className='text-danger'
                style={{ cursor: deleting ? 'default' : 'pointer' }}
                onClick={!deleting ? () => handleDeletion(product.id) : ''}
              />{" "}
              <BsPencilSquare
                className="text-primary"
                size='1.5em'
                data-bs-toggle="modal"
                data-bs-target="#customModal"
                style={{ cursor: 'pointer' }}
                onClick={() => setOperationFlag('edit')}
              />
            </div>
          ]
        })
        setProducts(reduxProducts.Data)
      } else {
        toast.success('No Products Found')
      }
    }
  }, [reduxProducts])

  useEffect(() => {
    if (products.length !== 0)
      toast.success('Products listed successfully')
  }, [products])

  // useEffect(() => {
  //   setLoading(false)
  // }, [product])

  useEffect(() => {

    if (latestError !== '') {
      setLoading(false)
      toast.error(latestError)
    }
  }, [latestError])

  return (
    <>
      <div className="row">
        <div className="col">
          <div
            className={loading
              ? "card p-3 d-flex justify-content-center align-items-center"
              : "card p-3"
            }
            style={{ maxHeight: '85vh', overflowY: 'auto' }}
          >
            {loading
              ? <div
                className="spinner-border text-primary"
                style={{
                  width: '3rem',
                  height: '3rem'
                }}
                role="status"
              ></div>
              : products.length === 0
                ? <h5 className="card-title text-center">No Products Found</h5>
                : <DataTable
                  columns={columns}
                  data={products}
                  rowEvents={rowEvents}
                  tableHeader={'Products'}
                />
            }
          </div>
          {reduxProducts.pagination_data === undefined
            ? <></>
            : <div className="float-end mt-2">
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activePage={activePage}
                onChange={handlePageChange}
                totalItemsCount={reduxProducts.pagination_data.total_items}
                itemsCountPerPage={reduxProducts.pagination_data.items_per_page}
                pageRangeDisplayed={6}
              />
            </div>
          }
        </div>
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Add New Product</h5>
              <ProductCreationForm
                editFlag={false}
                productData={product}
                handleCreateProduct={handleCreateProduct}
              />
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        dataFlag={'product'}
        modalData={currentProduct}
        operationFlag={operationFlag}
        modalTitle={currentProduct.title}
      />
    </>
  )
}

const mapStateToProps = state => {
  return {
    latestError: state.errorReducer.latestError,
    reduxProducts: state.productReducer.products,
    reduxProductsPagination: state.productReducer.productPagination,
  }
}

export default connect(mapStateToProps, null)(withRouter(ProductMainPage))
