import { css } from "@emotion/react"
import MetaTags from 'react-meta-tags'
import { Link } from 'react-router-dom'
import Pagination from "react-js-pagination"
import BarLoader from "react-spinners/BarLoader"
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import ListingsCard from "../../components/listing/ListingsCard"
import { searchListings } from '../../redux/actions/search.action'
import { getParentCategoriesNoAuth } from '../../redux/actions/category.action'

const override = css`
  display: block;
  margin: 0 auto;
  margin-top: 10%;
  border-color: red;
  border-radius: 10px;
`;

const LandingPage = ({
  latestError,
  reduxChildCategories,
  reduxParentCategories,
  reduxSearchedListings,
  reduxListingPagination,
}) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)
  const [cardCount, setCardCount] = useState(0)
  const [activePage, setActivePage] = useState(1)
  const [searching, setSearching] = useState(false)
  const [cardCountArr, setCardCountArr] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [searchString, setSearchString] = useState('')
  const [arrangedCategories, setArrangedCategories] = useState('')

  const handlePageChange = (pageNumber) => {
    setLoading(true)

    dispatch(searchListings(pageNumber, searchString))

    setActivePage(pageNumber)
  }

  const handleOnChange = e => {
    const { name, value } = e.target

    if (name === 'search') setSearchString(value)
  }

  const handleSearch = e => {
    setSearching(true)
    e.preventDefault()

    dispatch(searchListings(searchString))
  }

  useEffect(() => {
    dispatch(getParentCategoriesNoAuth())
  }, [])

  useEffect(() => {
    if (reduxParentCategories.parent_data !== undefined) {
      setLoading(false)
      setCardCount(Math.ceil(reduxParentCategories.parent_data.length / 7))
    }
  }, [reduxParentCategories])

  useEffect(() => {
    if (reduxSearchedListings.Data !== undefined) {
      setSearching(false)
      // if (reduxSearchedListings.Data.length !== 0) {
      //   const link = document.createElement('a')
      //   link.href = "#listingsSearchResults"
      //   document.body.appendChild(link)
      //   link.click()
      // }
    }
  }, [reduxSearchedListings])

  // useEffect(() => {
  //   if (arrangedCategories !== '')
  //     setLoading(false)
  // }, [arrangedCategories])

  useEffect(() => {
    if (cardCount !== 0) {
      let endIndex = 0
      let tempCardCountArr = []
      let tempCardCategoriesArr = []

      for (let i = 0; i < cardCount; i++) {
        tempCardCategoriesArr.push(reduxParentCategories.parent_data.slice(endIndex, (i + 1) * 7))
        tempCardCountArr.push(i + 1)
        endIndex = (i + 1) * 7
      }

      setArrangedCategories(tempCardCategoriesArr)
      // setCardCountArr(tempCardCountArr)
    }
  }, [cardCount])

  // useEffect(() => {
  //   if (reduxChildCategories !== undefined)
  //     setLoading(false)
  //   else {
  //     setLoading(false)
  //   }
  // }, [reduxChildCategories])

  useEffect(() => {
    if (latestError !== '')
      setErrorMessage(latestError)
    else
      setErrorMessage('')
  }, [latestError])

  return (
    <>
      <MetaTags>
        <title>Vendor Directory</title>
        <meta property="og:title" content="Vendor Directory" />
        <meta name="description" content="Home Page for Vendor Directory." />
      </MetaTags>
      <section
        style={{
          display: 'flex',
          minHeight: '75vh',
          position: 'relative',
          alignItems: 'center',
          background: '#f8edeb',
        }}
      >
        <div
          style={{
            top: 0,
            left: 0,
            zIndex: 1,
            width: '100%',
            height: '100%',
            margin: '0 auto',
            overflow: 'hidden',
            position: 'absolute',
          }}
          className="p-0"
        >
          <div
            style={{
              zIndex: '1',
              width: '100%',
              height: '100%',
              display: 'flex',
              overflow: 'hidden',
              position: 'relative',
              background: '#caf0f8',
              boxSizing: 'content-box',
              transitionDuration: '0ms',
            }}
          >
            <div
              style={{
                opacity: '1',
                width: '1349px',
                background: '#caf0f8',
                transitionDuration: '0ms',
                transform: 'translate3d(0px, 0px, 0px)',
              }}
            ></div>
          </div>
        </div>
        <div
          style={{
            zIndex: 20,
            // paddingTop: '1rem',
            // paddingBottom: '5rem'
          }}
          className="container text-white"
        >
          <div className="row">
            <div className="col-xl-12 px-0">
              <div className="text-center text-lg-start"
              >
                <h1
                  className="display-6 fw-bold text-primary"
                  style={{
                    textShadow: '2px 2px 2px rgb(0 0 0 / 10%)'
                  }}
                >
                  Find Listings
                </h1>
              </div>
              <div
                style={{
                  background: '#fff',
                  borderRadius: '10rem',
                  boxShadow: '0 0 1rem rgb(0 0 0 / 15%)'
                }}
                className="mt-5 p-3 p-lg-1 p-md-1 p-sm-1 ps-lg-4 ps-md-4 ps-sm-4 ps-4 py-1 pe-md-2 pe-sm-2"
              >
                <form onSubmit={handleSearch}>
                  <div className="row">
                    <div className="col-lg-10 col-md-10 col-sm-9 col-7 d-flex align-items-center form-group px-0">
                      <input
                        type="text"
                        name="search"
                        onChange={handleOnChange}
                        style={{ borderRadius: '10rem' }}
                        placeholder="What are you searching for?"
                        className="form-control border-0 shadow-0"
                      />
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-3 col-5 d-grid">
                      <button
                        type="submit"
                        style={{
                          lineHeight: '2',
                          fontSize: '.8rem',
                          fontWeight: 'bold',
                          letterSpacing: '.25em',
                          textTransform: 'uppercase'
                        }}
                        disabled={searchString === '' || searching}
                        className="btn btn-primary rounded-pill h-100"
                      >
                        {searching
                          ? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          : "Search"
                        }
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* <div className="row mb-2 mt-4">
            <div className="col">
              <h1
                className="display-6 fw-bold text-primary"
                style={{
                  textShadow: '2px 2px 2px rgb(0 0 0 / 10%);'
                }}
              >
                Listings
              </h1>
            </div>
          </div> */}
          <div className="row mt-5">
            {!loading
              ? reduxSearchedListings.Data === undefined
                ? <h2 className="text-center text-muted">Search for listings <br />or go to categories below</h2>
                : reduxSearchedListings.Data.length === 0
                  ? <h2 className="text-center text-muted">Search for listings to get started</h2>
                  : errorMessage !== ''
                    ? <h4>{errorMessage}</h4>
                    : <>
                      {
                        reduxSearchedListings.Data.map((reduxListing, i) => (
                          <div key={i} className="list-group shadow pe-0">
                            <ListingsCard reduxListing={reduxListing} />
                          </div>
                        ))
                      }
                    </>
              : <BarLoader
                css={override}
                height={5}
                width={1000}
                color={"#00b4d8"}
              />
            }
          </div>
          <div className="row mt-2">
            {reduxSearchedListings.pagination_data === undefined
              ? <></>
              : reduxSearchedListings.pagination_data.total_items === 0
                ? <></>
                : <div>
                  <div className="float-end">
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      pageRangeDisplayed={6}
                      activePage={activePage}
                      onChange={handlePageChange}
                      totalItemsCount={reduxSearchedListings.pagination_data.total_items}
                      itemsCountPerPage={reduxSearchedListings.pagination_data.items_per_page}
                    />
                  </div>
                </div>
            }
          </div>
        </div>
        {/* <div className="container">



        </div> */}
      </section>

      {/* <section
        id="listingsSearchResults"
        style={{
          paddingTop: '3rem',
          minHeight: '50vh',
          paddingBottom: '6rem',
          background: '#caf0f8'
        }}
      >
        <div className="container">
          <div className="row mb-2">
            <div className="col">
              <h1
                className="display-3 fw-bold text-primary"
                style={{
                  textShadow: '2px 2px 2px rgb(0 0 0 / 10%);'
                }}
              >
                Listings
              </h1>
            </div>
          </div>
          <div className="row mt-5">
            {!loading
              ? reduxSearchedListings.Data === undefined
                ? <h2 className="text-center text-muted">Search for listings to get started</h2>
                : reduxSearchedListings.Data.length === 0
                  ? <h2 className="text-center text-muted">Search for listings to get started</h2>
                  : errorMessage !== ''
                    ? <h4>{errorMessage}</h4>
                    : <>
                      {
                        reduxSearchedListings.Data.map((reduxListing, i) => (
                          <div key={i} className="list-group shadow pe-0">
                            <ListingsCard reduxListing={reduxListing} />
                          </div>
                        ))
                      }
                    </>
              : <BarLoader
                css={override}
                height={5}
                width={1000}
                color={"#00b4d8"}
              />
            }
          </div>
          <div className="row mt-2">
            {reduxSearchedListings.pagination_data === undefined
              ? <></>
              : reduxSearchedListings.pagination_data.total_items === 0
                ? <></>
                : <div>
                  <div className="float-end">
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      pageRangeDisplayed={6}
                      activePage={activePage}
                      onChange={handlePageChange}
                      totalItemsCount={reduxSearchedListings.pagination_data.total_items}
                      itemsCountPerPage={reduxSearchedListings.pagination_data.items_per_page}
                    />
                  </div>
                </div>
            }
          </div>
        </div>
      </section> */}
      <section
        id="categorySection"
        className="bg-gray-100"
        style={{
          minHeight: '50vh',
          paddingBottom: '6rem',
          background: '#caf0f8'
        }}
      >
        <div className="container">
          <div className="row mb-2">
            <div className="col">
              <h1
                className="display-6 fw-bold text-primary"
                style={{
                  textShadow: '2px 2px 2px rgb(0 0 0 / 10%)'
                }}
              >
                Categories
              </h1>
            </div>
          </div>
          <div className="row mt-1">
            {!loading
              ? reduxParentCategories.parent_data === undefined
                ? <h2 className="text-center text-muted">Categories not found</h2>
                : reduxParentCategories.parent_data.length === 0
                  ? <h2 className="text-center text-muted">Categories not found</h2>
                  : errorMessage !== ''
                    ? <h4>{errorMessage}</h4>
                    : <div className="row" id='categoryParentDiv'>
                      {arrangedCategories !== ''
                        ? arrangedCategories.map((singleIndex, i) => (
                          <div key={i} className="col-lg-2 h-20 my-2">
                            <div className="card h-100 w-100 border-1 shadow-sm">
                              <div className="card-body row">
                                {singleIndex.map((singleCategory, i) => (
                                  <div key={i} className="w-100">
                                    <h5 className="card-title">
                                      <Link
                                        className="text-decoration-none text-dark"
                                        to={{
                                          pathname: `categories/${singleCategory.name}`,
                                          state: { id: singleCategory.id }
                                        }}
                                      >
                                        {singleCategory.name}
                                        {singleCategory.list_count === 0
                                          ? <></>
                                          : <span className="float-end">{singleCategory.list_count}</span>
                                        }
                                      </Link>
                                    </h5>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        ))
                        : <></>
                      }
                    </div>
              : <BarLoader
                height={5}
                width={1000}
                css={override}
                color={"#00b4d8"}
              />
            }
          </div>
        </div>
      </section>
    </>
  )
}

const mapStateToProps = state => {
  return {
    latestError: state.errorReducer.latestError,
    reduxSearchedListings: state.searchReducer.searchedListings,
    reduxChildCategories: state.categoryReducer.childCategories,
    reduxParentCategories: state.categoryReducer.parentCategories,
    reduxListingPagination: state.listingReducer.listingPagination
  }
}

export default connect(mapStateToProps, null)(LandingPage)
