import {
  GETROLE,
  GETROLES,
  GETALLROLES,
} from "../actionTypes"

const initialState = {
  role: {},
  roles: {},
  allRoles: {},
}

const roleReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GETROLE:
      return {
        ...state,
        role: payload
      }

    case GETROLES:
      return {
        ...state,
        roles: payload
      }

    case GETALLROLES:
      return {
        ...state,
        allRoles: payload
      }

    default: return state
  }
}

export default roleReducer