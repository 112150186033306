import * as Yup from "yup"
import toast from "react-hot-toast"
import { BsEye } from "react-icons/bs"
import { Link } from "react-router-dom"
import { BsEyeSlash } from "react-icons/bs"
import { Field, Form, Formik } from "formik"
import { SETERROR } from "../../redux/actionTypes"
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Redirect, useHistory } from 'react-router'
import { login } from '../../redux/actions/auth.action'

const loginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().min(6, 'Too Short').required('Must have at least 2 characters')
})

const Login = ({
  latestError,
  claimFlag
}) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const handleLogin = (values, actions) => {
    setLoading(true)
    dispatch({ type: SETERROR, payload: '' })

    let loginFormData = new FormData()

    loginFormData.append('email', values.email)
    loginFormData.append('password', values.password)

    dispatch(login(loginFormData, history))
  }

  useEffect(() => {
    setLoading(false)

    if (latestError !== '')
      toast.error(latestError)
  }, [latestError])

  return (
    <>
      {localStorage.getItem('access_token')
        ? <Redirect to="/dashboard" />
        : <div className="col">
          <div
            className="shadow rounded"
            style={{
              height: "75vh",
              marginLeft: '10%',
              marginRight: '10%'
            }}
          >
            <div className="row h-100">
              <div className="col-lg-6 rounded bg-primary d-flex justify-content-center align-items-center">
                <h2 className="text-white">Directory</h2>
              </div>
              <div className="col-lg-6 d-flex justify-content-center align-items-center">
                <div>
                  <div className="row py-3">
                    <h2>Login</h2>
                  </div>
                  <div className="row pb-2">
                    <h5>Login to your account</h5>
                  </div>
                  <div className='row'>
                    <Formik
                      validationSchema={loginSchema}
                      initialValues={{ email: '', password: '' }}
                      onSubmit={(values, actions) => handleLogin(values, actions)}
                    >
                      {({ errors, touched, isSubmitting, isValid, dirty }) => (
                        <Form>
                          <div className="col-lg-12">
                            <h6 className="text-muted">
                              <small>EMAIL</small>
                            </h6>
                            <Field
                              id="email"
                              type="email"
                              name="email"
                              className={errors.email
                                ? "border border-danger form-control col-lg-12 px-2"
                                : "form-control col-lg-12 px-2"
                              }
                            />
                          </div>
                          {errors.email && touched.email
                            ? <small className="float-end text-danger">{errors.email}</small>
                            : null
                          }
                          <div className="col-lg-12 mt-4">
                            <h6 className="text-muted">
                              <small>PASSWORD</small>
                            </h6>
                            <div className="input-group">
                              <Field
                                id="password"
                                name="password"
                                type={showPassword ? "text" : "password"}
                                className={errors.password
                                  ? "border border-danger form-control col-lg-10 px-2"
                                  : "form-control col-lg-10 px-2"
                                }
                              />
                              <span className="mt-1 ps-3 col-lg-2">
                                {showPassword
                                  ? <BsEye
                                    size="20"
                                    onClick={() => setShowPassword(!showPassword)}
                                  />
                                  : <BsEyeSlash
                                    size="20"
                                    onClick={() => setShowPassword(!showPassword)}
                                  />
                                }
                              </span>
                            </div>
                          </div>
                          {errors.password && touched.password
                            ? <small className="float-end text-danger">{errors.password}</small>
                            : null
                          }
                          <div className="row mt-4 float-start">
                            <div className="col-lg-12">
                              <button
                                type='submit'
                                className="btn btn-primary rounded-3"
                                disabled={!isValid || !dirty || loading}
                              >
                                {loading
                                  ? <span
                                    role="status"
                                    aria-hidden="true"
                                    className="spinner-border spinner-border-sm"
                                  ></span>
                                  : <></>
                                }{" "}
                                {loading
                                  ? "Signing In"
                                  : "Sign In"
                                }
                              </button>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                  <div className="row mt-2">
                    <p>Don't have an account yet? <Link to={{ pathname: '/signup' }}>Join Directory</Link></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

const mapStateToProps = state => {
  return {
    errors: state.errorReducer.errors,
    latestError: state.errorReducer.latestError
  }
}

export default connect(mapStateToProps, null)(Login)
