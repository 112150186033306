import * as Yup from "yup"
import { connect } from 'react-redux'
import { Field, Form, Formik } from "formik"
import React, { useEffect, useState } from 'react'

const createProductSchema = Yup.object().shape({
  title: Yup.string().min(3, 'Too Short').required('Required'),
  description: Yup.string().min(3, 'Too Short').required('Required'),
  price: Yup.number().required('Required')
})

const ProductCreationForm = ({
  editFlag,
  viewFlag,
  productData,
  handleEditProduct,
  handleCreateProduct,
}) => {

  return (
    <>
      {!editFlag && !viewFlag ?
        <Formik
          initialValues={{ title: '', description: '', price: '' }}
          validationSchema={createProductSchema}
          onSubmit={(values, { resetForm }) => {
            handleCreateProduct(values, resetForm)
          }}
        >
          {({ errors, touched, isSubmitting, isValid, dirty }) => (
            <Form>
              <div className="col-lg-12">
                <h6 className="text-muted"><small>Product Title</small></h6>
                <Field
                  id="title"
                  type="text"
                  name="title"
                  className={errors.title ? "border border-danger form-control col-lg-12 px-2" : "form-control col-lg-12 px-2"}
                />
              </div>
              {errors.title && touched.title ? (
                <small className="float-end text-danger">{errors.title}</small>
              ) : null}
              <div className="col-lg-12  mt-3">
                <h6 className="text-muted"><small>Description</small></h6>
                <Field
                  id="description"
                  type="text"
                  name="description"
                  className={errors.description ? "border border-danger form-control col-lg-12 px-2" : "form-control col-lg-12 px-2"}
                />
              </div>
              {errors.description && touched.description ? (
                <small className="float-end text-danger">{errors.description}</small>
              ) : null}
              <div className="col-lg-12  mt-3">
                <h6 className="text-muted"><small>Price</small></h6>
                <Field
                  id="price"
                  type="text"
                  name="price"
                  className={errors.price ? "border border-danger form-control col-lg-12 px-2" : "form-control col-lg-12 px-2"}
                />
              </div>
              {errors.price && touched.price ? (
                <small className="float-end text-danger">{errors.price}</small>
              ) : null}
              <div className="row mt-4 float-start">
                <div className="col-lg-12">
                  <button
                    type='submit'
                    className="btn btn-primary rounded-3"
                    disabled={isSubmitting || !isValid || !dirty}
                  >
                    {isSubmitting
                      ? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      : <></>
                    }{" "}
                    {isSubmitting
                      ? "Submitting"
                      : "Create Product"
                    }
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        : editFlag && !viewFlag ?
          <Formik
            enableReinitialize={true}
            initialValues={{
              title: productData.title,
              description: productData.description,
              price: productData.price
            }}
            validationSchema={createProductSchema}
            onSubmit={(values, { resetForm }) => {
              handleEditProduct(values, resetForm)
            }}
          >
            {({ errors, touched, isSubmitting, isValid, dirty }) => (
              <Form>
                <div className="col-lg-12">
                  <h6 className="text-muted"><small>Product Title</small></h6>
                  <Field
                    id="title"
                    type="text"
                    name="title"
                    className={errors.title ? "border border-danger form-control col-lg-12 px-2" : "form-control col-lg-12 px-2"}
                  />
                </div>
                {errors.title && touched.title ? (
                  <small className="float-end text-danger">{errors.title}</small>
                ) : null}
                <div className="col-lg-12  mt-3">
                  <h6 className="text-muted"><small>Description</small></h6>
                  <Field
                    id="description"
                    type="text"
                    name="description"
                    className={errors.description ? "border border-danger form-control col-lg-12 px-2" : "form-control col-lg-12 px-2"}
                  />
                </div>
                {errors.description && touched.description ? (
                  <small className="float-end text-danger">{errors.description}</small>
                ) : null}
                <div className="col-lg-12  mt-3">
                  <h6 className="text-muted"><small>Price</small></h6>
                  <Field
                    id="price"
                    type="text"
                    name="price"
                    className={errors.price ? "border border-danger form-control col-lg-12 px-2" : "form-control col-lg-12 px-2"}
                  />
                </div>
                {errors.price && touched.price ? (
                  <small className="float-end text-danger">{errors.price}</small>
                ) : null}
                <div className="row mt-4 float-start">
                  <div className="col-lg-12">
                    <button
                      type='submit'
                      className="btn btn-primary rounded-3"
                      disabled={isSubmitting || !isValid || !dirty}
                    >
                      {isSubmitting
                        ? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        : <></>}{" "}
                      {isSubmitting
                        ? "Submitting"
                        : "Update Product"
                      }
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          : !editFlag && viewFlag && productData !== undefined
            ? <form>
              <div className='row'>
                <div className="mb-3">
                  <label htmlFor="titleField" className="form-label">Title</label>
                  <input type="text" className="form-control" id="titleField" name="title" disabled value={productData.title} />
                </div>
              </div>
              <div className='row'>
                <div className="mb-3">
                  <label htmlFor="descriptionField" className="form-label">Description</label>
                  <input type="text" className="form-control" id="descriptionField" name="description" disabled value={productData.description} />
                </div>
              </div>
              <div className='row'>
                <div className="mb-3">
                  <label htmlFor="priceField" className="form-label">Price</label>
                  <input type="text" className="form-control" id="priceField" name="price" disabled value={productData.price} />
                </div>
              </div>
            </form> :
            <></>
      }
    </>
  )
}

const mapStateToProps = state => {
  return {
    reduxProducts: state.productReducer.products
  }
}

export default connect(mapStateToProps, null)(ProductCreationForm)
