import React from 'react'
import * as Yup from "yup"
import { connect } from 'react-redux'
import { withRouter } from "react-router"
import { Field, Form, Formik } from "formik"

const createCategorySchema = Yup.object().shape({
  parent_id: Yup.string(),
  category_name: Yup.string().min(2, 'Too Short').required('Required'),
})

const CategoryCreationPage = ({
  editFlag,
  viewFlag,
  categoryData,
  handleEditCategory,
  handleCreateCategory,
  reduxParentCategories
}) => {
  return (
    <>
      {!editFlag && !viewFlag ?
        <Formik
          initialValues={{ category_name: '', parent_id: '' }}
          validationSchema={createCategorySchema}
          onSubmit={(values, { resetForm }) => {
            handleCreateCategory(values, resetForm)
          }}
        >
          {({ errors, touched, isSubmitting, isValid, dirty }) => (
            <Form>
              <div className="col-lg-12">
                <h6 className="text-muted">
                  <small>Category Name</small>
                </h6>
                <Field
                  type="text"
                  id="category_name"
                  name="category_name"
                  className={errors.category_name
                    ? "border border-danger form-control col-lg-12 px-2"
                    : "form-control col-lg-12 px-2"
                  }
                />
              </div>
              {errors.category_name && touched.category_name
                ? <small className="float-end text-danger">{errors.category_name}</small>
                : null
              }
              <div className="col-lg-12 mt-3">
                <h6 className="text-muted">
                  <small>Parent</small>
                </h6>
                <Field
                  as="select"
                  name="parent_id"
                  className={errors.parent_id
                    ? "border border-danger form-select col-lg-12 px-2"
                    : "form-select col-lg-12 px-2"
                  }
                >
                  <option value=''>Select Category</option>
                  {reduxParentCategories.parent_data !== undefined
                    ? reduxParentCategories.parent_data.map(reduxParentCategory => (
                      <option
                        value={reduxParentCategory.id}
                      >
                        {reduxParentCategory.name}
                      </option>
                    ))
                    : <></>
                  }
                </Field>
              </div>
              {errors.description && touched.description
                ? <small className="float-end text-danger">{errors.description}</small>
                : null
              }
              <div className="row mt-4 float-start">
                <div className="col-lg-12">
                  <button
                    type='submit'
                    className="btn btn-primary rounded-3"
                    disabled={isSubmitting || !isValid || !dirty}
                  >
                    {isSubmitting
                      ? <span
                        role="status"
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm"
                      ></span>
                      : <></>
                    }{" "}
                    {isSubmitting
                      ? "Submitting"
                      : "Create Category"
                    }
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        : editFlag && !viewFlag ?
          <Formik
            enableReinitialize={true}
            validationSchema={createCategorySchema}
            initialValues={{
              category_name: categoryData.name,
              /* parent_id: categoryData.parent_id*/
            }}
            onSubmit={(values, { resetForm }) => {
              handleEditCategory(values, resetForm)
            }}
          >
            {({ errors, touched, isSubmitting, isValid, dirty }) => (
              <Form>
                <div className="col-lg-12">
                  <h6 className="text-muted">
                    <small>Category Name</small>
                  </h6>
                  <Field
                    id="category_name"
                    type="text"
                    name="category_name"
                    className={errors.category_name
                      ? "border border-danger form-control col-lg-12 px-2"
                      : "form-control col-lg-12 px-2"
                    }
                  />
                </div>
                {errors.category_name && touched.category_name
                  ? <small className="float-end text-danger">{errors.category_name}</small>
                  : null
                }
                {/* <div className="col-lg-12">
                  <h6 className="text-muted">
                    <small>Parent</small>
                  </h6>
                  <Field
                    as="select"
                    name="parent_id"
                    className={errors.parent_id
                      ? "border border-danger form-control col-lg-12 px-2"
                      : "form-control col-lg-12 px-2"
                    }
                  >
                    <option value=''>Select Category</option>
                    {reduxParentCategories.map(reduxParentCategory => (
                      <option
                        value={reduxParentCategory.id}
                        selected={reduxParentCategory.id === categoryData.parent_id}
                      >
                        {reduxParentCategory.name}
                      </option>
                    ))}
                  </Field>
                </div>
                {errors.parent_id && touched.parent_id
                  ? <small className="float-end text-danger">{errors.parent_id}</small>
                  : null
                } */}
                <div className="row mt-4 float-start">
                  <div className="col-lg-12">
                    <button
                      type='submit'
                      className="btn btn-primary rounded-3"
                      disabled={isSubmitting || !isValid || !dirty}
                    >
                      {isSubmitting
                        ? <span
                          role="status"
                          aria-hidden="true"
                          className="spinner-border spinner-border-sm"
                        ></span>
                        : <></>
                      }{" "}
                      {isSubmitting
                        ? "Submitting"
                        : "Edit Category"
                      }
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          : !editFlag && viewFlag ?
            <form>
              <div className='row'>
                <div className="mb-3">
                  <label
                    className="form-label"
                    htmlFor="categoryField"
                  >
                    Category Name
                  </label>
                  <input
                    disabled
                    name="name"
                    type="text"
                    id="categoryField"
                    className="form-control"
                    value={categoryData.name}
                  />
                </div>
              </div>
              {categoryData.parent_id === null
                ? <></>
                : <div className='row'>
                  <div className="mb-3">
                    <label
                      className="form-label"
                      htmlFor="parentCategoryField"
                    >
                      Parent Category
                    </label>
                    <select
                      disabled
                      type="text"
                      name="parent_id"
                      className="form-control"
                      id="parentCategoryField"
                    >
                      <option defaultValue>No Parent</option>
                      {reduxParentCategories !== undefined
                        ? reduxParentCategories.map((reduxParentCategory, i) =>
                          <option
                            key={i}
                            selected={reduxParentCategory.id === categoryData.parent_id._id.$oid}
                            value={reduxParentCategory.id}
                          >
                            {reduxParentCategory.name}
                          </option>
                        ) : <></>
                      }
                    </select>
                  </div>
                </div>
              }
            </form> :
            <></>
      }
    </>
  )
}

const mapStateToProps = state => {
  return {
    reduxParentCategories: state.categoryReducer.parentCategories
  }
}

export default connect(mapStateToProps, null)(withRouter(CategoryCreationPage))

