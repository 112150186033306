import API from './api'

const locationService = {
  createLocation: (data) => {
    return API.post('/location/add', data)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  updateLocation: (data) => {
    return API.patch('/location/update', data)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  deleteLocation: (data) => {
    return API.delete(`/location/delete?id=${data}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  getLocations: (data) => {
    return API.get(`/location/?page_no=${data}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  getParentLocations: () => {
    return API.get('/location/parent_list')
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  getChildLocations: () => {
    return API.get('/location/parent_exist_list')
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  getLocation: (data) => {
    return API.get(`/location/read_id?parent_id=${data}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  getAllLocations: () => {
    return API.get(`/location/dropdown`)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
}

export default locationService