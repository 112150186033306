import React from 'react'
import AdminDashboard from '../../components/dashboard/admin/AdminDashboard'
import VendorDashboard from '../../components/dashboard/vendor/VendorDashboard'

const Dashboard = () => {
  return (
    <div className="container">
      {localStorage.getItem('role') === 'admin'
        ? <AdminDashboard />
        : localStorage.getItem('role') === 'vendor'
          ? <VendorDashboard />
          : <></>
      }
    </div>
  )
}

export default Dashboard