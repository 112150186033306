import React from 'react'
import { Link } from 'react-router-dom'

const ListingsCard = ({ reduxListing }) => {
  return (
    <Link
      to={{
        pathname: reduxListing.cat_data !== undefined
          ? `/listings/${reduxListing.cat_data.parent.name}/${reduxListing.cat_data.name}/${reduxListing.id}`
          : `/listings/${reduxListing.title}/${reduxListing.id}`,
        state: {
          listingData: reduxListing
        }
      }}
      className="list-group-item list-group-item-action p-4"
    >
      <div className="row">
        <div className="col-lg-4 align-self-center mb-4 mb-lg-0">
          <div className="d-flex align-items-center mb-3">
            <h2 className="h5 mb-0" style={{ fontWeight: '700' }}>{reduxListing.title}</h2>
          </div>
          <p className="text-sm text-muted">{reduxListing.description}</p>
          <div className="row">
            {reduxListing.service_data !== undefined ?
              reduxListing.service_data.length === 0
                ? <></>
                : reduxListing.service_data.map((service, i) => (
                  <h5
                    key={i}
                    className="col px-1"
                    style={{ minWidth: 'max-content', maxWidth: 'min-content' }}
                  >
                    <span
                      className="badge rounded-pill"
                      style={{ color: '#4E66F8', background: '#e4e8fe' }}
                    >
                      {service.title}
                    </span>
                  </h5>
                ))
              : <></>
            }
          </div>
        </div>
        <div className="col-lg-8">
          <div className="row">
            <div className="col-6 col-md-4 col-lg-8 py-3 mb-3 mb-lg-0">
              <h6
                style={{
                  fontSize: '.8rem',
                  fontWeight: 'normal',
                  letterSpacing: '0.1em',
                  textTransform: 'uppercase',
                }}
                className="label-heading text-muted"
              >
                Category
              </h6>
              <p className="text-sm fw-bold">
                {reduxListing.cat_data !== undefined
                  ? reduxListing.cat_data.name
                  : ""
                }
              </p>
            </div>
            <div className="col-12 col-lg-4 align-self-center float-end">
              <span
                style={{
                  fontSize: '.875rem',
                  letterSpacing: '0.1em',
                }}
                className={`text-sm float-end text-uppercase'text-secondary`}
              >
                <i className={reduxListing.is_claimed
                  ? ""
                  : "fas fa-times fa-fw me-2"
                }
                ></i>{reduxListing.is_claimed ? "" : "Claimed"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default ListingsCard
