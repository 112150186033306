import RoleService from "../../api-services/roleService"
import {
  GETROLE,
  GETROLES,
  SETERROR,
  GETALLROLES,
} from "../actionTypes"

export const getRole = (params, history) => async dispatch => {

  try {
    const data = await RoleService.getRole(params)
    dispatch({ type: GETROLE, payload: data })
    dispatch({ type: SETERROR, payload: '' })
  } catch (err) {
    if (err.response)
      dispatch({ type: SETERROR, payload: err.response.data.msg })
  }
}

export const getRoles = (pageNumber = 1) => async dispatch => {
  try {
    const data = await RoleService.getRoles(pageNumber)
    dispatch({ type: GETROLES, payload: data })
    dispatch({ type: SETERROR, payload: '' })
  } catch (err) {
    if (err.response)
      dispatch({ type: SETERROR, payload: err.response.data.msg })
  }
}

export const getAllRoles = () => async dispatch => {
  try {
    const data = await RoleService.getRolesDropdown()
    dispatch({ type: GETALLROLES, payload: data })
    dispatch({ type: SETERROR, payload: '' })
  } catch (err) {
    if (err.response)
      dispatch({ type: SETERROR, payload: err.response.data.msg })
  }
}