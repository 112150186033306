import * as Yup from "yup"
import React, { useEffect } from 'react'
import { withRouter } from "react-router"
import { connect, useDispatch } from 'react-redux'
import Product from "../../components/tabs/product/Product"
import Category from "../../components/tabs/category/Category"
import { getServices, getServicesByUserId } from "../../redux/actions/service.action"
import { getProducts, getProductsByUserId } from "../../redux/actions/product.action"
import { getLocation } from "../../redux/actions/location.action"
import BasicInfo from "../../components/tabs/basic-info/BasicInfo"
import LocationAndService from "../../components/tabs/location&services/LocationAndService"

const createListingSchema = Yup.object().shape({
  parent_id: Yup.string(),
  location_name: Yup.string().min(2, 'Too Short').required('Required'),
})

const ListingCreationForm = ({
  product,
  editFlag,
  viewFlag,
  updating,
  category,
  setProduct,
  currentTab,
  setUpdating,
  setCategory,
  listingData,
  setCurrentTab,
  creatingListing,
  locationAndService,
  handleUpdateListing,
  handleCreateListing,
  setLocationAndService,
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getProductsByUserId())
    dispatch(getServicesByUserId())
  }, [])

  return (
    <>
      {!editFlag && !viewFlag ?
        <>
          <ul className="nav nav-tabs row nav-fill">
            <li className="nav-item col-lg-3">
              <button
                role="tab"
                type="button"
                id="basic-info-tab"
                data-bs-toggle="tab"
                aria-selected="true"
                aria-controls="basic-info"
                data-bs-target="#basic-info"
                onClick={() => setCurrentTab('basic-info')}
                className={`nav-link ${currentTab === 'basic-info' ? 'active' : ''}`}
              >
                <h5>Basic Info</h5>
              </button>
            </li>
            <li className="nav-item col-lg-3">
              <button
                role="tab"
                type="button"
                id="products-tab"
                aria-selected="true"
                data-bs-toggle="tab"
                aria-controls="products"
                data-bs-target="#products"
                onClick={() => setCurrentTab('products')}
                className={`nav-link ${currentTab === 'products' ? 'active' : ''}`}
              >
                <h5>Products</h5>
              </button>
            </li>
            <li className="nav-item col-lg-3">
              <button
                role="tab"
                type="button"
                id="category-tab"
                data-bs-toggle="tab"
                aria-selected="true"
                aria-controls="category"
                data-bs-target="#category"
                onClick={() => setCurrentTab('category')}
                className={`nav-link ${currentTab === 'category' ? 'active' : ''}`}
              >
                <h5>Category</h5>
              </button>
            </li>
            <li className="nav-item col-lg-3">
              <button
                role="tab"
                type="button"
                data-bs-toggle="tab"
                aria-selected="true"
                id="location-services-tab"
                aria-controls="location-services"
                data-bs-target="#location-services"
                onClick={() => setCurrentTab('location-services')}
                className={`nav-link ${currentTab === 'location-services' ? 'active' : ''}`}
              >
                <h5>Location &amp; Services</h5>
              </button>
            </li>
          </ul>
          <div
            className="tab-content"
            id="myTabContent"
          >
            <div
              id="basic-info"
              role="tabpanel"
              aria-labelledby="basic-info-tab"
              className={`tab-pane fade ${currentTab === 'basic-info' ? 'show active' : ''}`}
            >
              <BasicInfo
                editFlag={editFlag}
                creatingListing={creatingListing}
                handleCreateListing={handleCreateListing}
              />
            </div>
            <div
              id="products"
              role="tabpanel"
              aria-labelledby="products-tab"
              className={`tab-pane fade ${currentTab === 'products' ? 'show active' : ''}`}
            >
              <Product
                product={product}
                updating={updating}
                setProduct={setProduct}
                handleUpdateListing={handleUpdateListing}
              />
            </div>
            <div
              id="category"
              role="tabpanel"
              aria-labelledby="category-tab"
              className={`tab-pane fade ${currentTab === 'category' ? 'show active' : ''}`}
            >
              <Category
                updating={updating}
                category={category}
                setCategory={setCategory}
                handleUpdateListing={handleUpdateListing}
              />
            </div>
            <div
              role="tabpanel"
              id="location-services"
              aria-labelledby="location-services-tab"
              className={`tab-pane fade ${currentTab === 'location-services' ? 'show active' : ''}`}
            >
              <LocationAndService
                updating={updating}
                locationAndService={locationAndService}
                handleUpdateListing={handleUpdateListing}
                setLocationAndService={setLocationAndService}
              />
            </div>
          </div>
        </>
        : editFlag && !viewFlag ?
          <>
            <ul className="nav nav-tabs row nav-fill">
              <li className="nav-item col-lg-3">
                <button
                  role="tab"
                  type="button"
                  id="basic-info-tab"
                  data-bs-toggle="tab"
                  aria-selected="true"
                  aria-controls="basic-info"
                  data-bs-target="#basic-info"
                  onClick={() => setCurrentTab('basic-info')}
                  className={`nav-link ${currentTab === 'basic-info' ? 'active' : ''}`}
                >
                  <h5>Basic Info</h5>
                </button>
              </li>
              <li className="nav-item col-lg-3">
                <button
                  role="tab"
                  type="button"
                  id="products-tab"
                  data-bs-toggle="tab"
                  aria-selected="true"
                  aria-controls="products"
                  data-bs-target="#products"
                  onClick={() => setCurrentTab('products')}
                  className={`nav-link ${currentTab === 'products' ? 'active' : ''}`}
                >
                  <h5>Products</h5>
                </button>
              </li>
              <li className="nav-item col-lg-3">
                <button
                  role="tab"
                  type="button"
                  id="category-tab"
                  data-bs-toggle="tab"
                  aria-selected="true"
                  aria-controls="category"
                  data-bs-target="#category"
                  onClick={() => setCurrentTab('category')}
                  className={`nav-link ${currentTab === 'category' ? 'active' : ''}`}
                >
                  <h5>Category</h5>
                </button>
              </li>
              <li className="nav-item col-lg-3">
                <button
                  role="tab"
                  type="button"
                  data-bs-toggle="tab"
                  aria-selected="true"
                  id="location-services-tab"
                  aria-controls="location-services"
                  data-bs-target="#location-services"
                  onClick={() => setCurrentTab('location-services')}
                  className={`nav-link ${currentTab === 'location-services' ? 'active' : ''}`}
                >
                  <h5>Location &amp; Services</h5>
                </button>
              </li>
            </ul>
            <div
              id="myTabContent"
              className="tab-content"
            >
              <div
                id="basic-info"
                role="tabpanel"
                aria-labelledby="basic-info-tab"
                className={`tab-pane fade ${currentTab === 'basic-info' ? 'show active' : ''}`}
              >
                <BasicInfo
                  editFlag={editFlag}
                  updating={updating}
                  listingData={listingData}
                  handleUpdateListing={handleUpdateListing}
                />
              </div>
              <div
                id="products"
                role="tabpanel"
                aria-labelledby="products-tab"
                className={`tab-pane fade ${currentTab === 'products' ? 'show active' : ''}`}
              >
                <Product
                  product={product}
                  editFlag={editFlag}
                  updating={updating}
                  setProduct={setProduct}
                  listingData={listingData}
                  handleUpdateListing={handleUpdateListing}
                />
              </div>
              <div
                id="category"
                role="tabpanel"
                aria-labelledby="category-tab"
                className={`tab-pane fade ${currentTab === 'category' ? 'show active' : ''}`}
              >
                <Category
                  updating={updating}
                  category={category}
                  editFlag={editFlag}
                  listingData={listingData}
                  setCategory={setCategory}
                  handleUpdateListing={handleUpdateListing}
                />
              </div>
              <div
                role="tabpanel"
                id="location-services"
                aria-labelledby="location-services-tab"
                className={`tab-pane fade ${currentTab === 'location-services' ? 'show active' : ''}`}
              >
                <LocationAndService
                  updating={updating}
                  editFlag={editFlag}
                  setUpdating={setUpdating}
                  listingData={listingData}
                  locationAndService={locationAndService}
                  handleUpdateListing={handleUpdateListing}
                  setLocationAndService={setLocationAndService}
                />
              </div>
            </div>
          </>
          : !editFlag
            && viewFlag
            && listingData !== undefined
            || Object.keys(listingData).length === 0
            ? <form>
              <div className='row'>
                <div className="mb-3">
                  <label htmlFor="titleField" className="form-label">Title</label>
                  <input
                    disabled
                    type="text"
                    name="title"
                    id="titleField"
                    className="form-control"
                    value={listingData.title}
                  />
                </div>
              </div>

              <div className='row'>
                <div className="mb-3">
                  <label htmlFor="descriptionField" className="form-label">Description</label>
                  <input
                    disabled
                    type="text"
                    name="description"
                    id="descriptionField"
                    className="form-control"
                    value={listingData.description}
                  />
                </div>
              </div>

              <div className='row'>
                <div className="mb-3">
                  <label htmlFor="addressField" className="form-label">Address</label>
                  <input
                    disabled
                    type="text"
                    name="address"
                    id="addressField"
                    className="form-control"
                    value={listingData.address}
                  />
                </div>
              </div>

              {listingData.contact_info === undefined || listingData.contact_info.length === 0
                ? <></>
                : <div className='row'>
                  <div className="mb-3">
                    <label htmlFor="addressField" className="form-label">Contact Info</label>
                    <div className="row">
                      {listingData.contact_info.map(contact_info =>
                        <input
                          disabled
                          type="text"
                          name="contact_info"
                          value={contact_info}
                          id="contact_infoField"
                          className="form-control mx-2 col"
                        />
                      )}
                    </div>
                  </div>
                </div>
              }
              {listingData.social_profile === undefined || listingData.social_profile.length === 0
                ? <></>
                : <div className='row'>
                  <div className="mb-3">
                    <label htmlFor="addressField" className="form-label">Social Links</label>
                    <div className="row">
                      {listingData.social_profile.map(social_profile =>
                        <a
                          disabled
                          type="text"
                          target="_blank"
                          name="social_profile"
                          href={social_profile}
                          id="social_profileField"
                          className="form-control mx-2 col"
                        >
                          {social_profile}
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              }
              {listingData.videos === undefined || listingData.videos.length === 0
                ? <></>
                : <div className='row'>
                  <div className="mb-3">
                    <label htmlFor="addressField" className="form-label">Videos</label>
                    <div className="row">
                      {listingData.videos.map(videos =>
                        <a
                          disabled
                          type="text"
                          name="videos"
                          href={videos}
                          target="_blank"
                          id="videosField"
                          className="form-control mx-2 col"
                        >
                          {videos}
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              }
              {listingData.website === undefined || listingData.website.length === 0
                ? <></>
                : <div className='row'>
                  <div className="mb-3">
                    <label htmlFor="addressField" className="form-label">Websites</label>
                    <div className="row">
                      {listingData.website.map(website =>
                        <a
                          disabled
                          type="text"
                          name="website"
                          href={website}
                          target="_blank"
                          id="websiteField"
                          className="form-control mx-2 col"
                        >
                          {website}
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              }
              {listingData.product_data === undefined || listingData.product_data.length === 0
                ? <></>
                : <div className='row'>
                  <div className="mb-3">
                    <label htmlFor="productsField" className="form-label">Product(s)</label>
                    <div className="row">
                      {listingData.product_data !== undefined
                        ? listingData.product_data.map(product =>
                          <input
                            disabled
                            type="text"
                            name="product"
                            id="productField"
                            value={product.title}
                            className="form-control mx-2 col"
                          />
                        )
                        : <></>
                      }
                    </div>
                  </div>
                </div>
              }
              {listingData.cat_data === undefined
                || Object.keys(listingData.cat_data).length === 0
                ? <></>
                : <div className='row'>
                  <div className="mb-3">
                    <label htmlFor="categoryField" className="form-label">Category</label>
                    <div className="row">
                      {listingData.cat_data !== undefined
                        ? <input
                          disabled
                          type="text"
                          name="category"
                          id="categoryField"
                          value={listingData.cat_data.name}
                          className="form-control mx-2 col"
                        />
                        : <></>
                      }
                    </div>
                  </div>
                </div>
              }
              {listingData.loc_data === undefined || listingData.loc_data.length === 0
                ? <></>
                : <div className='row'>
                  <div className="mb-3">
                    <label htmlFor="locationField" className="form-label">Location</label>
                    <div className="row">
                      {listingData.loc_data !== undefined
                        ? listingData.loc_data.map(location =>
                          <input
                            disabled
                            type="text"
                            name="location"
                            id="locationField"
                            value={location.name}
                            className="form-control mx-2 col"
                          />
                        )
                        : <></>
                      }
                    </div>
                  </div>
                </div>
              }
              {listingData.service_data === undefined || listingData.service_data.length === 0
                ? <></>
                : <div className='row'>
                  <div className="mb-3">
                    <label htmlFor="serviceField" className="form-label">Service(s)</label>
                    <div className="row">
                      {listingData.service_data !== undefined
                        ? listingData.service_data.map(service =>
                          <input
                            disabled
                            type="text"
                            name="service"
                            id="serviceField"
                            value={service.title}
                            className="form-control mx-2 col"
                          />
                        )
                        : <></>
                      }
                    </div>
                  </div>
                </div>
              }
            </form>
            : <></>
      }
    </>
  )
}

const mapStateToProps = state => {
  return {
    reduxCities: state.locationReducer.cities,
    reduxCategories: state.categoryReducer.categories,
    reduxChildLocations: state.locationReducer.location,
    reduxProducts: state.productReducer.productsByUser_Id,
    reduxParentLocations: state.locationReducer.parentLocations,
  }
}

export default connect(mapStateToProps, null)(withRouter(ListingCreationForm))

