import {
  SETSEARCHLISTINGS
} from "../actionTypes"

const initialState = {
  searchedListings: [],
}

const searchReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SETSEARCHLISTINGS:
      return {
        ...state,
        searchedListings: payload
      }

    default: return state
  }
}

export default searchReducer