import Login from "../pages/auth/Login"
import Signup from "../pages/auth/Signup"
import VerifyEmail from "../pages/auth/VerifyEmail"
import RoleMainPage from "../pages/role/RoleMainPage"
import UserMainPage from "../pages/user/UserMainPage"
import ListListing from "../pages/listing/ListListing"
import ViewListing from "../pages/listing/ViewListing"
import Dashboard from "../pages/dashboard/Dashboard"
import ClaimMainPage from "../pages/claim/ClaimMainPage"
import ListCategory from "../pages/category/ListCategory"
import LandingPage from "../pages/landingPage/LandingPage"
import ListingMainPage from "../pages/listing/ListingMainPage"
import ProductMainPage from "../pages/product/ProductMainPage"
import ServiceMainPage from "../pages/service/ServiceMainPage"
import LocationMainPage from "../pages/location/LocationMainPage"
import CategoryMainPage from "../pages/category/CategoryMainPage"

const publicRoutes = [
  { path: '/', exact: true, component: LandingPage },
  { path: "/login", component: Login },
  { path: "/signup", component: Signup },
  { path: '/listings/all', component: ListListing },
  { path: '/listings/:category/:subCategory', component: ListListing },
  { path: '/listings/:category/:subCategory/:id', component: ViewListing },
  { path: '/categories/:category', component: ListCategory },
  { path: '/verifyEmail/:id', component: VerifyEmail },
]

const protectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/users", component: UserMainPage },
  { path: "/roles", component: RoleMainPage },
  { path: "/locations", component: LocationMainPage },
  { path: "/categories", component: CategoryMainPage },
  { path: "/listings", component: ListingMainPage },
  { path: "/claims", component: ClaimMainPage },
  { path: "/products", component: ProductMainPage },
  { path: "/services", component: ServiceMainPage },
]

export { protectedRoutes, publicRoutes }