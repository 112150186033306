import {
  GETLISTING,
  GETLISTINGS,
} from "../actionTypes"

const initialState = {
  listing: {},
  listings: {},
  listingPagination: {}
}

const listingReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GETLISTING:
      return {
        ...state,
        listing: payload
      }

    case GETLISTINGS:
      return {
        ...state,
        listings: payload
      }

    default: return state
  }
}

export default listingReducer