import API from './api'

const listingService = {
  createListing: (data) => {
    return API.post('/lists/add', data)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  updateListing: (data) => {
    return API.patch('/lists/update', data)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  deleteListing: (data) => {
    return API.delete(`/lists/delete?list_id=${data}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  getListings: (data) => {
    return API.get(`/lists/?page_no=${data}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  getListingsNoAuth: (data) => {
    return API.get(`/lists/auth?page_no=${data}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  getListing: (data) => {
    return API.get(`/lists/detail?list_id=${data}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  getListingWithCategory: (pageNo, categoryId) => {
    return API.get(`/lists/category_list?page_no=${pageNo}&category_id=${categoryId}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  getListingsByUserId: (user_id, pageNumber) => {
    return API.get(`/lists/user_lists?user_id=${user_id}&page_no=${pageNumber}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  getListingNoAuth: (data) => {
    return API.get(`/lists/detail_auth?list_id=${data}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  }
}

export default listingService