import { BsTrash, BsPencilSquare } from 'react-icons/bs'
import { withRouter } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { GETLOCATION } from '../../redux/actionTypes'
import LocationCreationForm from './LocationCreationForm'
import { AiOutlineEdit, AiOutlineEye } from 'react-icons/ai'
import DataTable from '../../components/dataTable/DataTable'
import CustomModal from '../../components/modal/CustomModal'
import locationService from '../../api-services/locationService'
import { getLocations, getParentLocations } from '../../redux/actions/location.action'
import toast from 'react-hot-toast'
import Pagination from 'react-js-pagination'

const LocationMainPage = ({
  latestError,
  reduxLocations,
  reduxLocationsPagination,
}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [locations, setLocations] = useState([])
  const [deleting, setDeleting] = useState(false)
  const [operationFlag, setOperationFlag] = useState('')
  const [parentLocations, setParentLocations] = useState([])
  const [currentLocation, setCurrentLocation] = useState({})
  const [location, setLocation] = useState({ location_name: '', parent_id: '' })
  const [activePage, setActivePage] = useState(1)

  const columns = [{
    dataField: 'name',
    text: 'Name',
    sort: true
  }, {
    dataField: 'actions',
    text: 'Actions'
  }]

  const handlePageChange = (pageNumber) => {
    dispatch(getLocations(pageNumber))

    setActivePage(pageNumber)
  }

  const handleOnChange = e => {
    const { name, value } = e.target

    setLocation(prevLocation => ({ ...prevLocation, [name]: value }))
  }

  const handleCreateLocation = async (values, resetForm) => {

    let locationFormData = new FormData()

    locationFormData.append('name', values.location_name)
    locationFormData.append('parent_id', values.city_id !== '' ? values.city_id : values.state_id !== '' ? values.state_id : values.parent_id)

    try {
      const response = await locationService.createLocation(locationFormData)

      if (response.error === false) {
        resetForm()

        toast.success('Location Created successfully')
        setLocation({ location_name: '', parent_id: '' })
        dispatch(getLocations())
        dispatch(getParentLocations())
      }
    } catch (error) {
      if (error.response)
        toast.error(error.response.data.msg)
    }
  }

  const handleDeletion = async (locationId) => {
    setDeleting(true)

    try {
      const response = await locationService.deleteLocation(locationId)

      if (response.error === false) {
        dispatch(getLocations())
        setDeleting(false)
      }
    } catch (error) {
      setDeleting(false)

      if (error.response)
        toast.error(error.response.data.msg)
    }
  }

  const handleEditLocation = (e, row) => {
    dispatch({ type: GETLOCATION, payload: row })
    setCurrentLocation(row)
  }

  const rowEvents = {
    onClick: (e, row, rowIndex) => handleEditLocation(e, row)
  }

  useEffect(() => {
    setLoading(true)

    dispatch(getLocations())
    dispatch(getParentLocations())
  }, [])

  useEffect(() => {
    if (reduxLocations.Data !== undefined) {
      if (reduxLocations.Data.length !== 0) {
        reduxLocations.Data.forEach(location => {
          location.actions = [
            <div className="text-center">
              <AiOutlineEye
                size='1.5em'
                data-bs-toggle="modal"
                data-bs-target="#customModal"
                style={{ cursor: 'pointer' }}
                className='text-info mt-1'
                onClick={() => setOperationFlag('view')}
              />{" "}
              <BsTrash
                size='1.5em'
                className='text-danger'
                style={{ cursor: deleting ? 'default' : 'pointer' }}
                onClick={!deleting ? () => handleDeletion(location.id) : ''}
              />{" "}
              <BsPencilSquare
                size='1.5em'
                data-bs-toggle="modal"
                className="text-primary"
                data-bs-target="#customModal"
                style={{ cursor: 'pointer' }}
                onClick={() => setOperationFlag('edit')}
              />
            </div>
          ]
        })
        setLoading(false)
        setLocations(reduxLocations.Data)
      }
    }
  }, [reduxLocations])

  useEffect(() => {
    if (locations !== undefined && locations.length !== 0)
      toast.success('Locations listed successfully')
  }, [locations])

  // useEffect(() => {
  //   setLoading(false)
  // }, [location])

  useEffect(() => {

    if (latestError !== '') {
      setLoading(false)
      toast.error(latestError)
    }
  }, [latestError])

  return (
    <>
      <div className="row">
        <div className="col">
          <div
            className={loading
              ? "card p-3 d-flex justify-content-center align-items-center"
              : "card p-3"
            }
            style={{ maxHeight: '85vh', overflowY: 'auto' }}
          >
            {loading
              ? <div
                className="spinner-border text-primary"
                style={{
                  width: '3rem',
                  height: '3rem'
                }}
                role="status"
              ></div>
              : locations === undefined || locations.length === 0
                ? <h5 className="card-title text-center">No Locations Available</h5>
                : <DataTable
                  data={locations}
                  columns={columns}
                  rowEvents={rowEvents}
                  tableHeader={'Locations'}
                />
            }
          </div>
          {reduxLocations.pagination_data === undefined
            ? <></>
            : <div className="float-end mt-2">
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activePage={activePage}
                onChange={handlePageChange}
                totalItemsCount={reduxLocations.pagination_data.total_items}
                itemsCountPerPage={reduxLocations.pagination_data.items_per_page}
                pageRangeDisplayed={6}
              />
            </div>
          }
        </div>
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Add New Location</h5>
              <LocationCreationForm
                editFlag={false}
                locationData={location}
                handleOnChange={handleOnChange}
                parentLocations={parentLocations}
                handleEditLocation={handleEditLocation}
                handleCreateLocation={handleCreateLocation}
              />
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        dataFlag={'location'}
        modalData={currentLocation}
        operationFlag={operationFlag}
        modalTitle={currentLocation.name}
      />
    </>
  )
}

const mapStateToProps = state => {
  return {
    latestError: state.errorReducer.latestError,
    reduxLocations: state.locationReducer.locations,
    reduxLocationsPagination: state.locationReducer.locationPagination,
  }
}

export default connect(mapStateToProps, null)(withRouter(LocationMainPage))
