import API from './api'

const UserService = {
  createUser: (data) => {
    return API.post('/user/add', data)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  updateUser: (data) => {
    return API.patch('/user/update', data)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  suspendUser: (data) => {
    return API.patch('/user/suspend', data)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  getUsers: (params) => {
    return API.get(`/user/?page_no=${params}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
  getUser: (data) => {
    return API.get(`/user/detail?user_id=${data}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => { throw err })
  },
}

export default UserService