import toast from 'react-hot-toast'
import Pagination from 'react-js-pagination'
import { withRouter } from 'react-router-dom'
import { AiOutlineEye } from 'react-icons/ai'
import { connect, useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { GETCATEGORY } from '../../redux/actionTypes'
import { BsTrash, BsPencilSquare } from 'react-icons/bs'
import CategoryCreationForm from './CategoryCreationForm'
import DataTable from '../../components/dataTable/DataTable'
import CustomModal from '../../components/modal/CustomModal'
import categoryService from '../../api-services/categoryService'
import { getCategories, getParentCategories } from '../../redux/actions/category.action'

const CategoryMainPage = ({
  latestError,
  reduxCategories,
  reduxCategoriesPagination,
}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [activePage, setActivePage] = useState(1)
  const [deleting, setDeleting] = useState(false)
  const [categories, setCategories] = useState([])
  const [operationFlag, setOperationFlag] = useState('')
  const [currentCategory, setCurrentCategory] = useState({})
  const [parentCategories, setParentCategories] = useState([])
  const [category, setCategory] = useState({ category_name: '', parent_id: '' })

  const columns = [{
    dataField: 'name',
    text: 'Name',
    sort: true
  }, {
    dataField: 'actions',
    text: 'Actions'
  }]

  const handlePageChange = (pageNumber) => {
    dispatch(getCategories(pageNumber))

    setActivePage(pageNumber)
  }

  const handleOnChange = e => {
    const { name, value } = e.target

    setCategory(prevCategory => ({ ...prevCategory, [name]: value }))
  }

  const handleCreateCategory = async (values, resetForm) => {

    let categoryFormData = new FormData()

    categoryFormData.append('name', values.category_name)
    categoryFormData.append('parent_id', values.parent_id)

    const response = await categoryService.createCategory(categoryFormData)

    if (response.error === false) {
      resetForm()

      toast.success('Category Created Successfully')
      setCategory({ category_name: '', parent_id: '' })
      dispatch(getCategories())
    }
  }

  const handleDeletion = async (categoryId) => {
    setDeleting(true)

    try {
      const response = await categoryService.deleteCategory(categoryId)

      if (response.error === false) {
        dispatch(getCategories())
        setDeleting(false)
      }
    } catch (error) {
      setDeleting(false)

      if (error.response)
        toast.error(error.response.data.msg)
    }
  }

  const handleEditCategory = (e, row) => {
    dispatch({ type: GETCATEGORY, payload: row })
    setCurrentCategory(row)
  }

  const rowEvents = {
    onClick: (e, row, rowIndex) => handleEditCategory(e, row)
  }

  useEffect(() => {
    setLoading(true)

    dispatch(getCategories())
    dispatch(getParentCategories())
  }, [])

  useEffect(() => {
    if (reduxCategories.Data !== undefined) {
      if (reduxCategories.Data.length !== 0) {
        reduxCategories.Data.forEach(category => {
          category.actions = [
            <div className="text-center">
              <AiOutlineEye
                size='1.5em'
                data-bs-toggle="modal"
                data-bs-target="#customModal"
                style={{ cursor: 'pointer' }}
                className='text-info mt-1'
                onClick={() => setOperationFlag('view')}
              />{" "}
              <BsTrash
                size='1.5em'
                className='text-danger'
                style={{ cursor: deleting ? 'default' : 'pointer' }}
                onClick={!deleting ? () => handleDeletion(category.id) : ''}
              />{" "}
              <BsPencilSquare
                size='1.5em'
                data-bs-toggle="modal"
                className="text-primary"
                data-bs-target="#customModal"
                style={{ cursor: 'pointer' }}
                onClick={() => setOperationFlag('edit')}
              />
            </div>
          ]
        })
        setLoading(false)
        setCategories(reduxCategories.Data)
      }
    }
  }, [reduxCategories])

  useEffect(() => {
    if (categories !== undefined && categories.length !== 0)
      toast.success('Categories listed successfully')
  }, [categories])

  useEffect(() => {

    if (latestError !== '') {
      setLoading(false)
      toast.error(latestError)
    }
  }, [latestError])

  return (
    <>
      <div className="row">
        <div className="col">
          <div
            className={
              loading
                ? "card p-3 d-flex justify-content-center align-items-center"
                : "card p-3"
            }
            style={{
              maxHeight: '600px',
              overflowY: 'auto'
            }}
          >
            {loading
              ? <div
                className="spinner-border text-primary"
                style={{
                  width: '3rem',
                  height: '3rem'
                }}
                role="status"
              ></div>
              : categories === undefined || categories.length === 0
                ? <h5 className="card-title text-center">No Categories Found</h5>
                : <DataTable
                  data={categories}
                  columns={columns}
                  rowEvents={rowEvents}
                  tableHeader={'Categories'}
                />
            }
          </div>
          {reduxCategories.pagination_data === undefined
            ? <></>
            : <div className="float-end mt-2">
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                pageRangeDisplayed={6}
                activePage={activePage}
                onChange={handlePageChange}
                totalItemsCount={reduxCategories.pagination_data.total_items}
                itemsCountPerPage={reduxCategories.pagination_data.items_per_page}
              />
            </div>
          }
        </div>
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Add New Category</h5>
              <CategoryCreationForm
                editFlag={false}
                categoryData={category}
                handleOnChange={handleOnChange}
                parentCategories={parentCategories}
                handleCreateCategory={handleCreateCategory}
              />
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        dataFlag={'category'}
        modalData={currentCategory}
        operationFlag={operationFlag}
        modalTitle={currentCategory.name}
      />
    </>
  )
}

const mapStateToProps = state => {
  return {
    latestError: state.errorReducer.latestError,
    reduxCategories: state.categoryReducer.categories,
    reduxCategoriesPagination: state.categoryReducer.categoryPagination,
  }
}

export default connect(mapStateToProps, null)(withRouter(CategoryMainPage))
