import claimService from "../../api-services/claimService"
import { GETCLAIMS, SETERROR } from "../actionTypes"

export const getClaims = (pageNumber = 1) => async dispatch => {
  try {
    const data = await claimService.getClaims(pageNumber)
    dispatch({ type: GETCLAIMS, payload: data })

    dispatch({ type: SETERROR, payload: '' })
  } catch (err) {
    if (err.response)
      dispatch({ type: SETERROR, payload: err.response.data.msg })
  }
}