import * as Yup from "yup"
import { connect } from 'react-redux'
import { Field, Form, Formik } from "formik"
import React, { useEffect, useState } from 'react'

const createRoleSchema = Yup.object().shape({
  role_name: Yup.string().min(3, 'Too Short').required('Required')
})

const RoleCreationForm = ({
  role,
  editFlag,
  handleEditRole,
  handleOnChange,
  handleCreateRole
}) => {

  return (
    <>
      {!editFlag ?
        <Formik
          initialValues={{ role_name: '' }}
          validationSchema={createRoleSchema}
          onSubmit={(values, { resetForm }) => {
            handleCreateRole(values, resetForm)
          }}
        >
          {({ errors, touched, isSubmitting, isValid, dirty }) => (
            <Form>
              <div className="col-lg-12">
                <h6 className="text-muted"><small>Role Name</small></h6>
                <Field
                  id="role_name"
                  type="text"
                  name="role_name"
                  className={errors.role_name ? "border border-danger form-control col-lg-12 px-2" : "form-control col-lg-12 px-2"}
                />
              </div>
              {errors.role_name && touched.role_name ? (
                <small className="float-end text-danger">{errors.role_name}</small>
              ) : null}
              <div className="row mt-4 float-start">
                <div className="col-lg-12">
                  <button
                    type='submit'
                    className="btn btn-primary rounded-3"
                    disabled={isSubmitting || !isValid || !dirty}
                  >
                    {isSubmitting
                      ? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      : <></>}{" "}
                    {isSubmitting
                      ? "Submitting"
                      : "Create Role"
                    }
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik> :
        <Formik
          enableReinitialize={true}
          initialValues={{ role_name: role.role_name }}
          validationSchema={createRoleSchema}
          onSubmit={(values, { resetForm }) => {
            handleEditRole(values, resetForm)
          }}
        >
          {({ errors, touched, isSubmitting, isValid, dirty }) => (
            <Form>
              <div className="col-lg-12">
                <h6 className="text-muted"><small>Role Name</small></h6>
                <Field
                  id="role_name"
                  type="text"
                  name="role_name"
                  className={errors.role_name ? "border border-danger form-control col-lg-12 px-2" : "form-control col-lg-12 px-2"}
                />
              </div>
              {errors.role_name && touched.role_name ? (
                <small className="float-end text-danger">{errors.role_name}</small>
              ) : null}
              <div className="row mt-4 float-start">
                <div className="col-lg-12">
                  <button
                    type='submit'
                    className="btn btn-primary rounded-3"
                    disabled={isSubmitting || !isValid || !dirty}
                  >
                    {isSubmitting
                      ? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      : <></>}{" "}
                    {isSubmitting
                      ? "Submitting"
                      : "Edit Role"
                    }
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      }
    </>
  )
}

const mapStateToProps = state => {
  return {
    reduxRoles: state.roleReducer.roles
  }
}

export default connect(mapStateToProps, null)(RoleCreationForm)
