import React from 'react'
import * as Yup from "yup"
import { MdAdd } from 'react-icons/md'
import { Field, FieldArray, Form, Formik } from 'formik'

const createListingSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
  videos: Yup.array().required('Required'),
  website: Yup.array().required('Required'),
  address: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  contact_info: Yup.array().required('Required'),
  social_profile: Yup.array().required('Required')
})

const BasicInfo = ({
  editFlag,
  updating,
  listingData,
  creatingListing,
  handleCreateListing,
  handleUpdateListing
}) => {
  return (
    <>
      {editFlag
        ? <Formik
          initialValues={{
            title: listingData.title,
            videos: listingData.videos,
            address: listingData.address,
            website: listingData.website,
            description: listingData.description,
            contact_info: listingData.contact_info,
            social_profile: listingData.social_profile,
          }}
          enableReinitialize={true}
          validationSchema={createListingSchema}
          onSubmit={(values) => handleUpdateListing(values)}
        >
          {({
            dirty,
            values,
            errors,
            touched,
            isValid,
            isSubmitting,
          }) => (
            <Form>
              <div className="col-lg-12 mt-3">
                <h6 className="text-muted"><small>Title</small></h6>
                <Field
                  id="title"
                  type="text"
                  name="title"
                  className={errors.title
                    ? "border border-danger form-control col-lg-12 px-2"
                    : "form-control col-lg-12 px-2"
                  }
                />
              </div>
              {errors.title && touched.title
                ? <small className="float-end text-danger">{errors.title}</small>
                : null
              }
              <div className="col-lg-12 mt-3">
                <h6 className="text-muted"><small>Description</small></h6>
                <Field
                  type="textarea"
                  id="description"
                  name="description"
                  className={errors.description
                    ? "border border-danger form-control col-lg-12 px-2"
                    : "form-control col-lg-12 px-2"
                  }
                />
              </div>
              {errors.description && touched.description
                ? <small className="float-end text-danger">{errors.description}</small>
                : null
              }

              <div className="col-lg-12 mt-3">
                <h6 className="text-muted"><small>Address</small></h6>
                <Field
                  id="address"
                  type="text"
                  name="address"
                  className={errors.address
                    ? "border border-danger form-control col-lg-12 px-2"
                    : "form-control col-lg-12 px-2"
                  }
                />
              </div>
              {errors.address && touched.address
                ? <small className="float-end text-danger">{errors.address}</small>
                : null
              }

              <div className="row">
                <div className="col-lg-6 mt-3">
                  <h6 className="text-muted">
                    <small>Contact Info</small>
                  </h6>
                  <FieldArray name="contact_info">
                    {({ insert, remove, push }) => (
                      <>
                        <div className="row">
                          <div className="col-lg-10">
                            {values.contact_info.length > 0 &&
                              values.contact_info.map((contact_info, index) => (
                                <div className="row mt-1" key={index}>
                                  <div className="col-lg-11">
                                    <Field
                                      type="number"
                                      name={`contact_info[${index}]`}
                                      className={errors.contact_info
                                        ? "border border-danger form-control col-lg-12"
                                        : "form-control col-lg-12"
                                      }
                                    />
                                  </div>

                                  <button
                                    type="button"
                                    aria-label="Close"
                                    onClick={() => remove(index)}
                                    className="btn btn-close mt-2"
                                  ></button>
                                  {errors.contact_info && touched.contact_info
                                    ? <small className="float-end text-danger">{errors.contact_info}</small>
                                    : null
                                  }
                                </div>
                              ))}
                          </div>
                          <div className="col-lg-1 px-0 mt-1">
                            <button
                              type="button"
                              onClick={() => push('')}
                              className="btn btn-primary rounded-circle px-2 py-1"
                            >
                              <MdAdd size="1.3em" className="mb-1" />
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </FieldArray>
                </div>

                <div className="col-lg-6 mt-3">
                  <h6 className="text-muted">
                    <small>Website URL</small>
                  </h6>
                  <FieldArray name="website">
                    {({ insert, remove, push }) => (
                      <>
                        <div className="row">
                          <div className="col-lg-10">

                            {values.website.length > 0 &&
                              values.website.map((website, index) => (
                                <div className="row mt-1" key={index}>
                                  <div className="col-lg-11">
                                    <Field
                                      type="text"
                                      name={`website[${index}]`}
                                      className='form-control col-lg-12'
                                    />
                                  </div>
                                  <button
                                    type="button"
                                    aria-label="Close"
                                    onClick={() => remove(index)}
                                    className="btn btn-close mt-2"
                                  ></button>
                                  {errors.website && touched.website
                                    ? <small className="float-end text-danger">{errors.website}</small>
                                    : null
                                  }
                                </div>
                              ))}
                          </div>
                          <div className="col-lg-1 px-0 mt-1">
                            <button
                              type="button"
                              onClick={() => push('')}
                              className="btn btn-primary rounded-circle px-2 py-1"
                            >
                              <MdAdd size="1.3em" className="mb-1" />
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </FieldArray>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 mt-3">
                  <h6 className="text-muted">
                    <small>Links to Social Profiles</small>
                  </h6>
                  <FieldArray name="social_profile">
                    {({ insert, remove, push }) => (
                      <>
                        <div className="row">
                          <div className="col-lg-10">
                            {values.social_profile.length > 0 &&
                              values.social_profile.map((social_profile, index) => (
                                <div className="row mt-1" key={index}>
                                  <div className="col-lg-11">
                                    <Field
                                      type="text"
                                      name={`social_profile[${index}]`}
                                      className='form-control col-lg-12'
                                    />
                                  </div>
                                  <button
                                    type="button"
                                    aria-label="Close"
                                    onClick={() => remove(index)}
                                    className="btn btn-close mt-2"
                                  ></button>
                                  {errors.social_profile && touched.social_profile
                                    ? <small className="float-end text-danger">{errors.social_profile}</small>
                                    : null
                                  }
                                </div>
                              ))}
                          </div>
                          <div className="col-lg-1 px-0 mt-1">
                            <button
                              type="button"
                              onClick={() => push('')}
                              className="btn btn-primary rounded-circle px-2 py-1"
                            >
                              <MdAdd size="1.3em" className="mb-1" />
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </FieldArray>
                </div>

                <div className="col-lg-6 mt-3">
                  <h6 className="text-muted"><small>Links to Videos</small></h6>
                  <FieldArray name="videos">
                    {({ insert, remove, push }) => (
                      <>
                        <div className="row">
                          <div className="col-lg-10">
                            {values.videos.length > 0 &&
                              values.videos.map((video, index) => (
                                <div className="row mt-1" key={index}>
                                  <div className="col-lg-11">
                                    <Field
                                      type="text"
                                      name={`videos[${index}]`}
                                      className='form-control col-lg-12'
                                    />
                                  </div>
                                  <button
                                    type="button"
                                    aria-label="Close"
                                    onClick={() => remove(index)}
                                    className="btn btn-close mt-2"
                                  ></button>
                                  {errors.videos && touched.videos
                                    ? <small className="float-end text-danger">{errors.videos}</small>
                                    : null
                                  }
                                </div>
                              ))}
                          </div>
                          <div className="col-lg-1 px-0 mt-1">
                            <button
                              type="button"
                              onClick={() => push('')}
                              className="btn btn-primary rounded-circle px-2 py-1"
                            >
                              <MdAdd size="1.3em" className="mb-1" />
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </FieldArray>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col">
                  <button
                    type="submit"
                    id="basicInfoSubmitBtn"
                    className="btn btn-primary float-end rounded-3"
                    disabled={isSubmitting || !isValid || !dirty}
                  >
                    {updating
                      ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      : <></>}{" "}
                    {updating
                      ? "Updating"
                      : "Update"
                    }
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        : <Formik
          initialValues={{
            title: '',
            address: '',
            videos: [''],
            website: [''],
            description: '',
            contact_info: [''],
            social_profile: [''],
          }}
          validationSchema={createListingSchema}
          onSubmit={(values) => handleCreateListing(values)}
        >
          {({ values, errors, touched, isSubmitting, isValid, dirty }) => (
            <Form>
              <div className="col-lg-12 mt-3">
                <h6 className="text-muted"><small>Title</small></h6>
                <Field
                  id="title"
                  type="text"
                  name="title"
                  className={errors.title
                    ? "border border-danger form-control col-lg-12 px-2"
                    : "form-control col-lg-12 px-2"
                  }
                />
              </div>
              {errors.title && touched.title
                ? <small className="float-end text-danger">{errors.title}</small>
                : null
              }
              <div className="col-lg-12 mt-3">
                <h6 className="text-muted"><small>Description</small></h6>
                <Field
                  type="textarea"
                  id="description"
                  name="description"
                  className={errors.description
                    ? "border border-danger form-control col-lg-12 px-2"
                    : "form-control col-lg-12 px-2"
                  }
                />
              </div>
              {errors.description && touched.description
                ? <small className="float-end text-danger">{errors.description}</small>
                : null
              }

              <div className="col-lg-12 mt-3">
                <h6 className="text-muted"><small>Address</small></h6>
                <Field
                  id="address"
                  type="text"
                  name="address"
                  className={errors.address
                    ? "border border-danger form-control col-lg-12 px-2"
                    : "form-control col-lg-12 px-2"
                  }
                />
              </div>
              {errors.address && touched.address
                ? <small className="float-end text-danger">{errors.address}</small>
                : null
              }

              <div className="row">
                <div className="col-lg-6 mt-3">
                  <h6 className="text-muted"><small>Contact Info</small></h6>
                  <FieldArray name="contact_info">
                    {({ insert, remove, push }) => (
                      <>
                        <div className="row">
                          <div className="col-lg-10">
                            {values.contact_info.length > 0 &&
                              values.contact_info.map((contact_info, index) => (
                                <div className="row mt-1" key={index}>
                                  <div className="col-lg-11">
                                    <Field
                                      type="text"
                                      className={errors.contact_info
                                        ? "border border-danger form-control col-lg-12"
                                        : "form-control col-lg-12"
                                      }
                                      name={`contact_info.${index}.name`}
                                    />
                                  </div>

                                  <button
                                    type="button"
                                    aria-label="Close"
                                    onClick={() => remove(index)}
                                    className="btn btn-close mt-2"
                                  ></button>
                                  {errors.contact_info && touched.contact_info
                                    ? <small className="float-end text-danger">{errors.contact_info}</small>
                                    : null
                                  }
                                </div>
                              ))}
                          </div>
                          <div className="col-lg-1 px-0 mt-1">
                            <button
                              type="button"
                              onClick={() => push('')}
                              className="btn btn-primary rounded-circle px-2 py-1"
                            >
                              <MdAdd size="1.3em" className="mb-1" />
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </FieldArray>
                </div>

                <div className="col-lg-6 mt-3">
                  <h6 className="text-muted"><small>Website URL</small></h6>
                  <FieldArray name="website">
                    {({ insert, remove, push }) => (
                      <>
                        <div className="row">
                          <div className="col-lg-10">

                            {values.website.length > 0 &&
                              values.website.map((website, index) => (
                                <div className="row mt-1" key={index}>
                                  <div className="col-lg-11">
                                    <Field
                                      type="text"
                                      // pattern="https://.*"
                                      className='form-control col-lg-12'
                                      name={`website.${index}.name`}
                                    />
                                  </div>
                                  <button
                                    type="button"
                                    aria-label="Close"
                                    onClick={() => remove(index)}
                                    className="btn btn-close mt-2"
                                  ></button>
                                  {errors.website && touched.website
                                    ? <small className="float-end text-danger">{errors.website}</small>
                                    : null
                                  }
                                </div>
                              ))}
                          </div>
                          <div className="col-lg-1 px-0 mt-1">
                            <button
                              type="button"
                              onClick={() => push('')}
                              className="btn btn-primary rounded-circle px-2 py-1"
                            >
                              <MdAdd size="1.3em" className="mb-1" />
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </FieldArray>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 mt-3">
                  <h6 className="text-muted"><small>Links to Social Profiles</small></h6>
                  <FieldArray name="social_profile">
                    {({ insert, remove, push }) => (
                      <>
                        <div className="row">
                          <div className="col-lg-10">
                            {values.social_profile.length > 0 &&
                              values.social_profile.map((social_profile, index) => (
                                <div className="row mt-1" key={index}>
                                  <div className="col-lg-11">
                                    <Field
                                      type="text"
                                      className='form-control col-lg-12'
                                      name={`social_profile.${index}.name`}
                                    />
                                  </div>
                                  <button
                                    type="button"
                                    aria-label="Close"
                                    onClick={() => remove(index)}
                                    className="btn btn-close mt-2"
                                  ></button>
                                  {errors.social_profile && touched.social_profile
                                    ? <small className="float-end text-danger">{errors.social_profile}</small>
                                    : null
                                  }
                                </div>
                              ))}
                          </div>
                          <div className="col-lg-1 px-0 mt-1">
                            <button
                              type="button"
                              onClick={() => push('')}
                              className="btn btn-primary rounded-circle px-2 py-1"
                            >
                              <MdAdd size="1.3em" className="mb-1" />
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </FieldArray>
                </div>

                <div className="col-lg-6 mt-3">
                  <h6 className="text-muted"><small>Links to Videos</small></h6>
                  <FieldArray name="videos">
                    {({ insert, remove, push }) => (
                      <>
                        <div className="row">
                          <div className="col-lg-10">
                            {values.videos.length > 0 &&
                              values.videos.map((video, index) => (
                                <div className="row mt-1" key={index}>
                                  <div className="col-lg-11">
                                    <Field
                                      type="text"
                                      // pattern="https://.*"
                                      className='form-control col-lg-12'
                                      name={`videos.${index}.name`}
                                    />
                                  </div>
                                  <button
                                    type="button"
                                    aria-label="Close"
                                    onClick={() => remove(index)}
                                    className="btn btn-close mt-2"
                                  ></button>
                                  {errors.videos && touched.videos
                                    ? <small className="float-end text-danger">{errors.videos}</small>
                                    : null
                                  }
                                </div>
                              ))}
                          </div>
                          <div className="col-lg-1 px-0 mt-1">
                            <button
                              type="button"
                              onClick={() => push('')}
                              className="btn btn-primary rounded-circle px-2 py-1"
                            >
                              <MdAdd size="1.3em" className="mb-1" />
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </FieldArray>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col">
                  <button
                    type="submit"
                    id="basicInfoSubmitBtn"
                    className="btn btn-primary float-end rounded-3"
                    disabled={isSubmitting || !isValid || !dirty}
                  >
                    {creatingListing
                      ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      : <></>
                    }{" "}
                    {creatingListing
                      ? "Adding"
                      : "Add Listing"
                    }
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      }
    </>
  )
}

export default BasicInfo
