import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { css } from "@emotion/react"
import MetaTags from 'react-meta-tags'
import { Link } from 'react-router-dom'
import BarLoader from "react-spinners/BarLoader"
import { useRouteMatch } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { GETCHILDCATEGORIES } from '../../redux/actionTypes'
import { getChildrenCategoriesNoAuth } from '../../redux/actions/category.action'

const override = css`
  display: block;
  margin: 0 auto;
  margin-top: 10%;
  border-color: red;
  border-radius: 10px;
`;

const ListCategory = ({
  location,
  latestError,
  reduxChildCategories,
}) => {

  const dispatch = useDispatch()
  const { url } = useRouteMatch()

  const [id, setId] = useState('')
  const [urlSplit, setURLSplit] = useState([])
  const [loading, setLoading] = useState(true)
  const [cardCount, setCardCount] = useState(0)
  const [errorMessage, setErrorMessage] = useState('')
  const [arrangedCategories, setArrangedCategories] = useState([])

  useEffect(() => {
    // setLoading(true)
    setURLSplit(url.split('/'))

    if (location.state !== undefined)
      setId(location.state.id)
  }, [])

  useEffect(() => {
    if (latestError !== '')
      setErrorMessage(latestError)
    else
      setErrorMessage('')
  }, [latestError])

  useEffect(() => {
    if (id !== '')
      dispatch(getChildrenCategoriesNoAuth(id))
  }, [id])

  useEffect(() => {
    if (reduxChildCategories.data !== undefined) {
      setLoading(false)
      setCardCount(Math.ceil(reduxChildCategories.data.length / 7))
    }
  }, [reduxChildCategories])

  useEffect(() => {
    if (cardCount !== 0) {
      let endIndex = 0
      let tempCardCountArr = []
      let tempCardCategoriesArr = []

      for (let i = 0; i < cardCount; i++) {
        tempCardCategoriesArr.push(reduxChildCategories.data.slice(endIndex, (i + 1) * 7))
        tempCardCountArr.push(i + 1)
        endIndex = (i + 1) * 7
      }

      setArrangedCategories(tempCardCategoriesArr)
    }
  }, [cardCount])

  // useEffect(() => {
  //   if (arrangedCategories.length !== 0)
  //     setLoading(false)
  //   else {
  //     setLoading(false)
  //   }
  // }, [arrangedCategories])

  useEffect(() => {
    return () => {
      dispatch({ type: GETCHILDCATEGORIES, payload: [] })
    }
  }, [])

  return (
    <>
      <MetaTags>
        <title>Search From Categories</title>
        <meta property="og:title" content="Search From Categories" />
        <meta name="description" content="Search Listings from various Categories" />
      </MetaTags>
      <div className='container'>
        <ol className="breadcrumb justify-content-start">
          <li className="breadcrumb-item"><a href="/">Home</a></li>
          <li className="breadcrumb-item active">{urlSplit[2]}</li>
        </ol>
        <div className="row mb-2">
          <div className="col">
            <h2>{urlSplit[2]}</h2>
          </div>
        </div>
        <div className="row">
          {loading
            ? <BarLoader css={override} height={5} width={1000} color={"#00b4d8"} />
            : reduxChildCategories.data !== undefined
              ? reduxChildCategories.data.length === 0
                ? <h5 className="text-center">No Sub-Categories in {urlSplit[2]}</h5>
                : errorMessage !== ''
                  ? <h4>{errorMessage}</h4>
                  : <div className="row" id='categoryParentDiv'>
                    {arrangedCategories !== undefined
                      ? arrangedCategories.map((singleIndex, i) => (
                        <div key={i} className="col-lg-2 h-20 my-2">
                          <div className="card h-100 w-100 border-1 shadow-sm">
                            <div className="card-body d-flex align-items-center row">
                              {singleIndex.map((singleCategory, i) => (
                                <div key={i} className="w-100">
                                  <h5 className="card-title">
                                    <Link
                                      className="text-decoration-none text-dark"
                                      to={{
                                        pathname: `/listings/${singleCategory.parent_id.name}/${singleCategory.name}`,
                                        state: { id: singleCategory.id, parentData: { id: singleCategory.parent_id._id.$oid, name: singleCategory.parent_id.name } }
                                      }}
                                    >
                                      {singleCategory.name}
                                      {singleCategory.list_count === 0
                                        ? <></>
                                        : <span className="float-end">{singleCategory.list_count}</span>
                                      }
                                    </Link>
                                  </h5>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))
                      : <></>
                    }
                  </div>
              : <></>
          }
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    latestError: state.errorReducer.latestError,
    reduxChildCategories: state.categoryReducer.childCategories
  }
}

export default connect(mapStateToProps, null)(ListCategory)
