import { css } from "@emotion/react"
import MetaTags from 'react-meta-tags'
import Pagination from 'react-js-pagination'
import BarLoader from "react-spinners/BarLoader"
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link, useRouteMatch } from 'react-router-dom'
import ListingsCard from "../../components/listing/ListingsCard"
import { getListingsNoAuth, getListingsWithCategory } from '../../redux/actions/listing.action'

const override = css`
  display: block;
  margin: 0 auto;
  margin-top: 10%;
  border-color: red;
  border-radius: 10px;
`;

const ListListing = ({
  location,
  reduxListings
}) => {
  const dispatch = useDispatch()
  const { url } = useRouteMatch()

  const [id, setId] = useState('')
  const [parentData, setParentData] = useState('')
  const [urlSplit, setURLSplit] = useState([])
  const [loading, setLoading] = useState(false)
  const [activePage, setActivePage] = useState(1)

  const handlePageChange = (pageNumber) => {
    setLoading(true)

    dispatch(getListingsNoAuth(pageNumber))

    setActivePage(pageNumber)
  }

  useEffect(() => {
    // setLoading(true)

    // if (reduxListings.length === 0)
    //   dispatch(getListingsNoAuth(activePage))

    setLoading(false)
  }, [reduxListings])

  useEffect(() => {
    if (id !== '')
      dispatch(getListingsWithCategory(activePage, id))
  }, [id])

  useEffect(() => {
    setURLSplit(url.split('/'))
    setLoading(true)

    if (location.state === undefined)
      dispatch(getListingsNoAuth(activePage))
    else {
      setId(location.state.id)
      setParentData(location.state.parentData)
    }
  }, [])

  return (
    <>
      <MetaTags>
        <title>Browse Listings</title>
        <meta property="og:title" content="Browse Listings" />
        <meta name="description" content="Browse Listings from various categories" />
      </MetaTags>
      <div className="container">
        <ol className="breadcrumb justify-content-start">
          <li className="breadcrumb-item"><a href="/">Home</a></li>
          {parentData !== ''
            ? <li className="breadcrumb-item"><Link to={{ pathname: parentData !== '' ? `/categories/${parentData.name}` : "", state: { id: parentData.id } }}>{parentData !== '' ? parentData.name : ''}</Link></li>
            : ""
          }
          <li className="breadcrumb-item active">{urlSplit[2] === 'all' ? 'Listings' : urlSplit[3]}</li>
        </ol>
        {loading
          ? <BarLoader css={override} height={5} width={1000} color={"#00b4d8"} />
          : reduxListings.Data === undefined
            ? <BarLoader css={override} height={5} width={1000} color={"#00b4d8"} />
            : reduxListings.Data.length === 0
              ? <h4 className="text-center">No Listings Found</h4>
              : <>
                {
                  reduxListings.Data.map((reduxListing, i) => (
                    <div key={i} className="list-group shadow">
                      <ListingsCard reduxListing={reduxListing} />
                    </div>
                  ))
                }
                <div className="row mt-2">
                  {reduxListings.pagination_data === undefined
                    ? <></>
                    : <div>
                      <div className="float-end">
                        <Pagination
                          itemClass="page-item"
                          linkClass="page-link"
                          pageRangeDisplayed={6}
                          activePage={activePage}
                          onChange={handlePageChange}
                          totalItemsCount={reduxListings.pagination_data.total_items}
                          itemsCountPerPage={reduxListings.pagination_data.items_per_page}
                        />
                      </div>
                    </div>
                  }
                </div>
              </>
        }
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    reduxListings: state.listingReducer.listings,
    reduxListingPagination: state.listingReducer.listingPagination,
  }
}

export default connect(mapStateToProps, null)(ListListing)
