import toast from "react-hot-toast"
import ServicesService from "../../api-services/servicesService"
import {
  SETERROR,
  GETSERVICE,
  GETSERVICES,
  GETSERVICESBYUSER_ID,
} from "../actionTypes"

export const getService = (params, history) => async dispatch => {

  try {
    const data = await ServicesService.getService(params)
    dispatch({ type: GETSERVICE, payload: data })
    dispatch({ type: SETERROR, payload: '' })
  } catch (err) {
    if (err.response)
      dispatch({ type: SETERROR, payload: err.response.data.msg })
  }
}

export const getServices = (pageNumber = 1) => async dispatch => {
  try {
    const data = await ServicesService.getServices(pageNumber)
    dispatch({ type: GETSERVICES, payload: data })
    dispatch({ type: SETERROR, payload: '' })
  } catch (err) {
    if (err.response)
      dispatch({ type: SETERROR, payload: err.response.data.msg })
  }
}

export const getServicesByUserId = (data) => async dispatch => {
  try {
    const data = await ServicesService.getServiceByUserId(localStorage.getItem('user_id'))
    // if (data.Data.length === 0) toast.success('No Services Found')
    dispatch({ type: GETSERVICES, payload: data })
    dispatch({ type: GETSERVICESBYUSER_ID, payload: data })
    dispatch({ type: SETERROR, payload: '' })
  } catch (err) {
    if (err.response)
      dispatch({ type: SETERROR, payload: err.response.data.msg })
  }
}