import categoryService from "../../api-services/categoryService"
import {
  SETERROR,
  GETCATEGORY,
  GETCATEGORIES,
  GETPARENTCATEGORIES,
  GETCHILDCATEGORIES,
} from "../actionTypes"

export const getCategory = (params, history) => async dispatch => {

  try {
    const data = await categoryService.getCategory(params)
    dispatch({ type: GETCATEGORY, payload: data })
    dispatch({ type: SETERROR, payload: '' })
  } catch (err) {
    if (err.response)
      dispatch({ type: SETERROR, payload: err.response.data.msg })
  }
}

export const getCategories = (pageNumber = 1) => async dispatch => {
  try {
    const data = await categoryService.getCategories(pageNumber)
    dispatch({ type: GETCATEGORIES, payload: data })
    dispatch({ type: SETERROR, payload: '' })
  } catch (err) {
    if (err.response)
      dispatch({ type: SETERROR, payload: err.response.data.msg })
  }
}

export const getParentCategories = () => async dispatch => {
  try {
    const data = await categoryService.getParentCategories()
    dispatch({ type: GETPARENTCATEGORIES, payload: data })
    dispatch({ type: SETERROR, payload: '' })
  } catch (err) {
    if (err.response)
      dispatch({ type: SETERROR, payload: err.response.data.msg })
  }
}

export const getChildrenCategories = (id) => async dispatch => {
  try {
    const data = await categoryService.getCategory(id)
    dispatch({ type: GETCHILDCATEGORIES, payload: data })
    dispatch({ type: SETERROR, payload: '' })
  } catch (err) {
    if (err.response)
      dispatch({ type: SETERROR, payload: err.response.data.msg })
  }
}

export const getParentCategoriesNoAuth = () => async dispatch => {
  try {
    const data = await categoryService.getParentCategorieNoAuth()
    dispatch({ type: GETPARENTCATEGORIES, payload: data })
    dispatch({ type: SETERROR, payload: '' })
  } catch (err) {
    if (err.response)
      dispatch({ type: SETERROR, payload: err.response.data.msg })
  }
}

export const getChildrenCategoriesNoAuth = (id) => async dispatch => {
  try {
    const data = await categoryService.getCategoryNoAuth(id)
    dispatch({ type: GETCHILDCATEGORIES, payload: data })
    dispatch({ type: SETERROR, payload: '' })
  } catch (err) {
    if (err.response)
      dispatch({ type: SETERROR, payload: err.response.data.msg })
  }
}